import styled from "@emotion/styled";
import { Employee } from "../../../../models"
import { useData } from "../../../../utils/useData";
import { Box, Typography } from "@mui/material";
import { extraordinaryColors } from "../../../../utils/theme";
import { EventLogEntry, EventType } from "../../../../models/employees/eventLogEntry";

const TimelineContainer = styled(Box)({
    borderLeft: "2px solid #E7E7E7",
    position: "relative",
})

const TimelineNode = styled(Box)({
    backgroundColor: extraordinaryColors.green.light,
    borderRadius: "50%",
    border: "1px solid white",
    position: "absolute",
    height: "10px",
    width: "10px",
    left: -6,
})

const EventDescription = styled(Box)({
    display: "flex",
    flexDirection: "column",
    marginLeft: 12
})

const EventDateDisplay = styled(Typography)({
    fontSize: 14,
    fontWeight: 500,
    color: "#737373"
})

const EventLabelDisplay = styled(Typography)({
    fontWeight: 600,
    fontSize: 14
})

type TimelineEvent = {
    timestamp: string,
    label: string
}

const TimeFormat = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
const DateFormat = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: 'long', year: 'numeric' })

const formatEventTimestamp = (timeStamp: Date) => {
    const timePart = TimeFormat.format(timeStamp); 
    const datePart = DateFormat.format(timeStamp);
    return `${timePart} · ${datePart}`
}

const timelineEventLabelMap = {
    [EventType.CARD_ISSUED]: "Card Issued",
    [EventType.KYC_PASSED]: "KYC Verified",
    [EventType.KYC_FAILED]: "KYC Failed",
    [EventType.CARD_ACTIVATED]: "Card Activated",
    [EventType.CARD_CANCELLED]: "Card Cancelled",
    [EventType.CARD_BLOCKED]: "Card Frozen",
    [EventType.CARD_PIN_SET]: "Card PIN Set",
    [EventType.CARD_REISSUED]: "Card Reissued",
    [EventType.CARD_UNBLOCKED]: "Card Un-Frozen",
    [EventType.MESSAGE_SENT]: ""
}

const eventsToDisplay = [
    EventType.CARD_ISSUED,
    EventType.KYC_PASSED,
    EventType.KYC_FAILED,
    EventType.CARD_ACTIVATED,
    EventType.CARD_BLOCKED,
    EventType.CARD_CANCELLED,
    EventType.CARD_REISSUED,
    EventType.CARD_PIN_SET,
    EventType.CARD_UNBLOCKED,
]

const EmployeeTimeline = ({ employee } : { employee: Employee }) => {
    const { cards } = useData();

    const filteredEmployeeEvents = employee.eventLog.filter((employeeEvent) => 
        eventsToDisplay.includes(employeeEvent.type)
    )

    const createdAt = employee.createdAt;

    const timelineEvents : Array<TimelineEvent> = [];      

    timelineEvents.push({ timestamp: formatEventTimestamp(new Date(createdAt)), label: "Account Created" })

    filteredEmployeeEvents.forEach((event: EventLogEntry) => {
        timelineEvents.push({ timestamp: formatEventTimestamp(new Date(event.timestamp)), label: timelineEventLabelMap[event.type] ?? "" })
    })

    return (
        <Box paddingX={2}>
            <TimelineContainer>
                {timelineEvents.map((event) => {
                    return (
                        <Box marginY={2}>
                            <TimelineNode />
                            <EventDescription>
                                <EventDateDisplay>{event.timestamp.toString()}</EventDateDisplay>
                                <EventLabelDisplay>{event.label}</EventLabelDisplay>
                            </EventDescription>
                        </Box>
                    )
                })}
            </TimelineContainer>
        </Box>
    )
}

export { EmployeeTimeline }