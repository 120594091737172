import { Info } from "@mui/icons-material";
import { Box, Grid, TextField, Typography, useTheme } from "@mui/material";
import { FC, useState } from "react";
import { Country, WidgetInput } from "react-addressfinder";
import { useConfig } from "../../utils/useConfig";
import "./AddressFinderForm.css";

export const AddressFinderForm: FC<{
  formik: any;
  promptEmployerAddress?: boolean;
  required?: boolean;
}> = ({ formik, promptEmployerAddress, required }) => {
  const theme = useTheme();
  const { config } = useConfig();

  const [isCantFindAddress, setIsCantFindAddress] = useState(false);

  const updateAddress = (
    address1: string,
    address2: string,
    city: string,
    stateOrProvince: string,
    postalCode: string
  ) => {
    formik.setFieldValue("contact.address.address1", address1);
    formik.setFieldValue("contact.address.address2", address2);
    formik.setFieldValue("contact.address.city", city);
    formik.setFieldValue("contact.address.stateOrProvince", stateOrProvince);
    formik.setFieldValue("contact.address.postalCode", postalCode);
  };

  // Address type is badly defined in react package, hence the "any"
  const handlePlaceSelected = async (address: any) => {
    let line2 = address.address_line_2 || "";

    const isSuburbNonEmpty = address?.suburb && address?.suburb?.length > 0;
    const isNotAustralia = address.country !== "AU";
    const isLine2NonEmpty =
      address?.address_line_2 && address?.address_line_2?.length > 0;

    if (isSuburbNonEmpty && isNotAustralia) {
      line2 = isLine2NonEmpty
        ? `${address.address_line_2}, ${address.suburb}`
        : address.suburb;
    }

    // For NZ addresses, append the rd_number (rural road number), if provided
    if (address?.rd_number) {
      line2 = `${line2} RD ${address.rd_number}`;
    }

    updateAddress(
      address?.postal_line_1 ?? address?.address_line_1,
      line2,
      address?.mailtown ??
        address?.city ??
        address?.suburb ??
        address?.locality_name,
      address?.state_territory ?? "",
      address?.postcode
    );
  };

  const clearAddress = () => {
    updateAddress("", "", "", "", "");
  };

  return (
    <>
      <Grid item xs={12}>
        <WidgetInput
          name="contact.address.address1"
          value={formik.values.contact.address.address1}
          country={
            formik.values.contact.address.country === "NZ"
              ? Country.NZ
              : Country.AU
          }
          addressFinderKey={config?.ADDRESS_FINDER_KEY ?? ""}
          inputClassName="addressFinder"
          id="Address"
          onSelected={(fullAddress: string, address: any) => {
            handlePlaceSelected(address);
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value !== formik.values.address1) {
              clearAddress(); // If input changes clear address, so they can't edit a found address
            }
            formik.setFieldValue("contact.address.address1", e.target.value);
          }}
          placeholder="Address 1"
          required={required}
          style={{
            width: "100%",
            paddingTop: 11.5,
            paddingBottom: 11.5,
            paddingLeft: 10,
            fontSize: 16,
            outlineColor: theme.palette.primary.main,
            borderColor: "lightgray",
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: 6,
            backgroundColor: "transparent"
          }}
          addressParams={{ au_paf: "1", delivered: "1" }}
          raw={true}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          disabled={true}
          value={formik.values.contact.address.address2}
          onChange={formik.handleChange}
          name="contact.address.address2"
          error={
            formik.touched.contact?.address?.address2 &&
            Boolean(formik.errors.contact?.address?.address2)
          }
          helperText={
            formik.touched.contact?.address?.address2 &&
            formik.errors.contact?.address?.address2?.replace(
              "contact.address.address2",
              "Address 2"
            )
          }
          label="Address 2"
          fullWidth
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          disabled={true}
          value={formik.values.contact.address.city}
          onChange={formik.handleChange}
          name="contact.address.city"
          error={
            formik.touched.contact?.address?.city &&
            Boolean(formik.errors.contact?.address?.city)
          }
          helperText={
            formik.touched.contact?.address?.city &&
            formik.errors.contact?.address?.city?.replace(
              "contact.address.city",
              "City"
            )
          }
          label="City"
          fullWidth
          size="small"
          required={required}
        />
      </Grid>
      {formik.values.contact.address.country &&
        formik.values.contact.address.country !== "NZ" && ( // Only show state/province if not in NZ
          <Grid item xs={12}>
            <TextField
              disabled={true}
              value={formik.values.contact.address.stateOrProvince}
              onChange={formik.handleChange}
              name="contact.address.stateOrProvince"
              error={
                formik.touched.contact?.address?.stateOrProvince &&
                Boolean(formik.errors.contact?.address?.stateOrProvince)
              }
              helperText={
                formik.touched.contact?.address?.stateOrProvince &&
                formik.errors.contact?.address?.stateOrProvince?.replace(
                  "contact.address.stateOrProvince",
                  "State or Province"
                )
              }
              label="State or Province"
              fullWidth
              size="small"
              required={
                required && formik.values.contact.address.country !== "NZ"
              }
            />
          </Grid>
        )}

      <Grid item xs={12}>
        <TextField
          disabled={true}
          value={formik.values.contact.address.postalCode}
          onChange={formik.handleChange}
          name="contact.address.postalCode"
          error={
            formik.touched.contact?.address?.postalCode &&
            Boolean(formik.errors.contact?.address?.postalCode)
          }
          helperText={
            formik.touched.contact?.address?.postalCode &&
            formik.errors.contact?.address?.postalCode?.replace(
              "contact.address.postalCode",
              "Postal Code"
            )
          }
          label="Postal Code"
          required={required}
          fullWidth
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          disabled={true}
          value={formik.values.contact.address.country}
          onChange={formik.handleChange}
          name="contact.address.country"
          label="Country"
          required={required}
          fullWidth
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          gap={0.5}
          sx={{ cursor: "pointer" }}
          onClick={() => setIsCantFindAddress(!isCantFindAddress)}
        >
          <Info color="primary" fontSize="small" />
          <Typography
            color="primary"
            fontSize={14}
            sx={{ textDecoration: "underline" }}
          >
            I can't find {promptEmployerAddress ? "their" : "our"} address?
          </Typography>
        </Box>

        {isCantFindAddress && (
          <>
            <Typography my={1} fontSize={14} color="secondary">
              We only list verified postal addresses. If{" "}
              {promptEmployerAddress ? "their" : "your"} address isn't found,
              reach out to{" "}
              <a
                style={{ color: theme.palette.primary.main }}
                href="mailto:support@extraordinarypay.com"
              >
                support@extraordinarypay.com
              </a>
              .
            </Typography>
          </>
        )}
      </Grid>
    </>
  );
};
