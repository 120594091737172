import { Box, styled, Typography } from "@mui/material";
import { Invoice, InvoiceItemType } from "../../models";
import { formatCurrency } from "../../utils/formatters";
import { useGenerateColorGradient } from "../../utils/generateColorGradient";
import MainCard from "../shared/MainCard";

type InvoiceDetailsProps = {
  invoice: Invoice;
};


const BillingPageDisplayCard = styled(Box)({ 
  backgroundColor: "#FD864F",
  borderRadius: 12,
  overflow: "hidden",
  width: "100%",
  height: "100%",
  padding: 19,
})

const StatName = styled(Box)({
  fontWeight: 500,
  fontSize: 14
})

const StatAmount = styled(Box)({
  fontWeight: 700,
  fontSize: 28
})

export const CurrentMonthInvoiceView = ({ invoice }: InvoiceDetailsProps) => {
  const colors = useGenerateColorGradient(5);

  const displayInvoiceItem = (title: string, value: number, color: string) => {
    return (
      // @ts-ignore-next-line
      <BillingPageDisplayCard>
        <StatName>{title}</StatName>
        <StatAmount>
          {formatCurrency(value)}
        </StatAmount>
      </BillingPageDisplayCard>
    );
  };

  const employeeSpend = invoice.items.find(
    (x) => x.type === InvoiceItemType.EMPLOYEE_SPEND
  );
  const platformFees = invoice.items.filter(
    (x) =>
      x.type === InvoiceItemType.MONTHLY_USER_FEE ||
      x.type === InvoiceItemType.ISSUED_CARD_FEE ||
      x.type === InvoiceItemType.RENEW_CARD_FEE
  );

  return (
    <>
      <Box display="flex" gap={2} pt={2}>
        {displayInvoiceItem(
          "Platform Fees",
          platformFees?.reduce((sum, item) => sum + item.total, 0) ?? 0,
          colors[0]
        )}
        {displayInvoiceItem(
          "User Spend",
          employeeSpend?.total ?? 0,
          colors[1]
        )}
        {displayInvoiceItem(
          "Total for Month",
          invoice.amount.amount,
          colors[2]
        )}
      </Box>
    </>
  );
};
