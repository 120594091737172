import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { Fragment } from "react/jsx-runtime"

interface TabGroupProps {
    tabLabels: string[],
    children: React.ReactNode | React.ReactNode[],
}

const TabGroup = (props: TabGroupProps) => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newSelectedTab: number) => {
        setSelectedTab(newSelectedTab);
    }

    const childArray = Array.isArray(props.children) ? props.children : [props.children]

    return(
        <>
            <Tabs value={selectedTab} onChange={handleTabChange}
                sx={{
                    borderBottom: "2px solid #E4E7EC",
                    marginBottom: 1,
                    height: 38,
                    minHeight: 38,
                    '& .MuiTab-root': { fontSize: 14, fontWeight: 400, paddingY: 0, height: 32, minHeight: 32 },
                    '& .Mui-selected': { fontWeight: 600 }
                }}>
                {props.tabLabels.map((label, index) => (
                    <Tab label={label} key={index} />
                ))}
            </Tabs>
            {childArray.map((child, index) => 
                <Fragment key={index}>
                    {selectedTab === index && 
                        <>
                            {child}
                        </>
                    }
                </Fragment>
            )}
        </>
    )
}

export { TabGroup }