import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Box, Divider, IconButton, Menu, MenuItem, styled, Typography } from "@mui/material";
import { useState } from "react";
import { Settings } from "react-feather";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { NavBackButton } from "../../../components/employees/NavBackButton";
import { HnChip } from "../../../components/HnChip";
import { EmployeeStatusIndicator } from "../../../components/shared/EmployeeStatusIndicator";
import { LoadingSpinner } from "../../../components/shared/LoadingSpinner";
import { TabGroup } from "../../../components/shared/TabGroup";
import { Employee, EmployeeStatus } from "../../../models";
import { useConfig } from "../../../utils/useConfig";
import { useData } from "../../../utils/useData";
import { EmployeeAccounts } from "./TabContentPages/EmployeeAccounts";
import { EmployeeCards } from "./TabContentPages/EmployeeCards";
import { EmployeeOverview } from "./TabContentPages/EmployeeOverview";
import { EmployeePersonalDetails } from "./TabContentPages/EmployeePersonalDetails";
import { EmployeeTimeline } from "./TabContentPages/EmployeeTimeline";
import { EmployeeTransactions } from "./TabContentPages/EmployeeTransactions";

const HeaderContainer = styled(Box)({display:"flex", flexDirection:"row", marginBottom: 12, alignItems: "center" })

const ScrollableDetailDisplay = styled(Box)({
    display: "flex",
    flexDirection: "row",
    maxWidth: 512,
    overflowX: "auto",
    gap: 4
})

const TagPill = styled(Box)({
    borderRadius: 24,
    paddingLeft: 8, 
    paddingRight: 8,
    display: "flex",
    gap: 8,
    flexDirection: "row",
    alignItems: "center",
    border: "1px solid #8A8A8A"
})

const TagCategoryDisplay = styled(Typography)({
    fontSize: 12,
    fontWeight: 500,
    whiteSpace: "nowrap"
})

const TagDisplay = styled(Typography)({
    fontSize: 12,
    color: "#737373",
    whiteSpace: "nowrap"
})

const PageTitle = styled(Typography)({ fontSize: 32, fontWeight: 700, marginRight: 24 })


const EmployeeDetails = () => {
    const { employeeId } = useParams();
    const { employees, accounts, plans, tagCategories, employerId } = useData();
    const navigate = useNavigate();
    const { getToken } = useKindeAuth();
    const { config } = useConfig();
    
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const currentEmployee = employees.data?.items.find((employee) => employee.id === employeeId)

    const mutationChangeUserStatus = useMutation({
        mutationFn: async ({currentEmployee} : { currentEmployee: Employee } ) => {
          const response = await fetch(
            `${config?.API_URL}/employers/${employerId}/employees/${currentEmployee.id}/status`,
            {
              method: "PUT",
              headers: {
                Authorization: `Bearer ${await getToken()}`,
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                status:
                  currentEmployee.status === EmployeeStatus.Active
                    ? EmployeeStatus.Inactive
                    : EmployeeStatus.Active
              })
            }
          );
    
          if (!response.ok)
            throw new Error("There was a problem updating status for this user");
        },
        onSuccess: () => {
          toast.success("User Status updated successfully");
          employees.refetch();
          accounts.refetch();
        },
        onError: (error: Error) => {
          console.error(error.message);
          toast.error(error.message);
        }
    });

    const mutationDeleteUser = useMutation({
        mutationFn: async ({currentEmployee} : { currentEmployee: Employee } ) => {
          const response = await fetch(
            `${config?.API_URL}/employers/${employerId}/employees/${currentEmployee.id}`,
            {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${await getToken()}`,
                "Content-Type": "application/json"
              }
            }
          );
    
          if (!response.ok)
            throw new Error("There was a problem deleting this user");
        },
        onSuccess: () => {
          toast.success("User was deleted successfully");
          employees.refetch();
          accounts.refetch();
          navigate("/users");
        },
        onError: (error: Error) => {
          console.error(error.message);
          toast.error(error.message);
        }
      });
    
    if(
        employees.isFetching ||
        employees.isIdle ||
        tagCategories.isFetching ||
        tagCategories.isIdle
    ) return <LoadingSpinner />


    if(!employeeId || !currentEmployee ) return <p>Error.</p>

    const employeeAccountsWithActiveTiers = accounts.data?.items.flatMap((account) => {
        if(account.employeeId !== employeeId) return [];
        const currentPlan = plans.data?.items.find((plan) => plan.id === account.planId);
        const activeTier = currentPlan?.tiers.find((tier) => tier.id === account.tierId);
        return [{ ...account, tier: activeTier }]
    });

    const tagNameDictionary: Record<string, string> = {}

    tagCategories.data?.items.forEach((category) => {
        tagNameDictionary[category.id] = category.name
    })

    return (
    <>
        <NavBackButton onBackPress={() => navigate("/users")} />
        <HeaderContainer>
            <PageTitle>{`${currentEmployee?.firstName} ${currentEmployee?.lastName}`}</PageTitle>
            <Box display={"flex"} flexDirection={"row"} gap={2}>
                <ScrollableDetailDisplay>
                    {currentEmployee.tags.map((tag) => 
                        <TagPill>
                            <TagCategoryDisplay>
                                {tagNameDictionary[tag.tagCategoryId]}
                            </TagCategoryDisplay>
                            <Divider orientation="vertical" sx={{ borderColor: "#8A8A8A" }} />
                            <TagDisplay>
                                {tag.name}
                            </TagDisplay>
                        </TagPill>
                    )}
                </ScrollableDetailDisplay>
            </Box>
            <HnChip variant="outlined" size="sm" sx={{ marginLeft: "auto", marginRight: 1 }}>
                <Box display={"flex"} flexDirection={"row"}>
                    <EmployeeStatusIndicator status={currentEmployee.status} />
                    {currentEmployee.status}
                </Box>
            </HnChip>
            <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
                <Settings color="black"/>
            </IconButton>
            <Menu
                open={anchorEl !== null}
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                transformOrigin={{ vertical: 'center', horizontal: 'right', }}
            >
              { (currentEmployee.stats?.kyc && currentEmployee.stats.kyc.status === "APPROVED" ) &&
              <MenuItem onClick={() => mutationChangeUserStatus.mutate({ currentEmployee })}>
                {currentEmployee.status === EmployeeStatus.Active ? "Disable" : "Enable"}{" "} Access
              </MenuItem>

              }
              <MenuItem onClick={() => mutationDeleteUser.mutate({ currentEmployee })}>
                  Delete User
              </MenuItem>
            </Menu>
        </HeaderContainer>
        <TabGroup tabLabels={["Overview", "Transactions", `Accounts (${employeeAccountsWithActiveTiers?.length ?? 0})`, "Personal Details", "Cards", "Timeline"]}>
            <EmployeeOverview employee={currentEmployee} employeeAccounts={employeeAccountsWithActiveTiers ?? []} />
            <EmployeeTransactions employeeId={employeeId} />
            <EmployeeAccounts employee={currentEmployee} />
            <EmployeePersonalDetails employee={currentEmployee} />
            <EmployeeCards employee={currentEmployee} />
            <EmployeeTimeline employee={currentEmployee} />
        </TabGroup>
    </>)
}

export { EmployeeDetails };
