import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { extraordinaryColors } from "../../utils/theme";
import { useData } from "../../utils/useData";
import { EmployeeSpendTooltipRenderer } from "./EmployeeSpendTooltip/EmployeeSpendTooltip";

const areaChartOptions = {
  chart: {
    height: 355,
    type: "area",
    toolbar: {
      show: false
    }
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      type: "vertical",
      inverseColors: true,
      gradientToColors: [extraordinaryColors.purple.main],
      opacityFrom: 0.6,
      opacityTo: 0
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: "smooth",
    width: 6,
    lineCap: "round"
  },
  markers: {
    colors: "#A46FE0",
    strokeColor: "#fff"
  },
  grid: {
    show: false,
    xaxis: {
      lines: {
        show: false
      },
      ticks: {
        show: false
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        }
      }
    }
  },
  yaxis: {
    labels: {
      show: false
    }
  },
  xaxis: {
    crosshairs: {
      stroke: {
        color: "#000000",
        dashArray: 0,
        width: 2
      }
    }
  }
};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

const getDaysInCurrentMonth = () => {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + 1);
  currentDate.setDate(0);
  return currentDate.getDate();
};

const generateXAxis = (slot) => {
  if (slot === "12m") {
    // Go back 12 months. Wrap around if required.
    let month = new Date().getMonth();
    const last12Months = [];
    for (let i = 12; i > 0; i--) {
      if (month < 0) month = 11;
      last12Months.unshift(months[month]);
      month--;
    }
    return { categories: last12Months, tickAmount: 12 };
  }

  if (slot === "1m") {
    // month could have any number of days - so just show this month. get the total number of days, though.
    const dayLabels = [];
    const numberOfDays = getDaysInCurrentMonth();
    for (let i = 1; i <= numberOfDays; i++) {
      dayLabels.push(i);
    }
    return { categories: dayLabels, tickAmount: dayLabels.length - 1 };
  }

  if (slot === "6m") {
    // Go back 6 months. Wrap around if required.
    let month = new Date().getMonth();
    const last6Months = [];
    for (let i = 6; i > 0; i--) {
      if (month < 0) month = 11;
      last6Months.unshift(months[month]);
      month--;
    }
    return { categories: last6Months, tickAmount: 6 };
  }
};

const EmployeeSpendChart = ({ slot }) => {
  const theme = useTheme();
  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [options, setOptions] = useState(areaChartOptions);
  const [series, setSeries] = useState([]);

  const { ledgers } = useData();

  const seriesData = useMemo(() => {
    if (!ledgers.isFetched) return { isLoading: true };
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const currentWeekDay = today.getDay();

    const currentWeekStart = new Date(today);
    currentWeekStart.setDate(today.getDate() - (currentWeekDay - 1));

    const twelveMonthsAgo = new Date(today);
    twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 12);

    const past12monthsData = Array(12).fill(0);
    const past12monthsVolumes = Array(12).fill(0);

    const currentYearData = Array(12).fill(0);
    const currentYearVolumes = Array(12).fill(0);

    const daysInMonth = getDaysInCurrentMonth();
    const currentMonthData = Array(daysInMonth).fill(0);
    const currentMonthVolumes = Array(daysInMonth).fill(0);

    ledgers.data.items.forEach((transaction) => {
      const transactionDate = new Date(transaction.timestamp.split("T")[0]);
      const transactionYear = transactionDate.getFullYear();
      const transactionMonth = transactionDate.getMonth();
      const transactionDay = transactionDate.getDate();

      if (transactionDate >= twelveMonthsAgo && transactionDate <= today) {
        const diffInMonths =
          (today.getFullYear() - transactionDate.getFullYear()) * 12 +
          (today.getMonth() - transactionDate.getMonth());

        const monthIndex = 11 - diffInMonths;
        if (monthIndex >= 0 && monthIndex < 12) {
          past12monthsData[monthIndex] += transaction.billingAmount.amount;
          past12monthsVolumes[monthIndex]++;
        }
      }

      if (transactionYear === currentYear) {
        if (transactionMonth === currentMonth) {
          currentMonthData[transactionDay - 1] +=
            transaction.billingAmount.amount;
          currentMonthVolumes[transactionDay - 1]++;
        }

        currentYearData[transactionMonth] += transaction.billingAmount.amount;
        currentYearVolumes[transactionMonth]++;
      }
    });

    return {
      "12m": {
        data: past12monthsData,
        volume: past12monthsVolumes
      },
      // Todo: Revisit 6 month rolling windows once reporting is better defined
      "6m": {
        data: currentYearData,
        volume: currentYearVolumes
      },
      "1m": {
        data: currentMonthData,
        volume: currentMonthVolumes
      }
    };
  }, [ledgers.data, ledgers.isFetched]);

  const RenderTooltip = useCallback(
    ({ series, seriesIndex, dataPointIndex, w }) => {
      return EmployeeSpendTooltipRenderer(
        { series, seriesIndex, dataPointIndex, w },
        seriesData[slot].volume
      );
    },
    [series, seriesData, slot]
  );

  useEffect(() => {
    setOptions((prevState) => {
      const xAxisSpec = generateXAxis(slot);

      return {
        ...prevState,
        colors: [extraordinaryColors.purple.main],
        xaxis: {
          ...prevState.xaxis,
          categories: xAxisSpec.categories,
          labels: {
            style: {
              colors: [
                secondary,
                secondary,
                secondary,
                secondary,
                secondary,
                secondary,
                secondary,
                secondary,
                secondary,
                secondary,
                secondary,
                secondary
              ]
            }
          },
          tickAmount: xAxisSpec.tickAmount,
          tooltip: {
            enabled: false
          }
        },
        stroke: {
          colors: [theme.palette.primary.main]
        },
        grid: {
          borderColor: line
        },
        tooltip: {
          custom: RenderTooltip
        },
        theme: {
          mode: "light"
        }
      };
    });
  }, [primary, secondary, line, theme, slot, seriesData]);

  useEffect(() => {
    if (seriesData.isLoading) return;
    setSeries([
      {
        name: "Amount",
        data: seriesData[slot].data
      }
    ]);
  }, [slot, seriesData]);

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height={355}
    />
  );
};

EmployeeSpendChart.propTypes = {
  slot: PropTypes.string,
  quantity: PropTypes.any
};

export default EmployeeSpendChart;
