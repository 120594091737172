import { useEffect, useMemo, useState } from "react";
import { Transaction } from "../../../models";
import { ColumnDef, FilterFn } from "@tanstack/react-table";
import { useData } from "../../../utils/useData";
import { Table } from "../../shared/Table";
import { LoadingSpinner } from "../../shared/LoadingSpinner";
import { formatCurrency, formatDate } from "../../../utils/formatters";
import { Box, Button } from "@mui/material";
import { convertTransactionsToCsv, downloadCsv } from "../../../utils/convertToCSV";
import { Download } from "react-feather";


const EMPLOYEE_HEADER = "User"
const ACCOUNT_HEADER = "Account"
const filterUserColumn = (col : ColumnDef<Transaction>) => col.header !== EMPLOYEE_HEADER
const removeUserColumn = (columns: ColumnDef<Transaction>[]) => columns.filter(filterUserColumn)

const filterAccountColumn = (col: ColumnDef<Transaction>) => col.header !== ACCOUNT_HEADER
const removeAccountColumn = (columns: ColumnDef<Transaction>[]) => columns.filter(filterAccountColumn)

const TransactionTable = ({ employeeId, planId, enableSearch, enableFilters, enableExport }: { employeeId?: string, planId?: string, enableSearch?: boolean, enableFilters?: boolean, enableExport?: boolean }) => {
    const { transactions, employees, accounts, serviceSectors, plans, tagCategories } = useData();    

    const filterData = useMemo(() => {
      if(employeeId !== undefined){
        return transactions.data?.items.filter((transaction) => transaction.employeeId === employeeId)
      } else if(planId !== undefined){
        return transactions.data?.items.filter((transaction) => transaction.charges?.[0]?.planId === planId)
      }
      return transactions.data?.items
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactions.data?.items])

    const [filteredTableRowData, setFilteredTableRowData] = useState<Transaction[]>();
    const [filteredTableRowDataCsv, setFilteredTableRowDataCsv] = useState<string>();

    const handleDownload = () => {
      downloadCsv(filteredTableRowDataCsv ?? "", `Transactions-${new Date().toLocaleString()}`);
    };
    
    useEffect(() => {
      const csv = convertTransactionsToCsv(
        filteredTableRowData ?? [],
        false,
        serviceSectors.data?.items,
        employees.data?.items,
        tagCategories.data?.items,
        plans.data?.items
      );
      setFilteredTableRowDataCsv(csv);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredTableRowData])

    const dateRangeFilterFn: FilterFn<any> = (row, columnId, filterValues) => {
      const cellValue = new Date(row.getValue(columnId));
      const { start, end } = filterValues;
    
      // If no filter values, return true (no filter applied)
      if (!start && !end) return true;
    
      // Check if the date is within the range
      const isAfterStart = start ? cellValue >= new Date(start) : true;
      const isBeforeEnd = end ? cellValue <= new Date(end) : true;
    
      return isAfterStart && isBeforeEnd;
    };    

    if (
      transactions.isLoading || transactions.isIdle ||
      employees.isLoading || employees.isIdle ||
      accounts.isLoading || accounts.isIdle ||
      serviceSectors.isLoading || serviceSectors.isIdle
    ) return <LoadingSpinner />;

    if (transactions.error || !transactions.data) return <p>Something went wrong, please try again later</p>;

    const columns: ColumnDef<Transaction>[] = [
        {
          accessorFn: (row) => `${row.cardAcceptor}`,
          header: "Transaction",
          sortingFn: (rowA, rowB) => {
            return rowA.original.cardAcceptor < rowB.original.cardAcceptor
              ? -1
              : rowA.original.cardAcceptor === rowB.original.cardAcceptor
              ? 0
              : 1;
          },
          enableColumnFilter: false
        },
        {
          accessorFn: (row) => new Date(row.timestamp),
          header: "Date",
          filterFn: dateRangeFilterFn,
          meta: {
            filterType: "dateRange",
          },
          enableColumnFilter: true,
          cell: ({ row }) => {
            const formattedDate = formatDate(row.original.timestamp);
            return <Box>{formattedDate}</Box>;
          },
        },
        {
          accessorFn: (row) => formatCurrency(row.billingAmount.amount),
          cell: ({ row }) => 
            <Box textAlign={"center"} pr={"16px"}>
              {formatCurrency(row.original.billingAmount.amount)}
            </Box>,
          header: "Amount",
          enableColumnFilter: false
        },
        {
          accessorFn: (row) => row.status,
          header: "Status",
          enableColumnFilter: false
        },
        {
          accessorFn: (row) => {
            const employee = employees.data?.items.find((value) => value.id === row.employeeId)
            return `${employee?.firstName} ${employee?.lastName}`
          },
          header: EMPLOYEE_HEADER,
          enableColumnFilter: false
          
        },
        {
          accessorFn: (row) => serviceSectors.data?.items.find((sector) => sector.id === row.serviceSectorId)?.name ?? "N/A",
          header: "Category",
          enableColumnFilter: false
        },
        {
          accessorFn: (row) =>   accounts.data?.items.find((account) => account.id === row.charges?.[0]?.accountId)?.name ?? "N/A",
          header: "Account",
          meta: {
            filterLabel: "Account",
            filterSelectOptions: [
              ...(plans.data?.items?.map((plan) => {
                return {
                  option: plan.name,
                  value: plan.name
                };
              }) || [])
            ]
          },
          filterFn: (row: any, columnId, filterValues: string) => {
            const planName = row.getValue(columnId);
            return (
              filterValues.includes(planName)
            );
          }

        },
      ];
    
    var filteredColumns;

    if(employeeId) {
      filteredColumns = removeUserColumn(columns)
    }

    if(planId) {
      filteredColumns = removeAccountColumn(columns)
    }

    return (
      <>
      {
        enableExport && 
          <Button
            variant="outlined"
            onClick={() => handleDownload()}
            startIcon={<Download />}
            size="medium"
            sx={{ maxWidth: 100, ml: "auto", mr: 2, mt: 1 }}
          >
            Export
          </Button>
        }
        <Table<Transaction>
          data={
            filterData ?? []
          }
          totalItems={transactions.data.totalItems}
          columns={filteredColumns ?? columns}
          defaultSorting={[{ id: "Name", desc: false }]}
          displayFilters={enableFilters}
          displaySearch={enableSearch}
          onFilteredDataChange={(data) => setFilteredTableRowData(data)}
        />
      </>
    )
}

export { TransactionTable }