import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useFormik } from "formik";
import { DateTime, Settings } from "luxon";
import { useMemo } from "react";
import {
    PlanType,
    RenewalOptions,
    RenewalStrategy,
    RenewalType,
    MembershipType,
    Plan
} from "../../../../models";
import { LoadingSpinner } from "../../../../components/shared/LoadingSpinner";
import { useData } from "../../../../utils/useData"
import { PlanSettingsSchema } from "../../../../components/plans/PlanSettingsSchema";
import { useMutation } from "react-query";
import { mutatePlan } from "../mutatePlan";
import { useConfig } from "../../../../utils/useConfig";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { buildInitialPlanSettings } from "../../../../components/plans/buildInitialPlanSettings";

const PlanSettings = ({ plan } : { plan: Plan }) => {
    const { membership, employerId, plans, ledgers } = useData();
    const { config } = useConfig();
    const { getToken } = useKindeAuth();
    const isPilot = membership.data?.type === MembershipType.PILOT
    Settings.defaultLocale = "en-NZ"; // Set the locale to New Zealand


    const initialValues = useMemo(() => buildInitialPlanSettings(plan), [
      plan.renewalOptions,
      plan.name,
      plan.type
    ]);

    const planMutation = useMutation(async (updatedPlan: Plan) => mutatePlan(
      config,
      employerId as string,
      await getToken(),
      updatedPlan
    ),
    {
      onSuccess: async () => {
        await plans.refetch();
        await ledgers.refetch();
      },
      onError: (error: Error) => {

      }
    })

    const { resetForm, ...formik } = useFormik({
      initialValues: initialValues,
      validationSchema: PlanSettingsSchema,
      onSubmit: async (values) => {
        const renewalOptions: RenewalOptions = {
          strategy: values.renewalStrategy,
          type: values.renewalType
        };

        if (values.planType === PlanType.ONGOING) {
          renewalOptions.periodInMonths = values.ongoingPeriodInMonths;
          if (renewalOptions.type === RenewalType.SET_DATE) {
            renewalOptions.startDate = values.startDate?.toISO();
          }
        } else {
          renewalOptions.strategy = RenewalStrategy.EXPIRE;
          if (values.oneOffPeriodInMonths === -1) {
            renewalOptions.type = RenewalType.NEVER;
          } else {
            renewalOptions.periodInMonths = values.oneOffPeriodInMonths;
            if (renewalOptions.type === RenewalType.SET_DATE) {
              renewalOptions.startDate = values.startDate?.toISO();
            }
          }
        }
        
        const newPlan = { ...plan }
        newPlan.name = values.name
        newPlan.type = values.planType
        newPlan.renewalOptions = renewalOptions
        await planMutation.mutate(newPlan)
      },
      enableReinitialize: true
    });

    if(membership.isIdle || membership.isLoading) return <LoadingSpinner />

    const handleOneOffPeriodChange = (newPeriod: number) => {
      if (newPeriod === -1) {
        formik.setFieldValue("renewalType", RenewalType.NEVER);
      }
      if (formik.values.renewalType === RenewalType.NEVER) {
        formik.setFieldValue("renewalType", RenewalType.INDIVIDUAL_START_DATE);
      }
      formik.setFieldValue("oneOffPeriodInMonths", newPeriod);
    };

    return (
      <Grid container xs={7} spacing={3} sx={{padding: 5}}>
        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
          <Grid item xs={6} md={12}>
            <Box mb={3}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">Account Name</FormLabel>
                <TextField
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  name="name"
                  required
                  fullWidth
                  size="small"
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </FormControl>
            </Box>
            <Box mb={3}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">
                  Will this be an ongoing or one-off contribution account?
                </FormLabel>
                <RadioGroup
                  name="planType"
                  value={formik.values.planType}
                  onChange={formik.handleChange}
                  aria-disabled
                >
                  <FormControlLabel
                    value={PlanType.ONGOING}
                    control={
                      <Radio disabled />
                    }
                    label="Ongoing"
                  />
                  <FormControlLabel
                    value={PlanType.ONE_OFF}
                    control={
                      <Radio disabled />
                    }
                    label="One-off"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            {formik.values.planType === PlanType.ONGOING ? (
              <Box
                mb={3}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                style={{ width: "100%" }}
              >
                <FormLabel style={{ marginRight: "10px" }}>
                  How often will contributions be made?
                </FormLabel>
                <FormControl>
                  <Select
                    name="ongoingPeriodInMonths"
                    value={formik.values.ongoingPeriodInMonths}
                    onChange={formik.handleChange}
                    style={{ minWidth: "250px" }}
                    disabled
                  >
                    <MenuItem value={1}>Every month</MenuItem>
                    <MenuItem value={2}>Every 2 months</MenuItem>
                    <MenuItem value={3}>Every 3 months</MenuItem>
                    <MenuItem value={6}>Every 6 months</MenuItem>
                    <MenuItem value={12}>Every year</MenuItem>
                    <MenuItem value={24}>Every 2 years</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            ) : (
              <Box mb={3}>
                <FormControl fullWidth>
                  <FormLabel>When should this contribution expire?</FormLabel>
                  <Select
                    name="oneOffPeriodInMonths"
                    value={formik.values.oneOffPeriodInMonths}
                    onChange={(e) => handleOneOffPeriodChange(+e.target.value)}
                    disabled
                  >
                    <MenuItem value={-1}>Never</MenuItem>
                    <MenuItem value={1}>In 1 month</MenuItem>
                    <MenuItem value={2}>In 2 months</MenuItem>
                    <MenuItem value={3}>In 3 months</MenuItem>
                    <MenuItem value={6}>In 6 months</MenuItem>
                    <MenuItem value={12}>In 1 year</MenuItem>
                    <MenuItem value={24}>In 2 years</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
            <Box mb={3}>
              <FormControl
                component="fieldset"
                fullWidth
                disabled
              >
                <FormLabel>Contribution Start Date</FormLabel>
                <RadioGroup
                  name="renewalType"
                  value={formik.values.renewalType}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value={RenewalType.INDIVIDUAL_START_DATE}
                    control={<Radio />}
                    label="Relative to the date a user is added to account"
                  />
                  <FormControlLabel
                    value={RenewalType.SET_DATE}
                    control={<Radio />}
                    label="A specific date (All users will align to the same anniversary date)"
                  />
                </RadioGroup>
              </FormControl>
            </Box>

            {formik.values.renewalType === RenewalType.SET_DATE && (
              <Box mb={3}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    name="startDate"
                    maxDate={DateTime.now()} // Limits the date to today
                    value={formik.values.startDate}
                    onChange={(value) => formik.setFieldValue("startDate", value)}
                    disabled={isPilot}
                    slotProps={{
                      textField: {
                        helperText: formik.errors.startDate ? (
                          <Typography variant="caption" color="error">
                            {formik.errors.startDate}
                          </Typography>
                        ) : null,
                        error: Boolean(formik.errors.startDate),
                        InputProps: {
                          style: {
                            borderColor: formik.errors.startDate
                              ? "red"
                              : undefined
                          }
                        }
                      }
                    }}
                    format={"dd/MM/yyyy"}
                  />
                </LocalizationProvider>

                {formik.values.startDate && (
                  <Typography color="primary" fontSize={14} my={1}>
                    Your{" "}
                    {formik.values.planType === PlanType.ONE_OFF
                      ? "expiry date"
                      : "next renewal date"}{" "}
                    will be{" "}
                    {formik.values.startDate
                      .plus({ months: formik.values.ongoingPeriodInMonths })
                      .toLocaleString(DateTime.DATE_FULL)}
                  </Typography>
                )}
              </Box>
            )}
            {formik.values.planType === PlanType.ONGOING && (
              <Box mb={3}>
                <FormControl component="fieldset" fullWidth disabled={isPilot}>
                  <FormLabel>
                    What happens with residual balance at end of contribution
                    period?
                  </FormLabel>
                  <RadioGroup
                    name="renewalStrategy"
                    value={formik.values.renewalStrategy}
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel
                      value={RenewalStrategy.RESET}
                      control={<Radio />}
                      label="Expires"
                    />
                    <FormControlLabel
                      value={RenewalStrategy.CARRY_OVER}
                      control={<Radio />}
                      label="Rolls over"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            )}
          </Grid>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={formik.isSubmitting}
          >
            Update
          </Button>
        </form>
      </Grid>
    );
}

export { PlanSettings }