import { EmployeeStatus } from "../../models"

const EmployeeChipMap = {
    [EmployeeStatus.Active]: { color: 'green'},
    [EmployeeStatus.Created]: { color: 'blue' },
    [EmployeeStatus.Inactive]: { color: 'transparent' },
    [EmployeeStatus.Deleted]: { color: 'green' },
  }
  
  const EmployeeStatusIndicator = ({ status }: {status: EmployeeStatus}): JSX.Element => {
      return (
          <div style={{
              backgroundColor: EmployeeChipMap[status].color,
              width: 8,
              height: 8,
              marginLeft: 8,
              marginRight: 12,
              marginTop: "auto",
              marginBottom: "auto",
              borderRadius: '50%'
          }}/>
      )
  }
  
export { EmployeeStatusIndicator }