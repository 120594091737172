import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  styled,
  Typography
} from "@mui/material";
import parsePhoneNumberFromString from "libphonenumber-js";
import { useState } from "react";
import { Country } from "react-addressfinder";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import {
  EmployerForm,
  EmployerFormValuesType
} from "../../components/shared/EmployerForm";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner";
import { UserInfoLogout } from "../../components/shared/UserInfoLogout";
import { formatCurrency, formatPercentage } from "../../utils/formatters";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";

const AccountDetailsDisplay = styled(Box)({
  backgroundColor: "rgba(2, 176, 125, 0.1)",
  borderRadius: 12,
  overflow: "hidden",
  border: "2px solid #E4E7EC",
  marginTop: 24,
  paddingTop: 18,
  paddingLeft: 24,
  paddingRight: 24,
  paddingBottom: 18,
  whiteSpace: "nowrap",
  overflowX: "auto"
});

const AccountDetailsDisplayTitle = styled(Typography)({
  fontWeight: 800,
  fontSize: 14,
  paddingBottom: 12
});
const AccountDetailsRowTitle = styled(Box)({
  color: "#5C5C5C",
  fontSize: 14,
  marginRight: 8
});
const AccountDetailsRowInfo = styled(Box)({
  color: "#454545",
  fontWeight: 600,
  fontSize: 14
});

export const Account = () => {
  const { config } = useConfig();
  const { getToken } = useKindeAuth();
  const { employer } = useData();
  const [error, setError] = useState("");

  const updateEmployerMutation = useMutation(
    async ({ formValues }: { formValues: EmployerFormValuesType }) => {
      const formattedPhoneNumber = parsePhoneNumberFromString(
        formValues.contact.phone,
        formValues.contact.address.country === "NZ" ? Country.NZ : Country.AU
      )?.number;

      const response = await fetch(
        `${config?.API_URL}/employers/${employer.data?.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            ...formValues,
            contactName:
              formValues.contactFirstName + " " + formValues.contactLastName,
            contact: {
              ...formValues.contact,
              contactFirstName: undefined,
              contactLastName: undefined,
              phone: formattedPhoneNumber,
              address: {
                ...formValues.contact.address,
                city: formValues.contact.address.city,
                // Only include state/province for outside of NZ
                stateOrProvince:
                  formValues.contact.address.country !== "NZ"
                    ? formValues.contact.address.stateOrProvince
                    : undefined
              }
            }
          })
        }
      );

      if (!response.ok) {
        let responseJson;
        try {
          responseJson = await response.json();
        } catch (err) {
          throw new Error("There was a problem updating your account.");
        }

        switch (response.status) {
          case 409:
            throw new Error("Email already in use. Please contact support");
          case 400:
            if (responseJson.errors["Contact.Phone"]) {
              throw new Error(responseJson.errors["Contact.Phone"][0]);
            } else if (responseJson.errors["Contact.Email"]) {
              throw new Error(responseJson.errors["Contact.Email"][0]);
            } else {
              throw new Error("There was a problem updating your account.");
            }
          default:
            throw new Error("There was a problem updating your account.");
        }
      }
    },
    {
      onSuccess: () => {
        employer.refetch();
        toast.success("Account updated successfully");
      },
      onError: (error: Error) => {
        console.error(error.message);
        setError(error.message);
      }
    }
  );

  if (employer.isIdle || employer.isLoading) return <LoadingSpinner />;

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} md={8}>
        <EmployerForm
          isCreateEmployer={false}
          isLoading={updateEmployerMutation.isLoading}
          employer={employer.data}
          onSubmit={(formValues: EmployerFormValuesType) => {
            updateEmployerMutation.mutate({ formValues });
          }}
        >
          {error && (
            <Grid item xs={12}>
              <Typography color="error">{error}</Typography>
            </Grid>
          )}

          {employer.data?.createdAt ? (
            <FormControlLabel
              sx={{ mt: 1 }}
              control={<Checkbox checked={true} disabled={true} />}
              label={
                <span style={{ fontSize: 14 }}>
                  You agreed to our terms and conditions on{" "}
                  {new Date(employer.data?.createdAt).toLocaleDateString(
                    "en-NZ",
                    {
                      day: "2-digit",
                      month: "short",
                      year: "2-digit"
                    }
                  )}
                </span>
              }
            />
          ) : null}
        </EmployerForm>
      </Grid>
      <Grid item xs={4} md={4}>
        <AccountDetailsDisplay>
          <AccountDetailsDisplayTitle>
            Account Details
          </AccountDetailsDisplayTitle>
          <Divider
            sx={{ borderColor: "#D3D3D3", paddingLeft: 12, paddingRight: 12 }}
          ></Divider>
          <Box sx={{ paddingTop: "12px" }}>
            {employer.data?.generalOptions.expiryDate ? (
              <Grid item xs={12}>
                <Typography>
                  <span style={{ float: "left" }}>Expiry Date:</span>{" "}
                  <span style={{ float: "right" }}>
                    {new Date(
                      employer.data?.generalOptions.expiryDate
                    ).toLocaleDateString("en-NZ", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric"
                    })}
                  </span>
                </Typography>
              </Grid>
            ) : null}
            {employer.data?.createdAt ? (
              <Stack direction={"row"} justifyContent={"space-between"}>
                <AccountDetailsRowTitle>
                  Account Activation:
                </AccountDetailsRowTitle>{" "}
                <AccountDetailsRowInfo>
                  {new Date(employer.data?.createdAt).toLocaleDateString(
                    "en-NZ",
                    {
                      day: "2-digit",
                      month: "short",
                      year: "numeric"
                    }
                  )}
                </AccountDetailsRowInfo>
              </Stack>
            ) : null}
            {employer.data?.paymentOptions.minimumFloatPercent ? (
              <Stack direction={"row"} justifyContent={"space-between"}>
                <AccountDetailsRowTitle>
                  Min. Funding Requirement:
                </AccountDetailsRowTitle>
                <AccountDetailsRowInfo>
                  {formatPercentage(
                    employer.data.paymentOptions.minimumFloatPercent * 100
                  )}
                </AccountDetailsRowInfo>
              </Stack>
            ) : null}
            {employer.data?.paymentOptions.monthlyUserFee ? (
              <Stack direction={"row"} justifyContent={"space-between"}>
                <AccountDetailsRowTitle>
                  Platform Fee (per user):
                </AccountDetailsRowTitle>
                <AccountDetailsRowInfo>
                  {employer.data?.country.currencyName}
                  {formatCurrency(
                    employer.data.paymentOptions.monthlyUserFee,
                    2
                  )}
                </AccountDetailsRowInfo>
              </Stack>
            ) : null}
            {employer.data?.paymentOptions.issuedCardFee ? (
              <Stack direction={"row"} justifyContent={"space-between"}>
                <AccountDetailsRowTitle style={{ float: "left" }}>
                  Issuance Fee (per card):
                </AccountDetailsRowTitle>
                <AccountDetailsRowInfo style={{ float: "right" }}>
                  {employer.data?.country.currencyName}
                  {formatCurrency(
                    employer.data.paymentOptions.issuedCardFee,
                    2
                  )}
                </AccountDetailsRowInfo>
              </Stack>
            ) : null}
          </Box>
        </AccountDetailsDisplay>
        <UserInfoLogout hideUserInfo/>
      </Grid>
    </Grid>
  );
};
