import React from 'react'
import { renderToString } from 'react-dom/server'
import './ReportLineChartTooltip.css'

const LineChartTooltip = ({series, seriesIndex, dataPointIndex, w}) => {
    return(
        <div className='report-tooltipContainer'>
            <span className='report-volumeDisplay'>
                Count: {series[seriesIndex][dataPointIndex]}
            </span>
        </div>
    )
}

const LineChartTooltipMemo = React.memo(LineChartTooltip)

const LineChartTooltipRenderer = ({series, seriesIndex, dataPointIndex, w}) => 
    renderToString(<LineChartTooltipMemo 
        series={series}
        seriesIndex={seriesIndex}
        dataPointIndex={dataPointIndex}
        w={w}
    />)

export { LineChartTooltipMemo }
export { LineChartTooltipRenderer }