import { Button, Card, Grid, Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Plus } from "react-feather";
import { CreateReport } from "../../components/reporting/CreateReport";
import { RenderReport } from "../../components/reporting/RenderReport";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner";
import { Report } from "../../models/reporting/report";
import { ReportEntity } from "../../models/reporting/reportEntity";
import { useData } from "../../utils/useData";
import { PageHeaderControlContext } from "../../context/PageHeaderControlContext";
import { PageHeader } from "../../components/shared/PageHeader";
import { SideDrawer } from "../../components/shared/SideDrawer/SideDrawer";

export const Reporting = () => {
  const { employees, transactions, cards, reports, accounts } = useData();
  const [isCreatingReport, setIsCreatingReport] = useState(false);

  const { setHeaderActions } = useContext(PageHeaderControlContext)

  const resolveReportDocuments = (reportEntity: ReportEntity) => {
    switch (reportEntity) {
      case ReportEntity.EMPLOYEES:
        return (
          employees.data?.items.map((employee) => ({
            ...employee,
            accounts: accounts.data?.items.filter((account) => account.employeeId === employee.id) ?? []
          })) ?? []
        );
      case ReportEntity.TRANSACTIONS:
        return transactions.data?.items ?? [];
      case ReportEntity.CARDS:
        return cards.data?.items ?? [];
      default:
        return [];
    }
  };

  useEffect( () => {
      setHeaderActions([
      <Button variant="contained" onClick={() => setIsCreatingReport(true)} endIcon={<Plus/>}>New Report</Button>
    ])
  }, [])

  if (
    employees.isIdle ||
    employees.isLoading ||
    accounts.isIdle ||
    accounts.isLoading ||
    transactions.isIdle ||
    transactions.isLoading ||
    cards.isIdle ||
    cards.isLoading ||
    reports.isIdle ||
    reports.isLoading
  ) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <PageHeader pageTitle="Reporting" />
      <Grid container spacing={2} my={1}>
        {reports.data?.items.map((report: Report, i) => (
          <RenderReport
            key={i}
            report={report}
            documents={resolveReportDocuments(report.entity)}
          />
        ))}
      </Grid>
      <SideDrawer title="Create Report" open={isCreatingReport} setIsOpen={setIsCreatingReport}>
        <CreateReport handleClose={() => setIsCreatingReport(false)} />
      </SideDrawer>
    </>
  );
};
