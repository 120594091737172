import { LoadingButton } from "@mui/lab";
import { Grid, MenuItem, Select, styled, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { FC, ReactNode, useEffect, useState } from "react";
import { Employer, EmployerCompanySize } from "../../models";
import { useConfig } from "../../utils/useConfig";
import { useDynamicEmployerValidationSchema } from "../../utils/useDynamicValidationSchema";
import { AddressFinderForm } from "../AddressFinderForm";

export type EmployerFormValuesType = {
  contactFirstName: string;
  contactLastName: string;
  name: string;
  companySize: string;
  billingContact: {
    email: string;
  };
  contact: {
    phone: string;
    email: string;
    address: {
      address1: string;
      address2: string;
      city: string;
      stateOrProvince: string;
      postalCode: string;
      country: string;
    };
  };
};

const EmployerFormSectionTitle = styled(Typography)({ marginBottom: "12px", marginTop: "24px", fontSize: 20, fontWeight: 700 })

export const EmployerForm: FC<{
  isCreateEmployer: boolean;
  isLoading: boolean;
  employer?: Employer;
  children: ReactNode | ReactNode[];
  onSubmit: (formValues: EmployerFormValuesType) => void;
}> = ({ isCreateEmployer, isLoading, employer, onSubmit, children }) => {
  const { config } = useConfig();

  const [country, setCountry] = useState<any>(
    employer?.contact.address.country ?? "NZ"
  );
  const companySizeOptions = [
    { label: "1-350 Users", value: EmployerCompanySize.SMALL },
    { label: "351-1000 Users", value: EmployerCompanySize.MEDIUM },
    { label: "1000+ Users", value: EmployerCompanySize.LARGE }
  ];

  const validationSchema = useDynamicEmployerValidationSchema(country);

  const { resetForm, ...formik } = useFormik({
    initialValues: {
      contactFirstName: employer?.contactName?.split(" ")[0] ?? "",
      contactLastName: employer?.contactName?.split(" ")[1] ?? "",
      name: employer?.name ?? "",
      companySize: employer?.companySize ?? "",
      contact: {
        phone: employer?.contact.phone ?? "",
        email: employer?.contact.email ?? "",
        address: {
          address1: employer?.contact.address.address1 ?? "",
          address2: employer?.contact.address.address2 ?? "",
          city: employer?.contact.address.city ?? "",
          stateOrProvince: employer?.contact.address.stateOrProvince ?? "",
          postalCode: employer?.contact.address.postalCode ?? "",
          country
        }
      },
      billingContact: {
        email: employer?.billingContact?.email ?? ""
      }
    },
    validationSchema,
    onSubmit: async (values) => {
      onSubmit(values);
    }
  });

  useEffect(() => {
    setCountry(formik.values.contact.address.country);
  }, [formik.values.contact.address.country]);

  useEffect(() => {
    if (country === "NZ") {
      formik.setFieldValue("contact.address.stateOrProvince", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <EmployerFormSectionTitle>Company Details</EmployerFormSectionTitle>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={formik.values.name}
              onChange={formik.handleChange}
              label="Company Name"
              name="name"
              required
              fullWidth
              size="small"
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={
                formik.touched.name &&
                formik.errors.name?.replace("name", "Company Name")
              }
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <EmployerFormSectionTitle>Contact Details</EmployerFormSectionTitle>
        </Grid>

        <Grid container spacing={2} mt={1} mb={3}>
          <Grid item xs={6}>
            <TextField
              value={formik.values.contactFirstName}
              onChange={formik.handleChange}
              label="Contact First Name"
              name="contactFirstName"
              required
              fullWidth
              size="small"
              error={
                formik.touched.contactFirstName &&
                Boolean(formik.errors.contactFirstName)
              }
              helperText={
                formik.touched.contactFirstName &&
                formik.errors.contactFirstName?.replace(
                  "contactFirstName",
                  "Contact First Name"
                )
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={formik.values.contactLastName}
              onChange={formik.handleChange}
              label="Contact Last Name"
              name="contactLastName"
              required
              fullWidth
              size="small"
              error={
                formik.touched.contactLastName &&
                Boolean(formik.errors.contactLastName)
              }
              helperText={
                formik.touched.contactLastName &&
                formik.errors.contactLastName?.replace(
                  "contactLastName",
                  "Contact Last Name"
                )
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={formik.values.contact.email}
              onChange={formik.handleChange}
              label="Contact Email"
              name="contact.email"
              required
              fullWidth
              size="small"
              error={
                formik.touched.contact?.email &&
                Boolean(formik.errors.contact?.email)
              }
              helperText={
                formik.touched.contact?.email &&
                formik.errors.contact?.email?.replace(
                  "contact.email",
                  "Contact Email"
                )
              }
            />
          </Grid>
          {!isCreateEmployer && (
            <Grid item xs={6}>
              <TextField
                value={formik.values.billingContact.email}
                onChange={formik.handleChange}
                label="Billing Email"
                name="billingContact.email"
                required={!isCreateEmployer}
                fullWidth
                size="small"
                error={
                  formik.touched?.billingContact?.email &&
                  Boolean(formik.errors?.billingContact?.email)
                }
                helperText={
                  formik.touched?.billingContact?.email &&
                  formik.errors.billingContact?.email?.replace(
                    "billingContact.email",
                    "Billing Email"
                  )
                }
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <TextField
              value={formik.values.contact.phone}
              onChange={formik.handleChange}
              label="Contact Phone"
              name="contact.phone"
              required
              fullWidth
              size="small"
              type="tel"
              error={
                formik.touched.contact?.phone &&
                Boolean(formik.errors.contact?.phone)
              }
              helperText={
                formik.touched.contact?.phone &&
                formik.errors.contact?.phone?.replace(
                  "contact.phone",
                  "Contact Phone"
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <EmployerFormSectionTitle>Postal Address</EmployerFormSectionTitle>
          </Grid>
          {config?.ADDRESS_FINDER_KEY && (
            <AddressFinderForm
              formik={{ resetForm, ...formik }}
              promptEmployerAddress={false}
              required={true}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          {children}
        </Grid>

        <LoadingButton
          sx={{
            marginLeft: "auto"
          }}
          type="submit"
          variant="contained"
          color="primary"
          loading={isLoading}
        >
          {isCreateEmployer ? "Submit" : "Update"}
        </LoadingButton>
      </Grid>
    </form>
  );
};
