import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  CardStatus,
  EmployeeStatus,
  Tag,
  TagCategory,
  TransactionStatus
} from "../../models";
import { ReportAxisValue } from "../../models/reporting/reportAxisValue";
import { ReportEntity } from "../../models/reporting/reportEntity";
import {
  ReportFilter,
  ReportFilterOperator
} from "../../models/reporting/reportFilter";
import { ReportPeriod } from "../../models/reporting/reportPeriod";
import { ReportType } from "../../models/reporting/reportType";
import { ReportVisualScale } from "../../models/reporting/reportVisualScale";
import { useData } from "../../utils/useData";

type ReportDetailsProps = {
  form: any;
};

type AvailableReportFilters = {
  entity: ReportEntity;
  options: AvailableReportOption[];
}[];

type AvailableReportOption = {
  entity: ReportEntity;
  label: string;
  path: string;
  operator: ReportFilterOperator;
  values: { label: string; value: any }[];
};

export const ReportDetailsForm = ({ form }: ReportDetailsProps) => {
  const { tagCategories } = useData();

  const [entity, setEntity] = useState(form.values.entity);

  const [displayedFilterOptions, setDisplayedFilterOptions] = useState<
    AvailableReportOption[]
  >([]);

  const allTags =
    tagCategories.data?.items?.reduce(
      (acc: Tag[], tagCategory: TagCategory) => {
        return [...acc, ...tagCategory.tags];
      },
      []
    ) ?? [];

  const filters: AvailableReportFilters = [
    {
      entity: ReportEntity.TRANSACTIONS,
      options: [
        {
          entity: ReportEntity.TRANSACTIONS, // Used so we have the entity in the combined filter options down below
          label: "Transaction Status",
          path: "status",
          operator: ReportFilterOperator.EQUALS,
          values: [
            { label: "Approved", value: TransactionStatus.APPROVED },
            { label: "Declined", value: TransactionStatus.DECLINED }
          ]
        } // Not including TransactionStatus.PENDING / TransactionStatus.EXPIRED as users won't know what they mean
        // In future could add mccCode or serviceSectors etc
      ]
    },
    {
      entity: ReportEntity.EMPLOYEES,
      options: [
        {
          entity: ReportEntity.EMPLOYEES,
          label: "User Status",
          path: "status",
          operator: ReportFilterOperator.EQUALS,
          values: [
            { label: "Created", value: EmployeeStatus.Created },
            { label: "Active", value: EmployeeStatus.Active },
            { label: "Inactive", value: EmployeeStatus.Inactive }
          ]
        },
        {
          entity: ReportEntity.EMPLOYEES,
          label: "User Tags",
          path: "tags",
          operator: ReportFilterOperator.CONTAINS,
          values: allTags.map((t) => {
            return {
              label: t.name,
              value: t.id
            };
          })
        }
        // In future could add things like filtering by plans/accounts etc
      ]
    },
    {
      entity: ReportEntity.CARDS,
      options: [
        {
          entity: ReportEntity.CARDS,
          label: "Card Status",
          path: "status",
          operator: ReportFilterOperator.EQUALS,
          values: [
            { label: "Created", value: CardStatus.Created },
            { label: "Issued", value: CardStatus.Issued },
            { label: "Active", value: CardStatus.Active },
            { label: "Inactive", value: CardStatus.Inactive },
            { label: "Removed", value: CardStatus.Removed }
            // Not including CardStatus.None as users won't know what this means
          ]
        }
      ]
    }
  ];

  useEffect(() => {
    if (form.values.entity !== entity) {
      form.setFieldValue("filters", []); // Reset filters if entity changes
      form.setFieldValue("entity", entity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  useEffect(() => {
    if (entity === ReportEntity.EMPLOYEES) {
      form.setFieldValue("data.isAnonymised", false); // Employee data can't be anonymous
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  // Force Data tables to be LG
  useEffect(() => {
    if (form.values.type === ReportType.DATA_TABLE) {
      form.setFieldValue("visualScale", ReportVisualScale.LG);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.type]);

  useEffect(() => {
    let currentEntityFilter = filters.find(
      (f) => f.entity === form.values.entity
    );

    let filterOptionsToDisplay = currentEntityFilter?.options || [];

    if (form.values.entity !== ReportEntity.EMPLOYEES) {
      let employeeFilters = filters.find(
        (f) => f.entity === ReportEntity.EMPLOYEES
      );
      filterOptionsToDisplay = filterOptionsToDisplay.concat(
        employeeFilters?.options || []
      );
    }

    setDisplayedFilterOptions(filterOptionsToDisplay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography sx={{ mb: 1 }} variant="subtitle1">
          Report Name
        </Typography>
        <TextField
          value={form.values.title}
          onChange={form.handleChange}
          name="title"
          required
          fullWidth
          size="small"
          error={form.touched.title && Boolean(form.errors.title)}
          helperText={form.touched.title && form.errors.title}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography sx={{ mb: 1 }} variant="subtitle1">
          Entity
        </Typography>
        <Select
          required
          value={entity}
          onChange={(e) => {
            setEntity(e.target.value);
          }}
          size="small"
        >
          <MenuItem value={ReportEntity.EMPLOYEES}>Users</MenuItem>
          <MenuItem value={ReportEntity.TRANSACTIONS}>Transactions</MenuItem>
          <MenuItem value={ReportEntity.CARDS}>Cards</MenuItem>
        </Select>
      </Grid>

      <Grid item xs={12}>
        <Typography sx={{ mb: 1 }} variant="subtitle1">
          Filters
        </Typography>
        <Grid container spacing={2}>
          {displayedFilterOptions.map((fo) => (
            <Grid key={fo.label} item xs={6}>
              <FormControl fullWidth>
                <InputLabel>{fo.label}</InputLabel>
                <Select
                  size="small"
                  value={
                    form.values.filters.find(
                      (f: ReportFilter) =>
                        f.path === fo.path && f.entity === fo.entity
                    )?.value ?? "Any"
                  }
                  onChange={(event) => {
                    let newFilter =
                      event.target.value === "Any"
                        ? undefined
                        : {
                            entity: fo.entity,
                            path: fo.path,
                            operator: fo.operator,
                            value: event.target.value.toString()
                          };

                    const filters =
                      form.values.filters?.filter(
                        (f: ReportFilter) => f.path !== fo.path
                      ) ?? [];

                    if (newFilter) {
                      filters.push(newFilter);
                    }
                    form.setFieldValue("filters", filters);
                  }}
                  label={fo.label}
                >
                  <MenuItem value="Any" defaultChecked>
                    Any
                  </MenuItem>
                  {fo.values.map((option: any, i: number) => (
                    <MenuItem key={i} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ mb: 1 }} variant="subtitle1">
          Period
        </Typography>
        <Select
          required
          value={form.values.data.period}
          onChange={(e) => {
            form.setFieldValue("data.period", e.target.value);
          }}
          size="small"
        >
          <MenuItem value={ReportPeriod.WEEK}>Current Week</MenuItem>
          <MenuItem value={ReportPeriod.MONTH}>Current Month</MenuItem>
          <MenuItem value={ReportPeriod.YEAR}>Current Year</MenuItem>
          <MenuItem value={ReportPeriod.ALL_TIME}>All Time</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ mb: 1 }} variant="subtitle1">
          Report Type
        </Typography>
        <Select
          required
          value={form.values.type}
          onChange={(e) => {
            form.setFieldValue("type", e.target.value);
          }}
          size="small"
        >
          <MenuItem value={ReportType.LINE_CHART}>Line Chart</MenuItem>
          <MenuItem value={ReportType.STAT_VALUE}>Stat Value</MenuItem>
          <MenuItem value={ReportType.DATA_TABLE}>Data Table</MenuItem>
        </Select>
        {form.values.type === ReportType.LINE_CHART && (
          <Typography sx={{ mt: 2 }} variant="body2">
            A line graph that shows a selected values change over a set time
            period. (eg. Daily transactions over last month).
          </Typography>
        )}
        {form.values.type === ReportType.STAT_VALUE && (
          <Typography sx={{ mt: 2 }} variant="body2">
            A single total value, for a set time period. (eg. Total transactions
            last month).
          </Typography>
        )}
        {form.values.type === ReportType.DATA_TABLE && (
          <Typography sx={{ mt: 2 }} variant="body2">
            A table view of all the data.
          </Typography>
        )}
      </Grid>
      {form.values.type === ReportType.LINE_CHART && (
        <>
          <Grid item xs={6}>
            <Typography sx={{ mb: 1 }} variant="subtitle1">
              Y-Axis
            </Typography>
            <Select
              required
              value={form.values.data.yAxis}
              onChange={(e) => {
                form.setFieldValue("data.yAxis", e.target.value);
              }}
              size="small"
            >
              <MenuItem value={ReportAxisValue.COUNT}>Count</MenuItem>
            </Select>
            {form.values.data.yAxis === ReportAxisValue.COUNT && (
              <Typography sx={{ mt: 2 }} variant="body2">
                The number/count of the filtered entity.
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ mb: 1 }} variant="subtitle1">
              X-Axis
            </Typography>
            <Select
              required
              value={form.values.data.xAxis}
              onChange={(e) => {
                form.setFieldValue("data.xAxis", e.target.value);
              }}
              size="small"
            >
              <MenuItem value={ReportAxisValue.TIME}>Time</MenuItem>
            </Select>
            {form.values.data.xAxis === ReportAxisValue.TIME && (
              <Typography sx={{ mt: 2 }} variant="body2">
                The time period a specific value existed.
              </Typography>
            )}
          </Grid>
        </>
      )}
      {form.values.entity !== ReportEntity.EMPLOYEES && (
        <Grid container item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={form.values.data.isAnonymised}
                onChange={(e) =>
                  form.setFieldValue("data.isAnonymised", e.target.checked)
                }
              />
            }
            label="Anonymise User Data"
          />
        </Grid>
      )}
    </Grid>
  );
};
