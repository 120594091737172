import { Box, styled } from "@mui/material";
import { Float } from "../../models";
import { formatCurrency } from "../../utils/formatters";
import { useGenerateColorGradient } from "../../utils/generateColorGradient";

type CurrentFloatViewProps = {
  float: Float;
};

const FloatPageDisplayCard = styled(Box)({ 
  backgroundColor: "#FD864F",
  borderRadius: 12,
  overflow: "hidden",
  width: "100%",
  height: "100%",
  padding: 19,
})

const StatName = styled(Box)({
  fontWeight: 500,
  fontSize: 14
})

const StatAmount = styled(Box)({
  fontWeight: 700,
  fontSize: 28
})

const StatExplanation = styled(Box)({
  fontSize: 12,
  paddingTop: 12
})


export const CurrentFloatView = ({ float }: CurrentFloatViewProps) => {
  const displayInvoiceItem = (title: string, value: number, explanation: string) => {
    return (
      // @ts-ignore-next-line
      <FloatPageDisplayCard>
        <StatName>{title}</StatName>
        <StatAmount>
          {formatCurrency(value)}
        </StatAmount>
        <StatExplanation>
          {explanation}
        </StatExplanation>
      </FloatPageDisplayCard>
    );
  };

  return (
    <Box display="flex" gap={2} pt={2}>
      <Box flex={1}>
        {displayInvoiceItem(
          "Total Contribution",
          float.totalContribution.amount,
          "The aggregate spend amount your organisation has contributed and is available for your users."
        )}
      </Box>

      <Box flex={1}>
        {displayInvoiceItem(
          "Minimum Funding Required",
          float.minimumAmount.amount,
          `${float.minimumFloatPercent * 100}% of the Total Contribution. This is the minimum amount we require as a bond for your card program.`
        )}
      </Box>

      <Box flex={1}>
        {displayInvoiceItem(
          "Available Funding",
          float.availableAmount.amount,
          "Your funding contribution less transactions value for the billing period. Paid invoices will contribute to the Available Funding balance."
        )}
      </Box>
    </Box>
  );
};
