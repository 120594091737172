import { ServiceSector } from "../../models";

export const getOpenServiceSector = (serviceSectors: ServiceSector[]) => {
    return serviceSectors?.find(
      (ss) =>
        ss.restrictions.mccCodes.allow.length === 0 &&
        ss.restrictions.mccCodes.block.length === 0 &&
        ss.restrictions.providerNames.allow.length === 0 &&
        ss.restrictions.providerNames.block.length === 0
    );
  };