import { useState } from "react"
import { BillingInvoices } from "./billingTabContent/BillingInvoices"
import { InvoiceDetails } from "./billingTabContent/InvoiceDetails";

const Billing = () => {

    const [ viewingInvoice, setViewingInvoice ] = useState<string | null>("");

    return (
        <>
            {
                viewingInvoice 
                    ? <InvoiceDetails invoiceId={viewingInvoice} setViewingInvoice={setViewingInvoice} />
                    : <BillingInvoices setViewingInvoice={setViewingInvoice} />
            }
        </>
    )
}

export { Billing }