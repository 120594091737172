import { Stack, TextField } from "@mui/material";

type PersonalDetailsProps = {
  formik: any;
  allowEmailChange: boolean;
};

export const PersonalDetailsForm = ({
  formik,
  allowEmailChange
}: PersonalDetailsProps) => {
  return (
    <Stack p={2} gap={3} width={"100%"}>
        <TextField
          value={formik.values.firstName}
          onChange={formik.handleChange}
          label="First name"
          name="firstName"
          required
          fullWidth
          size="small"
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
        <TextField
          value={formik.values.lastName}
          onChange={formik.handleChange}
          label="Last name"
          name="lastName"
          required
          fullWidth
          size="small"
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
        <TextField
          value={formik.values.contact.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label="Email"
          type="email"
          name="contact.email"
          required
          fullWidth
          size="small"
          InputProps={{
            readOnly: !allowEmailChange
          }}
          error={
            formik.touched.contact?.email &&
            Boolean(formik.errors.contact?.email)
          }
          helperText={
            formik.touched.contact?.email && formik.errors.contact?.email
          }
        />
        <TextField
          value={formik.values.contact.phone}
          onChange={formik.handleChange}
          label="Phone"
          name="contact.phone"
          fullWidth
          type="tel"
          size="small"
          error={
            formik.touched.contact?.phone &&
            Boolean(formik.errors.contact?.phone)
          }
          helperText={
            formik.touched.contact?.phone && formik.errors.contact?.phone
          }
        />
    </Stack>
  );
};
