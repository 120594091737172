import { ServiceSector } from "../../models";
import { getOpenServiceSector } from "./getOpenServiceSector";

const RestrictedOption = "restricted";
const OpenOption = "open";


const checkForOpenSectorSelection = (sectorIds: string[], serviceSectors: ServiceSector[]) => {
    if (
        sectorIds &&
        sectorIds[0] ===
          getOpenServiceSector(serviceSectors ?? [])?.id
      ) {
        return OpenOption
      } else {
        return RestrictedOption
      }
}

export { checkForOpenSectorSelection, RestrictedOption, OpenOption }