import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Box, Divider, Grid, List, ListItem, Typography } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import {
  Employer,
  InvitedEmployee,
  Invoice,
  InvoiceStatus,
  InvoiceType,
  Plan,
  PlanReference
} from "../../models";
import { formatCurrency } from "../../utils/formatters";
import { useConfig } from "../../utils/useConfig";
import { CardPayment } from "../billing/CardPayment";
import { BackButton } from "../shared/BackButton";
import { LoadingSpinner } from "../shared/LoadingSpinner";
import { FloatSummary } from "./FloatSummary";

export const Step4SetupFloat: FC<{
  employer: Employer | undefined;
  next: () => void;
  back: () => void;
  invoices: Invoice[];
  minimumFloat: number;
  invitedEmployees: InvitedEmployee[];
  adminEmployeePlanReference: PlanReference | undefined;
  plans: Plan[];
}> = ({
  employer,
  next,
  back,
  invoices,
  minimumFloat,
  invitedEmployees,
  adminEmployeePlanReference,
  plans
}) => {
  const { config } = useConfig();
  const { getToken } = useKindeAuth();

  const [invoice, setInvoice] = useState<Invoice>();
  const [error, setError] = useState<string>();
  const [paid, setPaid] = useState<boolean>(
    !!invoices.find((x) => x.status === InvoiceStatus.PAID)
  );
  const hasCreatedInvoice = useRef(false); // Ref to track if mutation has been called

  const createFloatInvoice = useMutation({
    mutationFn: async () => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employer?.id}/invoices`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            type: InvoiceType.FLOAT,
            amount: minimumFloat
          })
        }
      );

      // No content returned if error building invoice
      if (!response.ok || response.status === 204) {
        throw new Error(
          "There was a problem preparing your payment. Please reach out to support."
        );
      }

      const invoice = await response.json();
      setInvoice(invoice);
    },
    onSuccess: () => {},
    onError: (error: Error) => {
      console.log(error);
      console.error(error.message);
      setError(error.message);
    }
  });

  useEffect(() => {
    if (!paid && !hasCreatedInvoice.current) {
      createFloatInvoice.mutate();
      hasCreatedInvoice.current = true; // Set the flag to true after mutation is called
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container item>
      <Grid item xs={8}>
        <Typography variant="h4" fontSize={32} fontWeight={700}>
          Add funds to your account
        </Typography>

        <Typography mt={4} mb={2} fontWeight={600}>
          How deposits work
        </Typography>

        <Divider sx={{ borderWidth: 1 }} />
        <List dense={true}>
          <ListItem>
            <Typography fontSize={15}>
              • We secure 10% of your total contribution to cover your users
              transactions
            </Typography>
          </ListItem>
          <ListItem>
            <Typography fontSize={15}>
              • Paying your monthly invoices will replenish your account funds
            </Typography>
          </ListItem>
          <ListItem>
            <Typography fontSize={15}>
              • If your funds are depleted we will prompt you to make another
              payment
            </Typography>
          </ListItem>
          <ListItem>
            <Typography fontSize={15}>
              • You will never be charged more than your set users allowances
              giving you total peace of mind
            </Typography>
          </ListItem>
        </List>

        <Divider sx={{ borderWidth: 1 }} />

        <Box display="flex" my={2}>
          <Typography fontWeight={600}>Deposit due today</Typography>
          <Typography ml={1}>(10% of total contribution)</Typography>
          <Typography ml="auto" color="primary">
            {formatCurrency(minimumFloat)}
          </Typography>
        </Box>

        <Divider sx={{ borderWidth: 1 }} />
        <Box my={2} mt={3}>
          <Typography fontWeight={600}>Card Details</Typography>
        </Box>

        {paid && <Typography fontWeight={600}>Successfully Paid</Typography>}

        {!paid && (
          <>
            {invoice ? (
              <CardPayment
                invoiceId={invoice.id}
                amount={invoice.amount}
                onSuccess={next}
                countryId={employer?.country.id ?? ""}
              >
                <BackButton onClick={back} outlined />
              </CardPayment>
            ) : (
              <LoadingSpinner marginY="10" />
            )}
          </>
        )}

        {error && (
          <Typography color="error" mt={3}>
            {error}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          flexDirection: "column",
          gap: 3,
          marginLeft: "auto",
          marginTop: -14
        }}
        display={{ xs: "none", md: "none", lg: "flex" }}
      >
        <FloatSummary
          invitedEmployees={invitedEmployees}
          adminEmployeePlanReference={adminEmployeePlanReference}
          plans={plans}
        />
      </Grid>
    </Grid>
  );
};
