import { Box, Button, Divider, Grid, IconButton, Typography } from "@mui/material"
import { AccountSummary } from "../../../../components/shared/AccountSummary"
import { LoadingSpinner } from "../../../../components/shared/LoadingSpinner"
import { Employee, Plan } from "../../../../models"
import { useData } from "../../../../utils/useData"
import { SideDrawer } from "../../../../components/shared/SideDrawer"
import { useState } from "react"
import { ReactComponent as TrashCan } from '../../../../assets/trash.svg'
import { AssignPlans } from "../../../../components/employees/AssignPlans"
import { Add } from "@mui/icons-material"
import { UnAssignPlans } from "../../../../components/employees/UnAssignPlans"

const EmployeeAccounts = ({ employee } : { employee : Employee}) => {
    const { accounts, plans, serviceSectors } = useData()
    const [isAddingPlan, setIsAddingPlan] = useState(false)
    const [isRemovingPlan, setIsRemovingPlan] = useState(false)
    const [removingPlanId, setRemovingPlanId] = useState("")

    if(accounts.isLoading || accounts.isIdle || plans.isLoading || plans.isIdle) return <LoadingSpinner />
    if(!accounts.data) return <></>

    const currentEmployeeAccounts = accounts.data.items.filter((account) => account.employeeId === employee.id)
    const currentEmployeeAccountPlanIds = currentEmployeeAccounts.map((account) => account.planId);
    const currentEmployeeAccountTierIds = currentEmployeeAccounts.map((account) => account.tierId);
    const planMap: Record<string, Plan> = {}


    plans.data?.items.filter((plan) => currentEmployeeAccountPlanIds.includes(plan.id)).forEach((filteredPlan) => {
        filteredPlan.tiers = filteredPlan.tiers.filter((tier) => 
            currentEmployeeAccountTierIds.includes(tier.id ?? "")
        )
        planMap[filteredPlan.id] = filteredPlan
    })

    return (
        <>
            <SideDrawer open={isAddingPlan} setIsOpen={setIsAddingPlan}>
                <AssignPlans selectedEmployees={[employee]} handleClose={() => setIsAddingPlan(false)} handleComplete={() => setIsAddingPlan(false)} shouldhideBackButton/>
            </SideDrawer>
            <SideDrawer open={isRemovingPlan} setIsOpen={setIsRemovingPlan}>
                <UnAssignPlans selectedEmployees={[employee]} handleClose={() => setIsRemovingPlan(false)} handleComplete={() => setIsRemovingPlan(false)} shouldHideBackButton preselectPlanIds={[removingPlanId]}/>
            </SideDrawer>

            <Grid container spacing={2}>
                {
                    Object.keys(planMap).map((planId) => 
                        <Grid item xs={12} md={6} key={planId}>
                            <Box sx={{ borderColor: "#E9EEF4", borderRadius: 4, borderWidth: 2, borderStyle: "solid", padding: 2 }}>
                                <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                    <Typography variant="h6">
                                        {planMap[planId].name}
                                    </Typography>
                                    <IconButton sx={{ marginLeft: "auto"}} onClick={() => {
                                        setRemovingPlanId(planId)
                                        setIsRemovingPlan(true)}
                                    }>
                                        <TrashCan />
                                    </IconButton>
                                </Box>
                                <Divider sx={{ borderBottom: 2, borderColor: "#D3D3D3", marginTop: 1, marginBottom: 1}} />

                                <AccountSummary
                                    variant="full" 
                                    planInfo={
                                        { ...planMap[planId], 
                                            planType: planMap[planId].type,
                                            serviceSectorIds: planMap[planId]?.controls.serviceSectors?.map((ssref) => ssref.id ) ?? [] 
                                        }
                                    }
                                    serviceSectors={serviceSectors.data?.items} 
                                    expiryOrRenewalDateToDisplay={currentEmployeeAccounts.find((a) => a.planId === planId)?.renewalOptionsV2.renewalDate}
                                    remainingBalanceToDisplay={currentEmployeeAccounts.find((a) => a.planId === planId)?.availableBalance.amount}
                                />
                            </Box>
                        </Grid>
                    )
                }
            </Grid>
            <Button 
                sx={{ marginTop: 1, paddingTop: 4, paddingBottom: 4}}
                variant="text"
                onClick={() => setIsAddingPlan(true)}
                startIcon=
                {
                    <Box
                        display="flex"
                        padding={1} 
                        borderRadius="100%"
                        sx={{ bgcolor: "grey.300" }}
                    >
                        <Add color="primary" />
                    </Box>
                }
            >
                <Box sx={{ marginRight: "auto", fontWeight: 400, fontSize: 18, fontFamily: "dazzed"}}>
                    Add account
                </Box>
            </Button>
        </>
    )
}

export { EmployeeAccounts }