import { createContext, useState } from "react";

interface IHeaderControlContext {
    headerActions: React.ReactNode[],
    setHeaderActions: (newActions: React.ReactNode[]) => void
}

const PageHeaderControlContext = createContext<IHeaderControlContext>({headerActions: [], setHeaderActions: () => {}});

const PageHeaderControlProvider = ({ children }: { children: React.ReactNode}) => {
    const [headerActions, setHeaderActions] = useState<React.ReactNode[]>([])

    return(
        <PageHeaderControlContext.Provider value={{headerActions, setHeaderActions}}>
            {children}
        </PageHeaderControlContext.Provider>
    )
}

export { PageHeaderControlContext, PageHeaderControlProvider }