import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Autocomplete,
  Box,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { FormEvent, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Employee } from "../../models";
import { checkJobProgress } from "../../utils/checkJobProgress";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { NavBackButton } from "./NavBackButton";
import { SideDrawerTitle } from "../shared/SideDrawer";

type UnAssignToPlanProps = {
  handleComplete: () => void;
  handleClose: () => void;
  selectedEmployees: Employee[];
  shouldHideBackButton?: boolean;
  preselectPlanIds?: string[]
};

export const UnAssignPlans = ({
                                handleComplete,
                                handleClose,
                                selectedEmployees,
                                shouldHideBackButton = false,
                                preselectPlanIds = []
                              }: UnAssignToPlanProps) => {
  const { config } = useConfig();
  const { employerId, employees, plans, accounts } = useData();
  const { getToken } = useKindeAuth();

  const [selectedPlanIds, setSelectedPlanIds] = useState<string[]>(preselectPlanIds);
  const [error, setError] = useState("");

  const selectedEmployeesWithPlan = selectedEmployees.filter(
    (employee) => employee.accounts.length !== 0
  );

  const unassignAsynchronously = useMutation({
    mutationFn: async () => {
      canSelectedPlansBeUnAssigned();

      // Initialize a progress toast
      let toastId = toast("Un-Assigning Accounts...", {
        progress: 0,
        autoClose: false,
        type: "info"
      });

      const selectedEmployeesIds = selectedEmployees.map((x) => x.id);
      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/employees/accounts`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            employeeIds: selectedEmployeesIds,
            planIds: selectedPlanIds
          })
        }
      );

      if (!response.ok) {
        throw new Error("There was a problem un-assigning these accounts");
      }

      const responseBody = await response.json();

      checkJobProgress(
        config!.API_URL,
        responseBody.jobId,
        toastId,
        "Accounts have been removed!",
        getToken,
        onJobComplete
      );
    },
    onSuccess: () => {
      toast.success("Accounts will be un-assigned in background!");
      employees.refetch();
      accounts.refetch();
      handleClose();
      handleComplete();
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  if (accounts.isLoading) {
    return <>Loading...</>;
  }

  const accountsWithSelectedPlans = accounts.data!.items
    .filter(account => selectedPlanIds.includes(account.planId))
    .filter(account => selectedEmployees.find((employee) => account.employeeId === employee.id));

  const canSelectedPlansBeUnAssigned = () => {
    if (accountsWithSelectedPlans.length === 0) {
      throw new Error(
        "None of the selected employees are assigned the plan(s) that were selected to un-assign."
      );
    }
  };

  const onJobComplete = async () => {
    await employees.refetch();
    await accounts.refetch();
    handleClose();
  };

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    unassignAsynchronously.mutate();
  }

  const totalAmountToBeRemoved = accountsWithSelectedPlans
    .map(x => x.availableBalance.amount)
    .reduce((sum, current) => sum + current, 0);

  return (
    <>
      {!shouldHideBackButton && <NavBackButton onBackPress={handleClose} />}
      <Box component={"form"} onSubmit={handleSubmit}>
        <Box display="flex" justifyContent="space-between">
          <SideDrawerTitle sx={{ paddingLeft: 2}}>
            Un-Assign {selectedEmployeesWithPlan.length} user
            {selectedEmployeesWithPlan.length > 1 ? "s" : ""} account(s)
          </SideDrawerTitle>
        </Box>
        <Box padding={2}>
          <Grid container spacing={2}>
            <Grid item>
              <Typography>
                General instructions on un-assigning plans
              </Typography>
              <Typography color="gray">
                All instances of selected plans will be un-assigned. This means the remaining funds be removed from the
                user's accounts
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} my={1}>
            <Grid item xs={12}>
              <Typography my={1}>Accounts to Un-Assign</Typography>
              <Autocomplete
                size="small"
                multiple
                options={plans.data?.items ?? []}
                getOptionLabel={(option) => option.name}
                value={plans.data?.items.filter((plan) =>
                  selectedPlanIds.includes(plan.id)
                )}
                onChange={(_, newValue) => {
                  let newIds = newValue.map((plan) => plan.id);
                  setSelectedPlanIds(newIds);
                }}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField {...params} placeholder="Accounts to Un-Assign" />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} my={1}>
            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
            {accountsWithSelectedPlans.length > 0 &&
              <Grid item>
                <Typography>
                  {accountsWithSelectedPlans.length} accounts will be removed. Totals of {totalAmountToBeRemoved}$ to be
                  removed from the user's accounts.
                </Typography>
              </Grid>}
          </Grid>
        </Box>
        <Box padding={2}>
          <LoadingButton type="submit" variant="contained" color="primary" fullWidth>
            Save
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};
