import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { AccountRadioBox } from "../../../../components/onboarding/AccountRadioBox";
import { useData } from "../../../../utils/useData";
import { LoadingSpinner } from "../../../../components/shared/LoadingSpinner";
import { useEffect, useState } from "react";
import { Plan } from "../../../../models";
import { getOpenServiceSector } from "../../../../utils/serviceSectors/getOpenServiceSector";
import { checkForOpenSectorSelection, RestrictedOption, OpenOption } from "../../../../utils/serviceSectors/checkForOpenSectorSelection";
import { useMutation } from "react-query";
import { mutatePlan } from "../mutatePlan";
import { useConfig } from "../../../../utils/useConfig";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { toast } from "react-toastify";

const PlanBenefits = ({ plan } : { plan: Plan }) => {
    const { serviceSectors, employerId, plans } = useData()
    const { config } = useConfig();
    const { getToken } = useKindeAuth();
    const [ error, setError ] = useState("");

    const [selectedOption, setSelectedOption] = useState<
        "open" | "restricted" | undefined
    >(undefined);

    const [serviceSectorIds, setServiceSectorIds] = 
      useState<string[]>(plan.controls.serviceSectors?.map((sector) => sector.id) ?? [])

    const openServiceSector = getOpenServiceSector(serviceSectors.data?.items ?? []);

    const restrictedServiceSectors = serviceSectors.data?.items.filter(
        (x) => x.id !== openServiceSector?.id
    );

    const mutateSectors = useMutation(async (updatedPlan: Plan) => await mutatePlan(
      config,
      employerId as string,
      await getToken(),
      updatedPlan
    ),
    {
      onSuccess: () => {
        toast.success("Plan updated successfully");
        plans.refetch();
      },
      onError: (error: Error) => {
        console.error(error.message);
        setError(error.message);
      }
    })

    const handleMutateSectors = (newSectorIds: string[]) => {
      const newPlan = { ... plan }
      newPlan.controls.serviceSectors = newSectorIds.map((sectorId) => ({ id: sectorId, name: "" }))
      mutateSectors.mutate(newPlan)
    }


    useEffect(() => {
      setSelectedOption(checkForOpenSectorSelection(serviceSectorIds, serviceSectors.data?.items ?? []))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    if(serviceSectors.isLoading || serviceSectors.isIdle ) return <LoadingSpinner /> 

    return (
      <>
        <Box width={"50%"}>
          <AccountRadioBox
            title="Restricted"
            content="Pick and choose exactly where users can spend their contribution"
            isSelected={selectedOption === RestrictedOption}
            onClick={() => {
              if (selectedOption !== RestrictedOption) {
                setSelectedOption(RestrictedOption);
                setServiceSectorIds([])
              }
            }}
            children={
              selectedOption === RestrictedOption && (
                <>
                  <Autocomplete
                    disabled={selectedOption !== RestrictedOption}
                    multiple
                    options={restrictedServiceSectors ?? []}
                    getOptionLabel={(option) => option.name}
                    value={restrictedServiceSectors?.filter((sector) =>
                      serviceSectorIds.includes(sector.id)
                    )}
                    onChange={(_, newValue) => {
                      let newIds = newValue.map((sector) => sector.id);
                      setServiceSectorIds(newIds);
                    }}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Categories"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              border: "1px solid lightgrey"
                            },
                            "&:hover fieldset": {
                              border: "1px solid lightgrey"
                            }
                          }
                        }}
                        InputProps={{
                          ...params.InputProps,

                          style: {
                            overflowY: "auto",
                            minHeight: 50,
                            height: "auto",
                            paddingLeft: 8,
                            paddingRight: 8,
                            backgroundColor: "white"
                          }
                        }}
                        InputLabelProps={{
                          ...params.InputLabelProps,
                          style: {
                            overflowY: "auto",
                            minHeight: 50,
                            paddingLeft: 8,
                            paddingRight: 8
                          }
                        }}
                      />
                    )}
                  />
                  {/* Display an error message if there are no service sector ids */}
                </>
              )
            }
          />
          {openServiceSector && (
            <AccountRadioBox
              title="All Categories"
              content="Users can spend anywhere"
              isSelected={selectedOption === OpenOption}
              onClick={() => {
                setSelectedOption(OpenOption);
                setServiceSectorIds([openServiceSector.id]);
              }}
            />
          )}
        </Box>
        <Button sx={{width: "50%"}} variant="contained" onClick={() => handleMutateSectors(serviceSectorIds)}>Save</Button>
      </>
    )
}

export { PlanBenefits }