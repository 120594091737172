import { Box, Fade, Grid, Skeleton, styled, Typography } from "@mui/material";
import { useMemo } from "react";
import { TransactionStatus, TransactionType } from "../../models";
import { useData } from "../../utils/useData";
import MainCard from "../shared/MainCard";
import { formatCurrency } from "../../utils/formatters";
import { extraordinaryColors } from "../../utils/theme";

const TotalDisplayCard = styled(MainCard)<{children: React.ReactNode, content: boolean}>({
  width: "100%",
  backgroundColor: extraordinaryColors.purple.main,
  paddingLeft: 24,
  paddingRight: 24,
  paddingTop: 16,
  paddingBottom: 16,
  borderRadius: 10
})

const MetricNameDisplay = styled(Typography)({ fontWeight: 500, fontSize: 14 })
const MetricDisplay = styled(Typography)({ fontWeight: 700, fontSize: 28 }) 

export const Totals = () => {
  const { employees, ledgers, transactions, accounts } = useData();

  const totals = useMemo(() => {
    const totalContributionValue = accounts.data?.items.reduce(
      (sum, account) => {
        return sum + account.totalContributionAmount.amount;
      },
      0
    );

    const totalActualBalance = accounts.data?.items.reduce((sum, account) => {
      return sum + account.actualBalance?.amount;
    }, 0);

    // Due to adjustments, this could result in values that don't "Add Up" by minor value(s) in an employers eyes
    const totalEmployeeSpend = transactions.data?.items
      .filter(
        (t) =>
          (t.status === TransactionStatus.APPROVED ||
            t.status === TransactionStatus.PENDING) &&
          t.type === TransactionType.PURCHASE
      )
      .reduce(
        (sum, transaction) =>
          sum + (transaction.billingAmount?.amount ?? 0),
        0
      );

    const formattedTotalContribution: string = formatCurrency(totalContributionValue ?? 0)
    const formattedTotalEmployeeSpend: string = formatCurrency(totalEmployeeSpend ?? 0)
    const formattedTotalBalance: string = formatCurrency(totalActualBalance ?? 0)

    return {
      formattedTotalContribution,
      formattedTotalBalance,
      formattedTotalEmployeeSpend
    };
  }, [employees.data?.items]);

  return (
    <>
      <Fade in={true}>
        <Grid item xs={12}>
          {!employees.isFetched ||
          !ledgers.isFetched ||
          ledgers.isLoading ||
          employees.isLoading ? (
            <Box display="flex" gap={2} width="100%">
              <Skeleton
                variant="rectangular"
                animation="wave"
                width="100%"
                height={105}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width="100%"
                height={105}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width="100%"
                height={105}
              />
            </Box>
          ) : (
            <Box display="flex" gap={2}>
              <TotalDisplayCard
                content={false}
              >
                <MetricNameDisplay>Lifetime Contribution</MetricNameDisplay>
                <MetricDisplay>
                  {totals.formattedTotalContribution}
                </MetricDisplay>
              </TotalDisplayCard>
              <TotalDisplayCard content={false}>
                <MetricNameDisplay>Lifetime User Spend</MetricNameDisplay>
                <MetricDisplay>
                  {totals.formattedTotalEmployeeSpend}
                </MetricDisplay>
              </TotalDisplayCard>
              <TotalDisplayCard content={false}>
                <MetricNameDisplay>Current Available Balance</MetricNameDisplay>
                <MetricDisplay>
                  {totals.formattedTotalBalance}
                </MetricDisplay>
              </TotalDisplayCard>
            </Box>
          )}
        </Grid>
      </Fade>
    </>
  );
};
