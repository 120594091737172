
import { styled, Typography } from "@mui/material";
import { Box } from '@mui/material'

interface IColoredContainerProps {
    color: string,
    title: string,
    toolbarItems?: React.ReactNode,
    subtitleItems?: React.ReactNode,
    children: React.ReactNode
}

const Name = styled(Box)({display: "flex", flexDirection: "row", alignItems: "center"})
const NameText = styled(Typography)({ fontWeight: 700, fontSize: 20, marginRight: "auto"})



const ColouredContainer = ({ color, title, toolbarItems, subtitleItems, children}: IColoredContainerProps) => {
    const Container = styled(Box)({ border: `2px solid ${color}`, borderRadius: 12, overflow: "hidden", marginBottom: 24 })
    const Header = styled(Box)({ backgroundColor: color, paddingLeft: 18, paddingRight: 18, paddingTop: 8, paddingBottom: 12})
    return (
        <Container>
            <Header>
                <Name>
                    <NameText>{title}</NameText>
                    {toolbarItems}
                </Name>
                {subtitleItems}
            </Header>
            {children}
        </Container>
    )
}

export { ColouredContainer }