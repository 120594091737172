import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Report } from "../../models/reporting/report";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { ReportDetailsForm } from "./ReportDetailsForm";
import { SideDrawerTitle } from "../shared/SideDrawer";
import { Trash } from "react-feather";

type EditReportProps = {
  handleClose: () => void;
  report: Report;
};

export const EditReport = ({ handleClose, report }: EditReportProps) => {
  const { config } = useConfig();
  const { employerId, reports } = useData();
  const { getToken } = useKindeAuth();

  const [error, setError] = useState("");

  const mutationUpdateReport = useMutation(
    async (formValues: Partial<Report>) => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/reports/${report.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(formValues)
        }
      );

      if (!response.ok)
        throw new Error("There was a problem updating this report");
    },
    {
      onSuccess: () => {
        toast.success("Report updated successfully");
        reports.refetch();
        handleClose();
      },
      onError: (error: Error) => {
        console.error(error.message);
        setError(error.message);
      }
    }
  );

  const mutationDeleteReport = useMutation(
    async (f) => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/reports/${report.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          }
        }
      );

      if (!response.ok)
        throw new Error("There was a problem deleting this Report");
    },
    {
      onSuccess: () => {
        toast.success("Report deleted successfully");
        handleClose();
        reports.refetch();
      },
      onError: (error: Error) => {
        console.error(error.message);
        setError(error.message);
      }
    }
  );

  const { resetForm, ...formik } = useFormik({
    initialValues: {
      title: report.title,
      type: report.type,
      entity: report.entity,
      visualScale: report.visualScale,
      data: {
        xAxis: report.data.xAxis,
        yAxis: report.data.yAxis,
        period: report.data.period,
        isAnonymised: report.data.isAnonymised
      },
      filters: report.filters
    },
    onSubmit: async (values) => {
      mutationUpdateReport.mutate(values);
    },
    enableReinitialize: true
  });

  useEffect(() => {
    resetForm();
    setError("");
  }, [resetForm, setError]);

  return (
    <>
      <Box display="flex">
        <Box ml="auto" p={2}>
          <IconButton
            onClick={() => mutationDeleteReport.mutate()}
            color="error"
            style={{ marginLeft: "auto" }}
          >
            <Trash />
          </IconButton>
        </Box>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Box padding={2}>
          <Grid container spacing={2}>
            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
            <Box sx={{ width: "100%", px: 2, pt: 1 }}>
              <ReportDetailsForm form={formik} />
            </Box>
          </Grid>
        </Box>
        <Box padding={2}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            loading={mutationUpdateReport.isLoading}
          >
            Save
          </LoadingButton>
        </Box>
      </form>
    </>
  );
};
