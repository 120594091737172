import { Plan } from "../../../models";

const mutatePlan = async (
    config: any,
    employerId: string,
    token: string | undefined,
    updatedPlan: Plan
) => {
    const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/plans/${updatedPlan.id}`,
        {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            name: updatedPlan.name,
            serviceSectorIds: updatedPlan.controls.serviceSectors?.map((sector) => sector.id),
            tiers: updatedPlan.tiers.map((tier) => {
            return {
                name: tier.name,
                amount: tier.amount.amount,
                id: tier.id ? tier.id : null
            };
            }),
            renewalOptions: updatedPlan.renewalOptions
        })
        }
    );

    if (!response.ok) throw new Error("There was a problem updating this plan");
}


export { mutatePlan }