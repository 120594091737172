import { Search } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Drawer,
  Table as MuiTable,
  Stack,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { rankItem } from "@tanstack/match-sorter-utils";
import {
  ColumnDef,
  ColumnFiltersState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  RowData,
  SortingState,
  useReactTable
} from "@tanstack/react-table";
import React, { Fragment, useEffect, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { Employee } from "../../models";
import { Filter } from "../table/Filter";
import { DateRangeFilter } from "../table/DateRangeFilter";

declare module "@tanstack/table-core" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    filterType?: "number" | "select" | "string" | "buttonGroup" | "dateRange";
    filterSelectOptions?: {
      option: string;
      value: string;
      category?: string;
    }[];
    filterNumberRange?: { min: number; max: number };
    filterLabel?: string;
    filterButtonGroupOptions?: string[];
  }
}

const FilterGroup = styled("div")({ display: "flex", flexDirection: "row", gap: 8 })

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);

  addMeta({
    itemRank
  });

  return itemRank.passed;
};

type TableProps<T> = {
  data: T[];
  totalItems: number;
  columns: ColumnDef<T>[];
  defaultSorting?: SortingState;
  headerActions?: React.ReactNode;
  itemActions?: React.ReactNode;
  displaySearch?: boolean;
  displayFilters?: boolean;
  rowSelection?: boolean;
  onSelectionChanges?: (selected: T[]) => void;
  total?: string | undefined;
  size?: "sm" | "lg";
  showPagination?: boolean;
  onFilteredDataChange?: (filteredData: any[]) => void;
};

export const Table = <T extends unknown>({
  data,
  totalItems,
  columns,
  defaultSorting = [],
  headerActions = undefined,
  itemActions = undefined,
  displaySearch = true,
  displayFilters = true,
  rowSelection = true,
  onSelectionChanges = undefined,
  total = undefined,
  size = "lg",
  showPagination = true,
  onFilteredDataChange
}: TableProps<T>) => {
  const [selectAll, setSelectAll] = useState<boolean | undefined>(undefined);
  const [searchValue, setSearchValue] = useState("");
  const globalFilter = useDebounce<string>(searchValue, 200);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>(defaultSorting);
  const [selected, setSelected] = useState<T[]>([]);

  const handleSelectionChange = (newSelection: T[]) => {
    setSelected(newSelection);
    if (onSelectionChanges) {
      onSelectionChanges(newSelection);
    }
  };

  useEffect(() => {
    if (selectAll === undefined) return;
    const allFilteredItems: any = table
      .getFilteredRowModel()
      .rows.map((row) => {
        row.toggleSelected(selectAll);
        return row.original;
      });

    handleSelectionChange(selectAll ? allFilteredItems : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]);

  const rowSelectionColumn: ColumnDef<T> = {
    id: "Select",
    header: ({ table }) => (
      <Checkbox
        checked={selectAll}
        onChange={(e) => {
          setSelectAll(e.target.checked);
        }}
        size="small"
        sx={{ p: 0, ml: "9px" }}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        size="small"
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        onChange={(e) => {
          row.getToggleSelectedHandler()(e);
          if (e.target.checked) {
            const newSelection = [...selected];
            newSelection.push(row.original);
            handleSelectionChange(newSelection);
          } else {
            const newSelection = selected.filter((x) => x !== row.original);
            handleSelectionChange(newSelection);
          }
        }}
      />
    ),
    enableSorting: false,
    size: 20
  };

  let adjustedColumns = [...columns];
  if (rowSelection) {
    adjustedColumns.unshift(rowSelectionColumn);
  }

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const table = useReactTable({
    data,
    columns: adjustedColumns,
    getCoreRowModel: getCoreRowModel(),
    filterFns: {
      fuzzy: fuzzyFilter
    },
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      globalFilter,
      columnFilters,
      sorting,
      pagination: {
        pageIndex: page,
        pageSize: pageSize
      }
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setSearchValue,
    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      columnVisibility: {
        Name: false
      }
    }
  });

  useEffect(() => {
    table.setPageIndex(page);
    table.setPageSize(pageSize);
  }, [page, pageSize, table, data]);

  useEffect(() => {
    if(!onFilteredDataChange) return;

    const filteredData = table.getFilteredRowModel().rows.map(row => row.original);
    onFilteredDataChange(filteredData)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getFilteredRowModel().rows]);  

  return (
    <>
      {/* Table header */}
      {(displaySearch || headerActions || displayFilters) && (
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center" // Add this line
          sx={{
            padding: 2,
            overflowX: "auto"
          }}
        >
          { headerActions && <Stack
              direction="row"
              spacing={2}
              justifyContent="end"
              sx={{
                padding: 2,
                marginLeft: 6,
                overflowX: "auto"
              }}
            >
              {headerActions}
            </Stack>
          }
          {/* filters */}
          {displayFilters && (
            <FilterGroup>
              {table.getHeaderGroups().map((headerGroup) => (
                <Fragment key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const { column } = header;
                    if (column.columnDef.meta?.filterType === "dateRange") {
                      return <DateRangeFilter key={header.id} column={column} />;
                    }
                    return <Filter key={header.id} header={header} column={column} />;
                  })}
                </Fragment>
              ))}
            </FilterGroup>
          )}
          { displaySearch && (
            <TextField
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={"Search"}
              InputProps={{
                startAdornment: <Search sx={{ marginLeft:0.5, marginRight: 1 }} />
              }}
              size="small"
              sx={{
                minWidth: "180px",
                width: "180px",
                marginLeft: "auto",
                border: "2px solid #D3D3D3",
                borderRadius: 2,
                transition: "width 0.2s ease-in-out",
                '&:focus-within': { 
                  width: "300px",
                },
                '& .MuiOutlinedInput-root': {
                  paddingLeft: "6px",
                  '& fieldset': {
                    border: "none",
                  },
                },
              }}
              />
          )}
        </Stack>
      )}

      <>

      {/* User Actions */}
      {itemActions && (
        <Drawer 
          open={(table.getSelectedRowModel().rows as Row<Employee>[]).length > 0}
          anchor="right"
          hideBackdrop
          ModalProps={{
            keepMounted: true,
            disableScrollLock: true,
            disableEscapeKeyDown: true,
            hideBackdrop: true,
            sx: {
              pointerEvents: 'none',
              '& .MuiDialog-container': {
                pointerEvents: 'none'
              },
              '& .MuiPaper-root': {
                pointerEvents: 'auto'
              }
            }
          }}
          >
          <Stack
              direction="column"
              spacing={2}
              sx={{
                padding: 2,
                overflowX: "auto",
              }}
            >
              {itemActions}
            </Stack>
        </Drawer>
      )}
      </>

      {/* Main table */}
      <TableContainer sx={{ maxHeight: size === "sm" ? 205 : undefined }}>
        <MuiTable sx={{ borderRadius: 2, overflow: 'hidden' }}>
          <TableHead
            sx={{
              background: "#f0f0f0",
            }}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell
                    key={header.id}
                    sx={{
                      borderBottom: 1,
                      borderColor: "grey.200",
                      width: `${header.column.getSize()}px`
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        {header.column.getCanSort() ? (
                          <TableSortLabel
                            sx={{ whiteSpace: "nowrap" }}
                            active={header.column.getIsSorted() !== false}
                            direction={
                              header.column.getIsSorted() === "asc"
                                ? "asc"
                                : "desc"
                            }
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {header.column.getIsSorted() !== false ? (
                              <Box component="span" sx={visuallyHidden}>
                                {header.column.getIsSorted() === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          <>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </>
                        )}
                      </>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody
            sx={{
              "> tr, th": {
                borderColor: "grey.200"
              }
            }}
          >

            {/* rows */}
            {table.getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    sx={{
                      borderBottom: 1,
                      borderColor: "grey.200",
                      width: `${cell.column.getSize()}px`,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}

            {/* Total Row */}
            {total !== undefined && (
              <TableRow>
                {/* Assuming the total is for the first column, adjust as necessary */}
                <TableCell>
                  <b>Total</b>
                </TableCell>
                <TableCell colSpan={table.getAllColumns().length - 1}>
                  <Box display={"flex"} justifyContent="flex-end">
                    <b>{total}</b>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {showPagination &&
      <TablePagination
        component="div"
        count={table.getFilteredRowModel().rows.length}
        page={table.getState().pagination.pageIndex}
        rowsPerPage={table.getState().pagination.pageSize}
        onPageChange={(_, page) => setPage(page)}
        onRowsPerPageChange={(e) => setPageSize(parseInt(e.target.value))}
      />}
    </>
  );
};
