import { Box, Button, CardMedia, styled, Typography } from "@mui/material";
import { LoadingSpinner } from "../../../../components/shared/LoadingSpinner";
import { useData } from "../../../../utils/useData"
import { SectionTitle } from "../SectionTitle"
import { ReactComponent as ArrowRight } from "../../../../assets/arrow-right.svg"
import { useState } from "react";
import { Card as CardType, Employee} from "../../../../models";
import { NavBackButton } from "../../../../components/employees/NavBackButton";
import { Card } from "../../../../components/shared/Card";
import HealthNowCardFront from "../../../../assets/cards/HealthNowCardFront.png";
import { useConfig } from "../../../../utils/useConfig";

const CardDisplayHeaderDivider = styled(Box)({
    borderBottom: "2px solid #E4E7EC",
    width: 550,
    marginTop: 14,
})

const StyledArrow = styled(ArrowRight)({
    marginLeft: "auto",
    alignSelf: "center",
    cursor: "pointer",
})

const NoCardsMessage = styled(Typography)({
    marginTop: 24,
    marginBottom: 24,
    marginLeft: 14,
    fontSize: 16,
    fontWeight: 500
})

const CardNameDisplay = styled(Typography)({
    fontSize: 16,
    fontWeight: 600,
    marginRight: 6
})

const CardLast4Display = styled(Typography)({
    fontSize: 16,
    fontWeight: 400,
    color: "#737373",
})

const CardStatusDisplay = styled(Typography)({
    fontSize: 14,
    fontWeight: 400,
    color: "#8A8A8A"
})

const EmployeeCards = ({ employee } : { employee: Employee }) => {
    const [activeCardDisplay, setActiveCardDisplay] = useState<CardType>()
    const { cards } = useData();
    const { config } = useConfig();

    const employeeCards = cards.data?.items.filter((card) => card.employeeId === employee.id)

    if(cards.isLoading || cards.isIdle) return <LoadingSpinner />

    if(activeCardDisplay){
        return (<>
            <NavBackButton onBackPress={() => setActiveCardDisplay(undefined)} />
            <Card cardId={activeCardDisplay.id} employee={employee} />
        </>)
    }

    return (
        <>
            <SectionTitle>Cards</SectionTitle>
            <CardDisplayHeaderDivider />
            {(!employeeCards || employeeCards?.length === 0) && 
                <NoCardsMessage>User has no issued cards.</NoCardsMessage>
            }
            
            { employeeCards?.map((card) => (
                <>
                    <Button
                        variant="text"
                        startIcon={
                        <CardMedia
                            height={"66px"}
                            sx={{ marginRight: 2 }}
                            component={"img"}
                            src={
                                card?.cardDesignId && config?.PUBLIC_BUCKET_URL
                                  ? `${config.PUBLIC_BUCKET_URL}/card-designs/${card.cardDesignId}/normal.png`
                                  : HealthNowCardFront
                              }
                  
                            />}
                        endIcon={<StyledArrow />}
                        onClick={() => setActiveCardDisplay(card)}
                        sx={{ 
                            width: 550,
                            paddingTop: 6,
                            paddingBottom: 6,
                            borderBottom: "1px solid #E4E7EC",
                            borderRadius: 0
                        }}
                    >
                        <Box display={"flex"} flexDirection={"column"} textAlign={"left"} marginRight={"auto"}>
                            <Box display={"flex"} flexDirection={"row"}>
                                <CardNameDisplay>{card.name}</CardNameDisplay>
                                <CardLast4Display>{card.maskedCardNumber.slice(-4)}</CardLast4Display>
                            </Box>
                            <CardStatusDisplay>{card.status}</CardStatusDisplay>
                        </Box>

                    </Button>
                </>
            ))}

        </>
    )
}

export { EmployeeCards }