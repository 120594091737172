import { Box, Button, Dialog, DialogActions, DialogContent, Drawer, IconButton, styled, Typography } from "@mui/material"
import { useContext, useState } from "react";
import { SideDrawerTitle } from "./SideDrawerTitle";
import { X } from "react-feather";
import { MatRippleColorContext } from "../../../context/MatRippleColorContext";

const DrawerContentContainer = styled(Box)({ width: 400, display: "flex", flexDirection: "column", padding: 12 })

const SideDrawer = ({ 
    children,
    open,
    setIsOpen,
    title = "",
    shouldConfirmClose = false,
    shouldHideBackButton = false } : { 
        children: React.ReactNode,
        open: boolean,
        setIsOpen: Function,
        title?: string,
        shouldConfirmClose?: boolean,
        shouldHideBackButton?: boolean
}) => {

    const [isConfirmCloseModalOpen, setIsConfirmCloseModalOpen] = useState(false);

    const { rippleColorSx } = useContext(MatRippleColorContext)

    return (
        <>
            <Drawer open={open} anchor="right" variant="temporary" onClose={() => {
                    if(shouldConfirmClose){ setIsConfirmCloseModalOpen(true) }
                    else setIsOpen(false)
                }}>
                <Box display={"flex"} flexDirection={"row"} alignItems={"center"} sx={rippleColorSx}>
                    {title && <SideDrawerTitle>{title}</SideDrawerTitle> }
                    {!shouldHideBackButton &&
                        <IconButton
                            sx={{
                                marginTop: 2,
                                marginRight: 2,
                                marginLeft: "auto"
                            }}
                            onClick={() => setIsOpen(false)}>
                            <X />
                        </IconButton>
                    }
                </Box>
                <DrawerContentContainer sx={rippleColorSx}>
                    {children}
                </DrawerContentContainer>
            </Drawer>
            {isConfirmCloseModalOpen && (
                <Dialog open={isConfirmCloseModalOpen} maxWidth="xs" fullWidth={true} sx={rippleColorSx}>
                    <DialogContent>
                        <Typography>Are you sure you want to exit?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                        onClick={() => setIsConfirmCloseModalOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                        variant="contained"
                        onClick={() => {
                            setIsConfirmCloseModalOpen(false);
                            setIsOpen(false);
                        }}
                        >
                            Exit
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}

export { SideDrawer }