import { Box, Stack, styled, Typography } from "@mui/material";
import { VariantStyleDictionary } from "./VariantStyleDictionary";
import { useData } from "../../../utils/useData";
import { LoadingSpinner } from "../LoadingSpinner";
import { SideDrawer } from "../SideDrawer";
import { useState } from "react";
import { HnChip } from "../../HnChip";

const ListItemTitle = styled(Box)({
    fontSize: 16,
    fontWeight: 600
})

const BenefitDescription = styled(Typography)({
    fontSize: 14,
    color: "#737373"
})

const LinkText = styled(Typography)({
    fontSize: 14,
    textDecoration: "underline",
    marginLeft: "auto",
    cursor: "pointer"
})

const ActiveSectorListDisplay = ({ serviceSectorIds } : { serviceSectorIds: string[] }) => {
  const { serviceSectors } = useData()
  
  if(serviceSectors.isLoading || serviceSectors.isIdle ) return <LoadingSpinner />
  
  const sectorIdSet = new Set(serviceSectorIds);

  const activeSectors = serviceSectors.data?.items.filter((sector) => sectorIdSet.has(sector.id))

  return (
    <Stack direction={"row"} flexWrap={"wrap"} p={2} rowGap={1} gap={1}>
      {activeSectors?.map((sector) => <HnChip size="sm" variant="outlined" key={sector.id}>{sector.name}</HnChip>)}
    </Stack>
  )
}

const SectorDisplay = ({ variant, serviceSectorIds, isOpenAccount
    }: {
        variant: "full" | "preview" | "fullColor";
        serviceSectorIds: string[],
        isOpenAccount?: string
    }) => {
      

    const [ drawerOpen, setDrawerOpen ] = useState(false);
        
    const { detailsListItemStyle, sectionHeaderStyle } = VariantStyleDictionary[variant]

    return (
        <>
        <SideDrawer open={drawerOpen} setIsOpen={setDrawerOpen} title="Categories">
          <ActiveSectorListDisplay serviceSectorIds={serviceSectorIds} />
        </SideDrawer>
        <Typography sx={sectionHeaderStyle}>
          Categories
        </Typography>
        {
          isOpenAccount ?
          <Box sx={detailsListItemStyle}>
            <Box display={"flex"} flexDirection={"row"}>
              <ListItemTitle>All Categories</ListItemTitle>
            </Box>
            <BenefitDescription>
              Users can spend their set allowance at all categories
            </BenefitDescription>
          </Box> 
          : 
          <Box sx={detailsListItemStyle}>
            <Box display={"flex"} flexDirection={"row"}>
              <ListItemTitle>Restricted Categories</ListItemTitle>
              <LinkText onClick={() => setDrawerOpen(true)}>View All</LinkText>
            </Box>
            <BenefitDescription>
                {serviceSectorIds.length} categories selected
              </BenefitDescription>
          </Box>
        }
      </>
)
}

export { SectorDisplay }