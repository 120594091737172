import { ColouredContainer } from "../../../../components/shared/ColoredContainer"
import { Plan, Tier } from "../../../../models"
import { extraordinaryColors } from "../../../../utils/theme"
import { ReactComponent as Users } from '../../../../assets/employees.svg'
import { Box, Button, Grid, IconButton, styled, Tooltip, Typography } from "@mui/material"
import { useData } from "../../../../utils/useData"
import { LoadingSpinner } from "../../../../components/shared/LoadingSpinner"
import { resolveRenewalPeriod } from "../../../../components/onboarding/TierInfoBox"
import { ReactComponent as TrashCan } from '../../../../assets/trash.svg'
import { ReactComponent as Edit } from '../../../../assets/edit.svg'
import { useMutation } from "react-query"
import { useConfig } from "../../../../utils/useConfig"
import { useKindeAuth } from "@kinde-oss/kinde-auth-react"
import { toast } from "react-toastify"
import { useCallback, useState } from "react"
import { Add } from "@mui/icons-material"
import { mutatePlan } from "../mutatePlan"
import { SideDrawer } from "../../../../components/shared/SideDrawer/SideDrawer"
import { DrawerTierForm } from "../DrawerTierForm"

const StyledTrash = styled(TrashCan)({ marginLeft: "auto", cursor: "pointer" })

const UserDisplayContainer = styled(Box)({ 
    display: "flex",
    paddingTop: 12,
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center"
})

const UserIcon = styled(Users)({ color: "white", width: 24, marginRight: 8 })

const ContributionLabel = styled(Typography)({ fontSize: 10, fontWeight: 600 })

const DeletePlanButton = ({ hasEmployees, onClick }: { hasEmployees : boolean, onClick: Function }) => {
    return (
        <>
            <Tooltip title={hasEmployees ? "Cannot delete tier - Employees are assigned to it." : "Delete" }>
                <div>
                    <IconButton disabled={hasEmployees} onClick={onClick as any}>
                        <StyledTrash />
                    </IconButton>
                </div>
            </Tooltip>
        </>
    )
}

const PlanTiers = ({ plan, renewalPeriodInMonths } :
    { plan: Plan, renewalPeriodInMonths: number }) => {
    const { accounts, employerId, plans, employer } = useData();
    const { config } = useConfig();
    const { getToken } = useKindeAuth();
    const [error, setError] = useState<string>();
    const [creatingTierError, setCreatingTierError] = useState<string>();
    const [isEditingTier, setIsEditingTier] = useState(false);
    const [openTierFormDrawer, setOpenTierFormDrawer] = useState(false);
    const initialTier = {
        id: undefined,
        name: "",
        amount: {
          amount: 200,
          currencyCode : employer.data?.country.currencyCode
        }
      };
    
    const [tierToCreate, setTierToCreate] = useState<Tier>(
        initialTier
      );

    const [tierToUpdate, setTierToUpdate] = useState<Tier>(initialTier)

    const mutateTiers = useMutation(async (updatedPlan: Plan) => await mutatePlan(
            config,
            employerId as string,
            await getToken(),
            updatedPlan
        ),
        {
          onSuccess: () => {
            toast.success("Plan updated successfully");
            plans.refetch();
          },
          onError: (error: Error) => {
            console.error(error.message);
            setError(error.message);
          }
        }
    )

    const handleDeleteTier = useCallback(async (tierIdToDelete: string) => {
        const updatedPlan = { ... plan }
        updatedPlan.tiers = plan.tiers.filter((tier) => tier.id !== tierIdToDelete)
        mutateTiers.mutate(updatedPlan)
    }, [])

    const handleCreateTier = useCallback(async (newTier: Tier) => {
        const updatedPlan = { ... plan }
        const newTierData = plan.tiers;

        newTierData.push(newTier)
        updatedPlan.tiers = newTierData

        mutateTiers.mutate(updatedPlan)
        setTierToCreate(initialTier)
    }, [])

    const handleEditTier = useCallback(async (updatedTier: Tier) => {
        const updatedPlan = { ... plan }
        updatedPlan.tiers.forEach((tier, index) => {
            if(tier.id === updatedTier.id){
                updatedPlan.tiers[index] = updatedTier
            }
        })

        mutateTiers.mutate(updatedPlan)
    }, [])

    if(accounts.isIdle || accounts.isFetching ) return <LoadingSpinner />

    const totals: Record<string, number> = {}

    accounts.data?.items.forEach((account) => {
        const currentTotal = totals[account.tierId] ?? 0;
        totals[account.tierId] = currentTotal + 1;
    })

    return (
        <>
            <Grid container paddingTop={2}>
                {plan.tiers.map((tier) => {
                    const renewalPeriod = resolveRenewalPeriod(renewalPeriodInMonths);
                    return (
                        <Grid item xs={6} sx={{ paddingX: 1}}>
                            <ColouredContainer 
                                title={tier.name}
                                color={extraordinaryColors.yellow.main}
                                toolbarItems={ 
                                <>
                                    <DeletePlanButton hasEmployees={(totals[tier.id!] ?? 0) > 0} onClick={() => handleDeleteTier(tier.id ?? "")} /> 
                                    <IconButton sx={{marginLeft: 1}} onClick={() => {
                                        setTierToUpdate(tier)
                                        setIsEditingTier(true)
                                        setOpenTierFormDrawer(true)
                                    }}><Edit/></IconButton>
                                </>}
                                subtitleItems={
                                    <UserDisplayContainer>
                                        <UserIcon />
                                        <Typography>{ totals[tier.id!] ?? 0 } active Users.</Typography>
                                    </UserDisplayContainer>
                                }>
                                <Box paddingX={2} paddingY={3}>
                                    <ContributionLabel>CONTRIBUTIONS</ContributionLabel>
                                    <Typography>${tier.amount.amount}{renewalPeriod !== undefined ? `/${renewalPeriod}` : null}</Typography>
                                </Box>
                            </ColouredContainer>
                        </Grid>)
                    })}
            </Grid>

            <SideDrawer title={isEditingTier ? "Edit Tier" : "Add New Tier"} open={openTierFormDrawer} setIsOpen={() => setOpenTierFormDrawer(false)}>
                <DrawerTierForm 
                    tierToCreate={isEditingTier ? tierToUpdate : tierToCreate}
                    isEditing={isEditingTier}
                    onChange={(value: Tier) => isEditingTier ? setTierToUpdate(value) : setTierToCreate(value) }
                    onSave={() => isEditingTier ? handleEditTier(tierToUpdate) : handleCreateTier(tierToCreate)}
                />
            </SideDrawer>
            <Button
                sx={{ paddingTop: 4, paddingBottom: 4}}
                variant="text"
                onClick={() => {
                    setIsEditingTier(false)
                    setOpenTierFormDrawer(true)
                }}
                startIcon={
                    <Box
                        display="flex"
                        padding={1} 
                        borderRadius="100%"
                        sx={{ bgcolor: "grey.300" }}
                    >
                        <Add color="primary" />
                    </Box>
                }
            >
                <Typography fontSize={18} fontWeight={400} marginRight={"auto"}>Add Tier</Typography>
            </Button>
        </>
    )
}

export { PlanTiers }