import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { FormikProps } from "formik";
import { FC, useEffect, useState } from "react";
import { ServiceSector } from "../../../models";
import { CreatePlanInput } from "../../plans/CreatePlanInput";
import { BackButton } from "../../shared/BackButton";
import { AccountRadioBox } from "../AccountRadioBox";
import { getOpenServiceSector } from "../../../utils/serviceSectors/getOpenServiceSector";
import { checkForOpenSectorSelection } from "../../../utils/serviceSectors/checkForOpenSectorSelection";

const RestrictedOption = "restricted";
const OpenOption = "open";

export const Step3ServiceSectors: FC<{
  formik: FormikProps<CreatePlanInput>;
  serviceSectors: ServiceSector[] | undefined;
  next: () => void;
  back: () => void;
}> = ({ formik, serviceSectors, next, back }) => {
  const openServiceSector = getOpenServiceSector(serviceSectors ?? []);
  const restrictedServiceSectors = serviceSectors?.filter(
    (x) => x.id !== openServiceSector?.id
  );

  const [selectedOption, setSelectedOption] = useState<
    "open" | "restricted" | undefined
  >(undefined);

  // When loads we need to figure out what option they have selected
  useEffect(() => {
    setSelectedOption(checkForOpenSectorSelection(formik.values.serviceSectorIds, serviceSectors ?? []))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item xs={12}>
      <Typography variant="h4" fontSize={32} fontWeight={700}>
        Set account restrictions
      </Typography>
      <Typography variant="subtitle1" my={2}>
        Assign general categories where users can spend the account
        contribution.
      </Typography>

      <Box>
        <AccountRadioBox
          title="Restricted"
          content="Pick and choose exactly where users can spend their contribution"
          isSelected={selectedOption === RestrictedOption}
          onClick={() => {
            if (selectedOption !== RestrictedOption) {
              setSelectedOption(RestrictedOption);
              formik.setFieldValue("serviceSectorIds", []);
            }
          }}
          children={
            selectedOption === RestrictedOption && (
              <>
                <Autocomplete
                  disabled={selectedOption !== RestrictedOption}
                  multiple
                  options={restrictedServiceSectors ?? []}
                  getOptionLabel={(option) => option.name}
                  value={restrictedServiceSectors?.filter((sector) =>
                    formik.values.serviceSectorIds?.includes(sector.id)
                  )}
                  onChange={(_, newValue) => {
                    let newIds = newValue.map((sector) => sector.id);
                    formik.setFieldValue("serviceSectorIds", newIds);
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Categories"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            border: "1px solid lightgrey"
                          },
                          "&:hover fieldset": {
                            border: "1px solid lightgrey"
                          }
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,

                        style: {
                          overflowY: "auto",
                          minHeight: 50,
                          height: "auto",
                          paddingLeft: 8,
                          paddingRight: 8,
                          backgroundColor: "white"
                        }
                      }}
                      InputLabelProps={{
                        ...params.InputLabelProps,
                        style: {
                          overflowY: "auto",
                          minHeight: 50,
                          paddingLeft: 8,
                          paddingRight: 8
                        }
                      }}
                    />
                  )}
                />
                {/* Display an error message if there are no service sector ids */}
                {formik.errors.serviceSectorIds && (
                  <Typography color="error" sx={{ mt: 2 }}>
                    {formik.errors.serviceSectorIds}
                  </Typography>
                )}
              </>
            )
          }
        />
        {openServiceSector && (
          <AccountRadioBox
            title="All Categories"
            content="Users can spend anywhere"
            isSelected={selectedOption === OpenOption}
            onClick={() => {
              setSelectedOption(OpenOption);
              formik.setFieldValue("serviceSectorIds", [openServiceSector.id]);
            }}
          />
        )}
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 10 }}
      >
        <BackButton onClick={back} outlined />
        <Button
          disabled={
            !formik.values.serviceSectorIds.length ||
            Boolean(formik.errors.serviceSectorIds)
          }
          variant="contained"
          onClick={next}
        >
          Continue
        </Button>
      </Box>
    </Grid>
  );
};
