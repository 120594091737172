import { useContext, useEffect } from "react";
import { PageHeader } from "../../components/shared/PageHeader";
import { TabGroup } from "../../components/shared/TabGroup"
import { Account } from "./Account"
import { PageHeaderControlContext } from "../../context/PageHeaderControlContext";

const Settings = () => {
    const { setHeaderActions } = useContext(PageHeaderControlContext)

    useEffect(() => {
        setHeaderActions([<></>])
    }, [])

    return (
        <>
            <PageHeader pageTitle="Settings" />
            <TabGroup tabLabels={["Account"]}>
                <Account />
            </TabGroup>
        </>
    );
}


export { Settings }