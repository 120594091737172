export enum EventType {
    KYC_PASSED = "KYC_PASSED",
    CARD_ISSUED = "CARD_ISSUED",
    MESSAGE_SENT = "MESSAGE_SENT",
    CARD_ACTIVATED = "CARD_ACTIVATED",
    CARD_PIN_SET = "CARD_PIN_SET",
    CARD_BLOCKED = "CARD_BLOCKED",
    CARD_UNBLOCKED = "CARD_UNBLOCKED",
    KYC_FAILED = "KYC_FAILED",
    CARD_REISSUED = "CARD_REISSUED",
    CARD_CANCELLED = "CARD_CANCELLED"
}

export type EventLogEntry = {
    timestamp: Date,
    type: EventType
}