import { PageHeader } from "../../components/shared/PageHeader";
import { TabGroup } from "../../components/shared/TabGroup";
import { EmployeeTags } from "./EmployeeTags";
import { EmployeeTableTab } from "./EmployeeTableTab";

export const Employees = () => {
  return (
    <>
      <PageHeader pageTitle={"Users"} />
      <TabGroup tabLabels={["Users", "Tags"]}>
          <EmployeeTableTab/> 
          <EmployeeTags/>
      </TabGroup>
    </>
  );
};
