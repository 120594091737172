import {
  Box,
  Button,
  CardContent,
  Grid,
  IconButton,
  styled,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Plus } from "react-feather";
import { HnChip } from "../../components/HnChip";
import { CreateTagCategory } from "../../components/modals/CreateTagCategory";
import { EditTagCategory } from "../../components/modals/EditTagCategory";
import { LoadingSpinner } from "../../components/shared/LoadingSpinner";
import { TagCategory } from "../../models/tagCategory";
import { useData } from "../../utils/useData";
import { PageHeaderControlContext } from "../../context/PageHeaderControlContext";
import { ColouredContainer } from "../../components/shared/ColoredContainer";
import { ReactComponent as Edit } from '../../assets/edit.svg'
import { SideDrawer } from "../../components/shared/SideDrawer";
import { useMutation } from "react-query";
import { useConfig } from "../../utils/useConfig";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { toast } from "react-toastify";
import { extraordinaryColors } from "../../utils/theme";
import { ReactComponent as TrashCan } from '../../assets/trash.svg'


const StyledEditIcon = styled(Edit)({ marginLeft: "auto", cursor: "pointer" })

export const EmployeeTags = () => {
  const { tagCategories, employees, employerId } = useData();

  const { config } = useConfig();

  const [isCreatingTagCategory, setIsCreatingTagCategory] = useState(false);
  const [editingTagCategory, setEditingTagCategory] = useState<
    TagCategory | undefined
  >(undefined);

  const { setHeaderActions } = useContext(PageHeaderControlContext)

  const { getToken } = useKindeAuth();

  useEffect(() => {
      setHeaderActions([
        <Button
          variant="contained"
          onClick={() => setIsCreatingTagCategory(true)}
          startIcon={<Plus size={16} />}
          size="medium"
        >
          Add
        </Button>
      ])
  }, [])

  const mutationDeleteTagCategory = useMutation(
    async (categoryIdToDelete: string) => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/tag-categories/${categoryIdToDelete}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          }
        }
      );

      if (!response.ok)
        throw new Error(
          "There was a problem deleting this Tag Category. To delete a tag category, it must first not have any tags assigned to users."
        );
    },
    {
      onSuccess: () => {
        toast.success("Tag Category deleted successfully");
        employees.refetch();
        tagCategories.refetch();
      },
      onError: (error: Error) => {
        console.error(error.message);
        toast.error(error.message);;
      }
    }
  );


  useEffect(() => {
    if (tagCategories.isLoading) return setEditingTagCategory(undefined);
  }, [tagCategories.isLoading]);

  if (
    tagCategories.isLoading ||
    tagCategories.isIdle ||
    employees.isLoading ||
    employees.isIdle
  ) {
    return <LoadingSpinner />;
  }

  if (
    tagCategories.error ||
    !tagCategories.data ||
    employees.error ||
    !employees.data
  ) {
    return <p>Something went wrong, please try again later</p>;
  }

  return (
    <Box>
      <Grid container spacing={2}>
        {tagCategories.data.items.map((tagCategory) => {
          return (
            <Grid item xs={12} md={6} key={tagCategory.id} gap={2}>
              <ColouredContainer 
                color={extraordinaryColors.green.light}
                title={tagCategory.name}
                toolbarItems={
                  <>
                    <IconButton
                      onClick={() => mutationDeleteTagCategory.mutate(tagCategory.id)}
                    >
                      <TrashCan />
                    </IconButton>
                    <IconButton  onClick={() => setEditingTagCategory(tagCategory)} >
                      <StyledEditIcon/>
                    </IconButton>
                  </>
                }>
                <CardContent>
                  <Box display="flex" flexWrap="wrap" gap={0.5}>
                    {tagCategory.tags.map((tag, i) => {
                      return (
                        <HnChip key={i} variant="outlined" size="sm" sx={{ color: "#737373", fontWeight: 600 }}>
                          {tag.name}
                        </HnChip>
                      );
                    })}
                  </Box>
                </CardContent>
              </ColouredContainer>
            </Grid>
          );
        })}
        <SideDrawer 
          open={editingTagCategory !== undefined} 
          setIsOpen={() => setEditingTagCategory(undefined)}
          title={`Edit ${editingTagCategory?.name} Tags`}
        >
          {editingTagCategory &&
            <EditTagCategory
              handleClose={() => setEditingTagCategory(undefined)}
              tagCategory={editingTagCategory}
            />
          }
        </SideDrawer>
        <SideDrawer title="Create Tag Category" open={isCreatingTagCategory} setIsOpen={setIsCreatingTagCategory}>
          <CreateTagCategory
              handleClose={() => setIsCreatingTagCategory(false)}
            />
        </SideDrawer>  
      </Grid>
    </Box>
  );
};
