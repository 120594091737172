import { Box, Grid, IconButton, styled, Typography } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { ArrowRight } from "react-feather";
import { CurrentMonthInvoiceView } from "../../../../components/billing/CurrentMonthInvoiceView";
import { LightTooltip } from "../../../../components/shared/LightTooltip";
import { LoadingSpinner } from "../../../../components/shared/LoadingSpinner";
import { Table } from "../../../../components/shared/Table";
import { Invoice, InvoiceStatus, InvoiceType } from "../../../../models";
import { formatCurrency, formatDate } from "../../../../utils/formatters";
import { useData } from "../../../../utils/useData";


const PaidChip = styled(Box)({
  borderRadius: 14,
  backgroundColor: "#D0EAD3",
  color: "#0E8F2B",
  fontSize: 10,
  fontWeight: 600,
  padding: 6,
  paddingLeft: 12,
  paddingRight: 12
})

const DueChip = styled(PaidChip)({
  backgroundColor: "#FAD4D3",
  color: "#BA352B"
})


export const BillingInvoices = ({ setViewingInvoice } : { setViewingInvoice : Function}) => {
  const { invoices } = useData();
  const [platformInvoices, setPlatformInvoices] = useState<Invoice[]>([]);

  useEffect(() => {
    if (!invoices.isLoading && invoices.data) {
      const clonedInvoices = [...invoices.data.items];
      setPlatformInvoices(
        clonedInvoices.filter(
          (invoice) => invoice.type === InvoiceType.PLATFORM_FEE || invoice.type === InvoiceType.TRANSACTIONS
        )
      );
    }
  }, [invoices.isLoading, invoices.data]);

  if (invoices.isLoading || invoices.isIdle) {
    return <LoadingSpinner />;
  }

  if (invoices.error || !invoices.data) {
    return <p>Something went wrong, please try again later</p>;
  }

  const now = new Date();
  const currentMonth = now.getMonth() + 1;
  const currentYear = now.getFullYear();

  const currentMonthInvoice = platformInvoices.find(
    (x) => x.period.month === currentMonth && x.period.year === currentYear
  );

  const pastInvoices = platformInvoices
    .filter(
      (x) => x.period.month !== currentMonth || x.period.year !== currentYear
    )
    .sort((a, b) => {
      return (
        new Date(b.period.start).getTime() - new Date(a.period.start).getTime()
      );
    });

  const columns: ColumnDef<Invoice>[] = [
    {
      accessorFn: (row) => row.number,
      header: "Invoice",
      sortingFn: (rowA, rowB) => {
        return rowA.original.number < rowB.original.number ? -1 : 1;
      },
      size: 20
    },
    {
      accessorKey: "status",
      header: () => (
        <div style={{ textAlign: "center", width: "150px" }}>Status</div>
      ),
      cell: (cell) => {
        const invoiceStatus = cell.getValue() as InvoiceStatus;
        return (
          <Box
            display="flex"
            justifyContent="center"
            style={{ width: "150px" }}
          >
            <LightTooltip
              title={
                invoiceStatus === InvoiceStatus.PAID
                  ? "Paid"
                  : invoiceStatus === InvoiceStatus.PAYMENT_PENDING
                  ? "Payment Pending"
                  : "Ready To Pay"
              }
              placement="top"
            >
              {invoiceStatus === InvoiceStatus.PAID ? (
                <PaidChip>Paid</PaidChip>
              ) : invoiceStatus === InvoiceStatus.PAYMENT_PENDING ? (
                <PaidChip>Payment Pending</PaidChip>
              ) : (
                <DueChip>DUE</DueChip>
              )}
            </LightTooltip>
          </Box>
        );
      },
      enableSorting: false,
      id: "Status",
      size: 20
    },
    {
      accessorFn: (row) => row.period.title,
      header: "Period",
      sortingFn: (rowA, rowB) => {
        return rowA.original.period.title < rowB.original.period.title ? -1 : 1;
      },
      size: 1000
    },
    {
      accessorFn: (row) =>
        row.type
          .toLowerCase()
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
      header: "Type",
      enableSorting: false,
      size: 20
    },
    {
      "header": "Due Date",
      accessorFn: (row) => formatDate(row.dueDate)
    },
    {
      accessorFn: (row) => formatDate(row.paidOn),
      header: "Date Paid",
      sortingFn: (rowA, rowB) => {
        return rowA.original.paidOn < rowB.original.paidOn ? -1 : 1;
      },
      size: 20
    },
    {
      accessorKey: "totalAmount",
      header: () => (
        <div style={{ justifyContent: "end", textAlign: "end", width: "100px" }}>Amount</div>
      ),
      cell: ({ row }) => (
        <Box display="flex" justifyContent="end">
          {formatCurrency(row.original.amount.amount, 2)}
        </Box>
      ),
      enableSorting: false,
      size: 100
    },
    {
      id: "Details",
      cell: ({ row }) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          <IconButton onClick={() => setViewingInvoice(row.original.id)}>
            <ArrowRight />
          </IconButton>
        </Box>
      ),
      enableSorting: false,
      size: 20
    }
  ];

  return (
    <>
      <Grid container spacing={5} flexDirection="column">
        <Grid item>
          <Box minWidth="fit-content">
            {currentMonthInvoice && (
              <CurrentMonthInvoiceView invoice={currentMonthInvoice} />
            )}
          </Box>
        </Grid>
        <Grid item>
          <Box minWidth="fit-content">
            <Typography variant="h6" pb={2}>
              Invoices
            </Typography>
            <Table<Invoice>
              data={pastInvoices}
              totalItems={pastInvoices.length}
              columns={columns}
              displaySearch={false}
              displayFilters={false}
              rowSelection={false}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
