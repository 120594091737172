import { Box, Grid, Typography } from "@mui/material";
import { EmployeeSpend } from "../../components/analytics/EmployeeSpend";
import { Totals } from "../../components/analytics/Totals";
import { CommunicationsBanners } from "../../components/CommunicationsBanners";
import { useData } from "../../utils/useData";
import { TransactionTable } from "../../components/table/Transaction/TransactionTable";

export const Home = () => {
  const { employer, userInfoData } = useData();

  return (
    <Box minWidth="fit-content">
      <CommunicationsBanners />
      <Typography variant="subtitle2" sx={{fontWeight: 400, color: "#454545"}}>
        {employer.data?.name ?? ""}
      </Typography>
      <Typography variant="h4" id="welcome-message" data-testid="welcome-message">
        Welcome back, {userInfoData?.user?.firstName}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EmployeeSpend />
        </Grid>
        <Grid container item spacing={2}>
          <Totals />
        </Grid>
        <Grid container item spacing={2} xs={12}>
          <Grid item xs={12}>
            <TransactionTable enableExport={false} enableFilters={false} enableSearch={false}/>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
