import { Box, styled, Typography } from "@mui/material";
import { resolveRenewalPeriod } from "../../onboarding/TierInfoBox";
import { VariantStyleDictionary } from "./VariantStyleDictionary";
import { RenewalOptions, Tier } from "../../../models";

const ListItemTitle = styled(Box)({
    fontSize: 16,
    fontWeight: 600
})

type TierInfo = {
    tiers: Tier[],
    renewalOptions: RenewalOptions
}

const TierDisplay = ({ variant, tierInfo }: 
    { variant: "full" | "preview" | "fullColor"; tierInfo: TierInfo }) => {
    const { detailsListItemStyle, sectionHeaderStyle } = VariantStyleDictionary[variant]
    const renewalPeriod = resolveRenewalPeriod(
      tierInfo.renewalOptions.periodInMonths ?? 0
    );

    return (
        <>
        <Typography sx={sectionHeaderStyle}>
          Tiers
        </Typography>
        {tierInfo.tiers.map((t, i) => {
          return (
            <Box
              key={i}
              display="flex"
              alignItems="flex-end"
              paddingX={1.5}
              paddingY={1}
              sx={detailsListItemStyle}
            >
              <ListItemTitle>{t.name}</ListItemTitle>
              <Typography ml="auto">
                ${t.amount.amount}{renewalPeriod !== undefined ? `/${renewalPeriod}` : null}
              </Typography>
            </Box>
          );
        })}
      </>
    )
}

export { TierDisplay }