
    // Prioritise "Departments" tag for useability

import { Tag, TagCategory } from "../models";

// (instead of default sorting which would be in order of last modified)
function compareTags(tag: Tag, tagCategories: any) {
    const tagCategory = tagCategories.data?.items.find(
        (tc: TagCategory) => tc.id === tag.tagCategoryId
    );
    if (tagCategory?.name === "Departments") {
        return -1;
    }
    return 1;
}

export { compareTags }