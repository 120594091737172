import { styled, Typography } from "@mui/material"

const SectionTitle = styled(Typography)({
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "24px",
    marginTop: 12,
    marginBottom: 12
})

export { SectionTitle }