import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography
} from "@mui/material";
import { FormEvent, useMemo, useState } from "react";
import { Check, Edit, Trash } from "react-feather";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Tag } from "../../models/tag";
import { TagCategory } from "../../models/tagCategory";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { Add } from "@mui/icons-material";

type EditTagCategoryProps = {
  handleClose: () => void;
  tagCategory: TagCategory;
};

export const EditTagCategory = ({
  handleClose,
  tagCategory
}: EditTagCategoryProps) => {
  const { config } = useConfig();
  const { getToken } = useKindeAuth();

  const { employerId, employees, tagCategories } = useData();

  const [tagCategoryState, setTagCategoryState] = useState(tagCategory);
  const [editingTag, setEditingTag] = useState<Tag | undefined>(undefined);
  const [error, setError] = useState("");

  const handleCreate = () => {
    const tempTags = tagCategoryState.tags;
    const newTag = {
      id: `local-${tagCategoryState.tags.length.toString()}}`, // Local reference ID before creation in DB with UUID
      name: "New Tag",
      tagCategoryId: tagCategoryState.id
    };
    tempTags.push(newTag);

    setEditingTag(newTag);
    setTagCategoryState({
      ...tagCategoryState,
      tags: tempTags
    });
  };

  const handleUpdateTagName = (input: string, tag: Tag) => {
    setTagCategoryState({
      ...tagCategoryState,
      tags: tagCategoryState.tags.map((existingTag) =>
        existingTag === tag ? { ...existingTag, name: input } : existingTag
      )
    });
  };

  const handleDelete = (tag: Tag) => {
    setTagCategoryState({
      ...tagCategoryState,
      tags: tagCategoryState.tags.filter((t) => t.id !== tag.id)
    });
  };

  const mutationUpdateTagCategory = useMutation(
    async (f) => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/tag-categories/${tagCategory.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            ...tagCategoryState,
            tags: tagCategoryState.tags.map((tag: Tag) => {
              return {
                ...tag,
                id: tag.id.includes("local") ? undefined : tag.id // Strips out the local IDs in request
              };
            })
          })
        }
      );

      if (!response.ok)
        throw new Error(
          "There was a problem updating this Tag Category. To delete a tag, it first must not be assigned to any users."
        );
    },
    {
      onSuccess: () => {
        toast.success("Tag Category updated successfully");
        handleClose();
        employees.refetch();
        tagCategories.refetch();
      },
      onError: (error: Error) => {
        tagCategories.refetch();
        setTagCategoryState(tagCategory);
        console.error(error.message);
        setError(error.message);
      }
    }
  );

  const onClose = () => {
    employees.refetch();
    tagCategories.refetch();
    handleClose();
  };

  const mutationDeleteTagCategory = useMutation(
    async (f) => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/tag-categories/${tagCategory.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          }
        }
      );

      if (!response.ok)
        throw new Error(
          "There was a problem deleting this Tag Category. To delete a tag category, it must first not have any tags assigned to users."
        );
    },
    {
      onSuccess: () => {
        toast.success("Tag Category deleted successfully");
        handleClose();
        employees.refetch();
        tagCategories.refetch();
      },
      onError: (error: Error) => {
        console.error(error.message);
        setError(error.message);
      }
    }
  );

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    mutationUpdateTagCategory.mutate();
  }

  const checkIfTagAppliedToEmployee = useMemo(() => {
    return (tag: Tag) => {
      const foundEmployeeWithTag = employees.data?.items.find((employee) => {
        return employee.tags.find((t) => t.id === tag.id);
      });

      if (!foundEmployeeWithTag) return false;
      return true;
    };
  }, [employees.data?.items]);

  return (
    <>
      <Box component={"form"} onSubmit={handleSubmit}>
        <Box padding={2}>
          <List>
            {tagCategoryState.tags.map((tag) => {
              const isTagAppliedToEmployee = checkIfTagAppliedToEmployee(tag);
              return (
                <ListItem key={tag.id}>
                  <Grid container mx={2}>
                    {editingTag?.id === tag.id ? (
                      <Input
                        fullWidth
                        placeholder={tag.name}
                        onChange={(e) =>
                          handleUpdateTagName(e.target.value, tag)
                        }
                      />
                    ) : (
                      <ListItemText primary={tag.name} />
                    )}
                  </Grid>
                  <Button
                    onClick={() =>
                      editingTag?.id === tag.id
                        ? setEditingTag(undefined)
                        : setEditingTag(tag)
                    }
                    color="secondary"
                  >
                    {editingTag?.id === tag.id ? <Check /> : <Edit />}
                  </Button>
                  <Tooltip
                    title={
                      isTagAppliedToEmployee
                        ? "Tag is currently assigned to Employee(s)"
                        : "Delete"
                    }
                  >
                    <span>
                      <Button
                        disabled={isTagAppliedToEmployee}
                        onClick={() => handleDelete(tag)}
                        color="secondary"
                      >
                        <Trash />
                      </Button>
                    </span>
                  </Tooltip>
                </ListItem>
              );
            })}
          </List>
          <Button
                sx={{ paddingTop: 4, paddingBottom: 4}}
                variant="text"
                onClick={handleCreate}
                fullWidth
                startIcon={
                    <Box
                        display="flex"
                        padding={1} 
                        borderRadius="100%"
                        sx={{ bgcolor: "grey.300" }}
                    >
                        <Add color="primary" />
                    </Box>
                }
            >
              <Typography fontSize={18} fontWeight={400} marginRight={"auto"}>Add Tag</Typography>
            </Button>
            <Typography color="error">{error}</Typography>
        </Box>
        <Box padding={2}>
          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            color="primary"
            loading={mutationUpdateTagCategory.isLoading}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};
