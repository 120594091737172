import { LoadingButton } from "@mui/lab";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { FC } from "react";
import { ServiceSector } from "../../../models";
import { CreatePlanInput } from "../../plans/CreatePlanInput";
import { BackButton } from "../../shared/BackButton";
import { AccountSummary } from "../../shared/AccountSummary";

export const Step6Review: FC<{
  formik: FormikProps<CreatePlanInput>;
  serviceSectors: ServiceSector[] | undefined;
  loading: boolean;
  back: () => void;
}> = ({ formik, serviceSectors, loading, back }) => {
  return (
    <Grid item xs={12}>
      <Typography variant="h4" fontSize={32} fontWeight={700}>
        Great Account!
      </Typography>
      <Typography variant="subtitle1" my={1} mb={3}>
        Confirm everything below looks as planned
      </Typography>
      <Box sx={{ borderColor: "#E9EEF4", borderRadius: 4, borderWidth: 2, borderStyle: "solid", padding: 2 }}>
        <Typography variant="h6">
          {formik.values.name}
        </Typography>
        <Divider sx={{ borderBottom: 2, borderColor: "#D3D3D3", marginTop: 1, marginBottom: 1}} />
        <AccountSummary
          variant="full"
          planInfo={formik.values}
          serviceSectors={serviceSectors}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 5 }}
      >
        <BackButton onClick={back} outlined />
        <LoadingButton loading={loading} variant="contained" type="submit">
          Continue
        </LoadingButton>
      </Box>
    </Grid>
  );
};
