import React from 'react'
import { renderToString } from 'react-dom/server'
import './EmployeeSpendTooltip.css'
import { formatCurrency } from '../../../utils/formatters'

const EmployeeSpendTooltip = ({series, seriesIndex, dataPointIndex, w, volume}) => {
    return(
        <div className='employeeSpend-tooltipContainer'>
            <span className='employeeSpend-currencyDisplay'>
                {formatCurrency(series[seriesIndex][dataPointIndex])}
            </span>
            <span className='employeeSpend-volumeDisplay'>
                {volume[dataPointIndex]} Transactions
            </span>
        </div>
    )
}

const EmployeeSpendTooltipMemo = React.memo(EmployeeSpendTooltip)

const EmployeeSpendTooltipRenderer = ({series, seriesIndex, dataPointIndex, w}, volume) => 
    renderToString(<EmployeeSpendTooltipMemo 
        series={series}
        seriesIndex={seriesIndex}
        dataPointIndex={dataPointIndex}
        w={w}
        volume={volume}
    />)

export { EmployeeSpendTooltipMemo }
export { EmployeeSpendTooltipRenderer }