import { Box, Button, Grid, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { FormikProps } from "formik";
import { DateTime } from "luxon";
import { FC, useEffect, useState } from "react";
import { ReactComponent as Calendar } from "../../../assets/calendar.svg";
import { ReactComponent as StarredCalendar } from "../../../assets/starred-calendar.svg";
import { PlanType, RenewalType } from "../../../models";
import { CreatePlanInput } from "../../plans/CreatePlanInput";
import { BackButton } from "../../shared/BackButton";
import { AccountRadioBox } from "../AccountRadioBox";

export const Step4RenewalType: FC<{
  formik: FormikProps<CreatePlanInput>;
  next: () => void;
  back: () => void;
}> = ({ formik, next, back }) => {
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false); // Managing the date picker open state as we have disabled free text entry, and on text field click we want to open date picker

  useEffect(() => {
    if (formik.values.renewalOptions.type === RenewalType.SET_DATE) {
      formik.setFieldValue(
        "renewalOptions.startDate",
        DateTime.fromISO(new Date().toISOString())
      );
    } else {
      formik.setFieldValue("renewalOptions.startDate", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.renewalOptions.type]);

  return (
    <Grid item xs={12}>
      <Typography variant="h4" fontSize={32} fontWeight={700}>
        Contribution Start Date
      </Typography>
      <Box display="flex" width="100%" gap={3} my={4}>
        <AccountRadioBox
          title={"Relative to the date a user is added to account"}
          content={
            "Contributions apply relative to the date user is added to account"
          }
          icon={<Calendar />}
          isSelected={
            formik.values.renewalOptions.type ===
            RenewalType.INDIVIDUAL_START_DATE
          }
          onClick={() =>
            formik.setFieldValue(
              "renewalOptions.type",
              RenewalType.INDIVIDUAL_START_DATE
            )
          }
        />
        <AccountRadioBox
          title={"A specific date"}
          content={"All users will align to the same anniversary date"}
          icon={<StarredCalendar />}
          isSelected={
            formik.values.renewalOptions.type === RenewalType.SET_DATE
          }
          onClick={() =>
            formik.setFieldValue("renewalOptions.type", RenewalType.SET_DATE)
          }
        />
      </Box>

      {formik.values.renewalOptions.type === RenewalType.SET_DATE && (
        <>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              name="startDate"
              maxDate={DateTime.now()} // Limits the date to today
              value={DateTime.fromISO(
                formik.values.renewalOptions.startDate ??
                  new Date().toISOString()
              )}
              onChange={(value) =>
                formik.setFieldValue("renewalOptions.startDate", value)
              }
              open={datePickerOpen}
              onClose={() => setDatePickerOpen(false)}
              sx={{
                input: {
                  cursor: "pointer"
                },
                width: "100%"
              }}
              slotProps={{
                textField: {
                  // Managing the date picker open state as we have disabled free text entry, and on text field click we want to open date picker
                  inputProps: { readOnly: true },
                  onClick: () => {
                    if (!datePickerOpen) {
                      setDatePickerOpen(true);
                    }
                  },
                  helperText: formik.errors.renewalOptions?.startDate ? (
                    <Typography variant="caption" color="error">
                      {formik.errors.renewalOptions?.startDate}
                    </Typography>
                  ) : null,
                  error: Boolean(formik.errors.renewalOptions?.startDate),
                  InputProps: {
                    style: {
                      borderColor: formik.errors.renewalOptions?.startDate
                        ? "red"
                        : undefined
                    }
                  }
                }
              }}
              format={"dd/MM/yyyy"}
            />
          </LocalizationProvider>
          <Box>
            {formik.values?.renewalOptions.startDate && (
              <Typography color="primary" fontSize={14} my={1}>
                {formik.values.planType === PlanType.ONE_OFF
                  ? "Expiry date"
                  : "Next renewal date"}{" "}
                will be{" "}
                {new Date(
                  new Date(formik.values.renewalOptions.startDate).setMonth(
                    new Date(
                      formik.values.renewalOptions.startDate
                    ).getMonth() + 12
                  )
                ).toLocaleDateString()}
              </Typography>
            )}
          </Box>
        </>
      )}

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 10 }}
      >
        <BackButton onClick={back} outlined />
        <Button
          disabled={
            formik.values.renewalOptions.type === RenewalType.SET_DATE &&
            !formik.values.renewalOptions.startDate
          }
          variant="contained"
          onClick={next}
        >
          Continue
        </Button>
      </Box>
    </Grid>
  );
};
