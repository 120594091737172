import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  useTheme
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Employee, InvitedEmployee, Plan, PlanReference } from "../../models";
import { useData } from "../../utils/useData";
import { BackButton } from "../shared/BackButton";
import EmployeeInput from "./EmployeeInput";

export const Step3InviteEmployees: React.FC<{
  next: () => void;
  back: () => void;
  invitedEmployees: InvitedEmployee[];
  adminEmployeePlanReference: PlanReference | undefined;
  plans: Plan[];
  onEmployeesChange: (emails: InvitedEmployee[]) => void;
  updateAdminEmployeePlanReference: (
    planReference: PlanReference | undefined
  ) => void;
}> = ({
  next,
  back,
  invitedEmployees,
  adminEmployeePlanReference,
  plans,
  onEmployeesChange,
  updateAdminEmployeePlanReference
}) => {
  const theme = useTheme();
  const { userInfoData, employees } = useData();
  const [currentUser, setCurrentUser] = useState<Employee>();

  useEffect(() => {
    let user = employees.data?.items.find(
      (e) => e.id === userInfoData?.employeeId
    );
    if (user) setCurrentUser(user);
  }, [userInfoData, employees]);

  return (
    <Grid container item>
      <Grid item xs={8}>
        <Typography variant="h4" fontSize={32} fontWeight={700}>
          Invite your users
        </Typography>
        <Box marginTop={3}>
          {/* Let the admin assign plan to them as an employee if they want */}
          {currentUser && (
            <Box>
              <Divider />
              <List sx={{ marginX: 2 }}>
                <ListItem>
                  <ListItemText
                    primary={`${currentUser.firstName} ${currentUser.lastName}`}
                    secondary={currentUser.contact.email}
                  />
                  {plans.map((plan) => {
                    const selectedTierId =
                      adminEmployeePlanReference?.tierId || ""; // Fallback in case it's undefined

                    return (
                      <Select
                        key={plan.id}
                        value={selectedTierId}
                        onChange={(e) => {
                          if (e.target.value !== "") {
                            updateAdminEmployeePlanReference({
                              planId: plan.id,
                              tierId: e.target.value
                            });
                          } else {
                            updateAdminEmployeePlanReference(undefined);
                          }
                        }}
                        displayEmpty
                        style={{ marginLeft: 8 }}
                      >
                        <MenuItem value="">
                          Select a Plan/Tier
                          {/* Display a label for the undefined option */}
                        </MenuItem>
                        {plan?.tiers.map((tier) => (
                          <MenuItem key={tier.id} value={tier.id}>
                            {plan.name} - {tier.name}
                          </MenuItem>
                        ))}
                      </Select>
                    );
                  })}
                </ListItem>
              </List>
            </Box>
          )}

          <EmployeeInput
            onEmployeesChange={onEmployeesChange}
            invitedEmployees={invitedEmployees}
            plans={plans}
          />
          <Typography
            textAlign="center"
            mt={8}
            mb={6}
            color={theme.palette.grey[500]}
          >
            You can add all your users at another time in the dashboard
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mt: 5 }}
        >
          <BackButton onClick={back} outlined />
          <Button
            variant="contained"
            disabled={
              adminEmployeePlanReference === undefined &&
              invitedEmployees.length === 0
            }
            onClick={next}
          >
            Continue
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
