import { useContext, useEffect } from "react"
import { PageHeader } from "../../components/shared/PageHeader"
import { TabGroup } from "../../components/shared/TabGroup"
import { Billing } from "./tabs/Billing"
import { FloatManagement } from "./tabs/Float"
import { PageHeaderControlContext } from "../../context/PageHeaderControlContext"

const FloatAndBilling = () => {
    const { setHeaderActions } = useContext(PageHeaderControlContext)
 
    useEffect( () => {
        setHeaderActions([<></>])
    }, [])

    return (
    <>
        <PageHeader pageTitle="Funding" />
        <TabGroup tabLabels={["Funding", "Billing"]}>
            <FloatManagement />
            <Billing />
        </TabGroup>
    </>)
}

export { FloatAndBilling }