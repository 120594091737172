import { Box, Button, InputAdornment, styled, TextField, Typography } from "@mui/material"
import { Tier } from "../../../models"

const FieldLabel = styled(Typography)({
    fontSize: 16,
    fontWeight: 600
})

const DrawerTierForm = ({ onSave, onChange, tierToCreate, isEditing } : { onSave : Function, onChange: Function, tierToCreate: Tier, isEditing: boolean}) => {
    return(
      <Box paddingY={1} paddingX={2} display={"flex"} flexDirection={"column"}>
        <FieldLabel>Tier Name</FieldLabel>
        <TextField sx={{paddingBottom: 4}} value={tierToCreate.name} onChange={(change) => 
            onChange({ ...tierToCreate, name: change.target.value })} />
        <FieldLabel>Tier Contribution</FieldLabel>
        <TextField
            name="contribution"
            type="number"
            disabled={isEditing}
            value={tierToCreate.amount.amount}
            onChange={(e) => {
                onChange({
                  ...tierToCreate,
                  amount: {
                    ...tierToCreate.amount,
                    amount: +e.target.value
                  }
                });
              }}
            InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
            }}
            sx={{ paddingBottom: 4}}
        />
        <Button variant="contained" onClick={() => onSave()}>Save</Button>
    </Box>
  )
}

export { DrawerTierForm }