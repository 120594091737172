import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { CssBaseline, Theme, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Route, Routes as Router, useLocation } from "react-router-dom";
import Zendesk from "react-zendesk";
import { LoadingSpinner } from "./components/shared/LoadingSpinner";
import { MatRippleColorContextProvider } from "./context/MatRippleColorContext";
import { PageHeaderControlProvider } from "./context/PageHeaderControlContext";
import { Employer } from "./models";
import { NotAuthorized } from "./pages/not-authorized/NotAuthorized";
import { Signup } from "./pages/signup/Signup";
import { AuthenticatedRoutes } from "./Routes";
import { createExtraordinaryTheme } from "./utils/theme";
import { useConfig } from "./utils/useConfig";
import { useCustomQuery, useFeatures } from "./utils/useData";

function App() {
  const { config } = useConfig();
  const location = useLocation();
  const { isLoading: isFeaturesLoading, data: features } = useFeatures();
  const { isAuthenticated, isLoading, error, login, getPermission, getClaim } =
    useKindeAuth();

  const [employerId, setEmployerId] = useState<string>();
  const [theme, setTheme] = useState<Theme>(createExtraordinaryTheme()); // Default theme

  // Have to query employer here as well, as we can't use
  // useData due to getClaim not being defined until authenticated.
  const employer = useCustomQuery<Employer>({
    endpoint: `/employers/${employerId}`,
    options: {
      enabled: !!employerId
    }
  });

  const handleTheme = () => {
    const extraordinaryTheme = createExtraordinaryTheme();
    setTheme(extraordinaryTheme);
    updatePublicHtml("Extraordinary", "/extraordinaryFavicon.png");
  };

  const updatePublicHtml = (title: string, url: string): void => {
    document.title = title;

    const link: HTMLLinkElement | null =
      document.querySelector("link[rel*='icon']");

    const cacheBustedUrl = `${url}?v=${new Date().getTime()}`;

    if (link) {
      link.href = cacheBustedUrl;
    } else {
      const newLink: HTMLLinkElement = document.createElement("link");
      newLink.rel = "icon";
      newLink.href = cacheBustedUrl;
      document.head.appendChild(newLink);
    }
  };

  const resolveRoutes = () => {
    if (
      !isAuthenticated &&
      features?.find(
        (f) => f.name === "self-service-onboarding" && f.isEnabled
      ) &&
      config?.GOOGLE_RECAPTCHA_SITE_KEY
    ) {
      return (
        // Self-Service Signup - Requires Feature enabled + ReCaptcha API key
        <GoogleReCaptchaProvider
          reCaptchaKey={config?.GOOGLE_RECAPTCHA_SITE_KEY ?? ""}
        >
          <Router>
            <Route path="/signup" Component={Signup} />
          </Router>
        </GoogleReCaptchaProvider>
      );
    } else if (getPermission("employer_access").isGranted) {
      // Regular authenticated routes
      return (
        <PageHeaderControlProvider>
          <MatRippleColorContextProvider>
            <AuthenticatedRoutes />
          </MatRippleColorContextProvider>
        </PageHeaderControlProvider>
      );
    } else {
      return <NotAuthorized />;
    }
  };

  useEffect(() => {
    if (!isAuthenticated) return;

    const employerId = getClaim("external_org_id")?.value?.toString();
    setEmployerId(employerId);
  }, [getClaim, isAuthenticated]);

  useEffect(() => {
    handleTheme();
  }, []);

  useEffect(() => {
    // Don't force re-dir to login if on signup route
    if (isAuthenticated || isLoading || location.pathname === "/signup") {
      return;
    }
    login &&
      login({
        app_state: {
          redirectTo: window.location.pathname
        }
      });
  }, [isAuthenticated, isLoading, location.pathname, login]);

  if (isLoading || isFeaturesLoading) return <LoadingSpinner />;

  if (error) return <p>{error}</p>;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />{" "}
      {config?.ZENDESK_KEY && (
        <Zendesk defer zendeskKey={config?.ZENDESK_KEY} />
      )}
      {resolveRoutes()}
    </ThemeProvider>
  );
}

export default App;
