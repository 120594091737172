import { styled, Typography } from "@mui/material"
import { PageHeaderControlContext } from "../../context/PageHeaderControlContext"
import { useContext } from "react"
import { useData } from "../../utils/useData"

interface PageHeaderProps {
    pageTitle: string,
}

const HeaderContainer = styled('div')({display:"flex", flexDirection:"row", marginBottom: 12 })

const PageTitle = styled(Typography)({ fontSize: 32, fontWeight: 700 })

const ButtonContainer = styled('div')({ display:"flex", gap: 16, marginLeft: "auto", width:"fit-content"})


const PageHeader = ({ pageTitle }: PageHeaderProps ) => {
    const { headerActions } = useContext(PageHeaderControlContext);
    const { employer } = useData();

    return (
        <>
            <Typography variant="subtitle2" sx={{fontWeight: 400, color: "#454545"}}>
                {employer.data?.name ?? ""}
            </Typography>
            <HeaderContainer>
                <PageTitle>
                    {pageTitle}
                </PageTitle>
                <ButtonContainer>
                    {headerActions}
                </ButtonContainer>
            </HeaderContainer>
        </>
    )
}

export { PageHeader }