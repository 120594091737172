import { Box } from "@mui/material";
import { FC, ReactNode } from "react";

export const HnChip: FC<{
  variant: "filled" | "outlined";
  size?: "sm" | "lg";
  children: ReactNode | ReactNode[];
  sx?: any
}> = ({ variant, size, children, sx = {} }) => {
  return (
    <Box
      bgcolor={variant === "filled" ? "primary.main" : "white"}
      border="1px solid #737373"
      color={variant === "filled" ? "white" : "primary.main"}
      paddingY={
        variant === "filled"
          ? size === "sm"
            ? 0.7
            : 1
          : size === "sm"
          ? 0.5
          : 1
      }
      paddingX={size === "sm" ? 1.2 : 2}
      borderRadius={size === "sm" ? 4 : 5}
      fontSize={size === "sm" ? 12 : 16}
      fontWeight={500}
      whiteSpace="nowrap"
      sx={sx}
    >
      {children}
    </Box>
  );
};
