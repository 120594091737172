import { useFormik } from "formik";
import { ReplaceEmployeeFormValuesType } from "../../../../components/employees/EditUser"
import { Employee } from "../../../../models"
import { useData } from "../../../../utils/useData";
import { useDynamicEmployeeValidationSchema } from "../../../../utils/useDynamicValidationSchema";
import parsePhoneNumberFromString from "libphonenumber-js";
import { useConfig } from "../../../../utils/useConfig";
import { useMutation } from "react-query";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { toast } from "react-toastify";
import { PersonalDetailsForm } from "../../../../components/employees/PersonalDetailsForm";
import { Box, Button, Grid, styled, TextField } from "@mui/material";
import { AddressFinderForm } from "../../../../components/AddressFinderForm";
import { SectionTitle } from "../SectionTitle";

const SaveButton = styled(Button)({
    marginTop: 12,
})

const EmployeePersonalDetails = ({ employee } : { employee: Employee }) => {
    const { config } = useConfig();
    const { allEmployees, employer, employees, accounts } = useData();
    const { getToken } = useKindeAuth();


  const mutationUpdateUser = useMutation(
    async (formValues: ReplaceEmployeeFormValuesType) => {
      const countryCode: any = employer?.data?.country?.id;

      let formattedPhoneNumber = null;
      if (formValues.contact.phone?.length) {
        formattedPhoneNumber = parsePhoneNumberFromString(
          formValues.contact.phone,
          countryCode
        )?.number;
      }

      const response = await fetch(
        `${config?.API_URL}/employers/${employer.data?.id}/employees/${employee.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            customerEmployeeId: formValues.customerEmployeeId,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            cardDisplayName: formValues.cardDisplayName,
            contact: {
              email: formValues.contact.email,
              phone: formattedPhoneNumber,
              address: {
                ...formValues.contact.address,
                city: formik.values.contact.address.city,
                // Only include state/province for outside of NZ
                stateOrProvince:
                  employer.data?.country.id !== "NZ"
                    ? formik.values.contact.address.stateOrProvince
                    : undefined,
                country: employer.data?.country.id ?? ""
              }
            },
          })
        }
      );

      if (!response.ok) {
        const responseJson = await response.json();

        switch (response.status) {
          case 400:
            if (responseJson.errors["Contact.Phone"]) {
              throw new Error(responseJson.errors["Contact.Phone"][0]);
            } else if (responseJson.errors["Contact.Email"]) {
              throw new Error(responseJson.errors["Contact.Email"][0]);
            } else {
              throw new Error("There was a problem updating this user");
            }
          default:
            throw new Error("There was a problem updating this user");
        }
      }
    },
    {
      onSuccess: () => {
        toast.success("User updated successfully");
        employees.refetch();
        accounts.refetch();
      },
      onError: (error: Error) => {
        console.error(error.message);
      }
    }
  );

    const validationSchema = useDynamicEmployeeValidationSchema(
        employer.data?.country.id ?? "",
        allEmployees.data?.items,
        employee.id
      );
    
    const { resetForm, ...formik } = useFormik({
        initialValues: {
          customerEmployeeId: employee?.customerEmployeeId ?? "",
          firstName: employee.firstName,
          lastName: employee.lastName,
          cardDisplayName: employee?.cardDisplayName ?? "",
          contact: {
            email: employee.contact.email,
            phone: employee.contact.phone,
            address: {
              address1: employee.contact.address?.address1 ?? "",
              address2: employee.contact.address?.address2 ?? "",
              city: employee.contact.address?.city ?? "",
              stateOrProvince: employee.contact.address?.stateOrProvince ?? "",
              postalCode: employee.contact.address?.postalCode ?? "",
              country: employer.data?.country.id ?? ""
            }
          }
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
          mutationUpdateUser.mutate(values);
        },
        enableReinitialize: true
      });

    return (
        <Grid container item spacing={2} xs={7}>
            <Grid item xs={12}>
                <SectionTitle>Employee</SectionTitle>
            </Grid>
            <PersonalDetailsForm
                formik={formik}
                allowEmailChange={true}
            />
            <Grid item xs={12} md={6}>
                <TextField
                    value={formik.values.cardDisplayName}
                    onChange={formik.handleChange}
                    label="Name to display on card (Max. 28 Characters)"
                    name="cardDisplayName"
                    fullWidth
                    size="small"
                    error={
                    formik.touched.cardDisplayName &&
                    Boolean(formik.errors.cardDisplayName)
                    }
                    helperText={
                    formik.touched.cardDisplayName &&
                    formik.errors.cardDisplayName
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <SectionTitle>Postal Address</SectionTitle>
            </Grid>
            <Box
                width="100%"
                pt={1}
                ml={2}
                display="flex"
                flexDirection="column"
                gap={2}
            >
                <AddressFinderForm
                    formik={formik}
                    promptEmployerAddress={true}
                    required={
                    formik.values.contact.address.address1.length > 0
                    } // Don't mark address fields required if user is not filling in address. This is as an employer might want to edit an employee, before the employee has entered their address (and the employer might not want to fill it in). The validation of this is handled in the useDynamicEmployeeValidationSchema, this is just to mark the fields visually.
                />

                <SaveButton variant="contained" onClick={formik.submitForm} fullWidth >Save</SaveButton>
            </Box>
        </Grid>
    )
}

export { EmployeePersonalDetails }