import { Box, CircularProgress, styled, Typography } from "@mui/material";
import { LoadingSpinner } from "../../../../components/shared/LoadingSpinner";
import { TransactionTable } from "../../../../components/table/Transaction/TransactionTable";
import { Employee, EmployeeAccount } from "../../../../models";
import { formatCurrency, formatPercentage } from "../../../../utils/formatters";
import { extraordinaryColors } from "../../../../utils/theme";
import { useData } from "../../../../utils/useData";

const TransactionHeader = styled(Typography)({ fontWeight: 700, fontSize: 20 });

const TotalDisplayCard = styled(Box)({
  width: "100%",
  backgroundColor: extraordinaryColors.green.light,
  paddingLeft: 24,
  paddingRight: 24,
  paddingTop: 24,
  paddingBottom: 24,
  borderRadius: 10
});

const StatisticTitle = styled(Typography)({
  fontSize: 14,
  fontWeight: 500
});

const StatisticDisplay = styled(Typography)({
  fontSize: 28,
  fontWeight: 700
});

const StatisticSubtitle = styled(Typography)({
  fontSize: 12
});

const EmployeeOverview = ({
  employee,
  employeeAccounts
}: {
  employee: Employee;
  employeeAccounts: EmployeeAccount[];
}) => {
  const { ledgers, allAccounts } = useData();

  if (
    ledgers.isLoading ||
    ledgers.isIdle ||
    allAccounts.isLoading ||
    allAccounts.isIdle
  )
    return <LoadingSpinner />;

  var totalSpend = 0;
  var totalTxns = 0;

  ledgers.data?.items.forEach((ledger) => {
    if (ledger.employeeId !== employee.id) return;
    totalSpend += ledger.billingAmount.amount;
    totalTxns++;
  });

  // If the account has been deleted, then the total contribution doesn't reflect the amount they employee was able to spend, as they could have remaining balance.
  // So we need to subtract the remaining balance to see how much was "actually" contributed. This will result in the deleted accounts utilization always being 100%
  const totalEmployeeContribution: number =
    allAccounts.data?.items
      .filter((a) => a.employeeId === employee.id)
      .reduce((accumulator, account): number => {
        if (account.deletedAt) {
          return (accumulator +=
            account.totalContributionAmount.amount -
            account.availableBalance.amount);
        }
        return (accumulator += account.totalContributionAmount.amount);
      }, 0) ?? 0;

  const totalRemainingBalance: number =
    employeeAccounts.reduce((accumulator, account): number => {
      return (accumulator += account.availableBalance.amount);
    }, 0) ?? 0;

  const averageTxn = totalSpend / totalTxns;
  const remainingBalance = totalRemainingBalance;
  const utilizationPercentage = Math.min(
    (totalSpend / totalEmployeeContribution) * 100,
    100
  ); // Cap utilization percentage at 100% in case weird scenarios cause it to exceed.

  return (
    <>
      <Box display={"flex"} flexDirection={"row"} gap={3} marginY={4}>
        <TotalDisplayCard>
          <StatisticTitle>Spend Tracking</StatisticTitle>
          <StatisticDisplay>{formatCurrency(totalSpend)}</StatisticDisplay>
          <StatisticSubtitle>{totalTxns} Transactions</StatisticSubtitle>
        </TotalDisplayCard>
        <TotalDisplayCard>
          <StatisticTitle>Balance Remaining</StatisticTitle>
          <StatisticDisplay>
            {formatCurrency(remainingBalance)}
          </StatisticDisplay>
          <StatisticSubtitle>
            {formatPercentage(100 - utilizationPercentage)}
          </StatisticSubtitle>
        </TotalDisplayCard>
        <TotalDisplayCard>
          <StatisticTitle>Average Transaction</StatisticTitle>
          <StatisticDisplay>{formatCurrency(averageTxn || 0)}</StatisticDisplay>
        </TotalDisplayCard>
        <TotalDisplayCard>
          <StatisticTitle>Contribution Utilization</StatisticTitle>
          <Box
            display={"flex"}
            flexDirection={"row"}
            marginTop={1}
            alignItems={"center"}
          >
            <CircularProgress
              variant="determinate"
              value={utilizationPercentage}
              size={40}
              thickness={8}
              color="primary"
              sx={{ marginRight: 2 }}
            />

            <StatisticDisplay>
              {formatPercentage(utilizationPercentage || 0)}
            </StatisticDisplay>
          </Box>
        </TotalDisplayCard>
      </Box>
      <TransactionHeader>Recent Transactions</TransactionHeader>
      <TransactionTable employeeId={employee.id} />
    </>
  );
};

export { EmployeeOverview };
