import { extraordinaryColors } from "../../../utils/theme"

const VariantStyleDictionary = {
    "full": {
        iconStyle: {
            color: "black",
        },
        sectionHeaderStyle: {
            fontSize: 18,
            fontWeight: 600,
            paddingTop: "12px",
            paddingBottom: "12px",
            color: "black"
        },
        detailsListItemStyle: {
            paddingTop: "6px",
            paddingBottom: "6px",
            paddingLeft: "12px", 
            paddingRight: "12px",
            borderWidth: "2px",
            borderRadius: "4px",
            borderStyle: "solid",
            borderColor: "#E9EEF4",
            marginBottom: "8px",
        }
    },
    "fullColor": {
        iconStyle: {
            color: extraordinaryColors.yellow.main
        },
        sectionHeaderStyle: {
            fontSize: 18,
            fontWeight: 600,
            paddingTop: "12px",
            paddingBottom: "12px",
            color: "black"
        },
        detailsListItemStyle: {
            paddingTop: "6px",
            paddingBottom: "6px",
            paddingLeft: "12px", 
            paddingRight: "12px",
            borderWidth: "2px",
            borderRadius: "4px",
            borderStyle: "solid",
            borderColor: extraordinaryColors.yellow.main,
            marginBottom: "8px",
        }
    },
    "preview": {
        iconStyle: {
            color: "black"
        },
        sectionHeaderStyle: {
            fontSize: 10,
            fontWeight: 600,
            mt: 1,
            color: "white"
        },
        detailsListItemStyle: {
            paddingTop: "4px",
            paddingBottom: "4px",
            paddingLeft: "12px", 
            paddingRight: "12px",
            borderColor: 'white',
            borderWidth: "1px",
            borderRadius: "4px",
            borderStyle: "solid",
            marginBottom: "8px",
        }
    }
}

export { VariantStyleDictionary }