import { Button, styled } from "@mui/material"
import { ArrowLeft } from "react-feather"

const StyledButton = styled(Button)({
    justifyContent: "start",
    paddingLeft: 0,
    fontFamily: "'Dazzed', sans-serif"
})

const NavBackButton = ({ onBackPress } : { onBackPress: (event: any) => void}) => {
    return(
        <StyledButton onClick={onBackPress} variant="text" startIcon={<ArrowLeft />}>Back</StyledButton>
    )
}

export { NavBackButton }