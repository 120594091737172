import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
  Typography
} from "@mui/material";
import { FC, useState } from "react";
import { LogOut } from "react-feather";
import { ReactComponent as UserCircled } from "../../assets/user-circled.svg";
import { useData } from "../../utils/useData";

const UserInfoContainer = styled(Box)({
  backgroundColor: "#262626",
  color: "white",
  borderRadius: 12,
  marginTop: 18,
  marginBottom: 32,
  marginLeft: 18,
  marginRight: 18,
  padding: 12,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  flexDirection: "row"
});

const UserInfoTextbox = styled(Box)({
  marginLeft: 8,
  marginRight: 8,
  width: 124,
  overflowX: "auto",
  whiteSpace: "nowrap"
});
const EmployerName = styled(Typography)({ fontSize: 12 });
const UserFullName = styled(Typography)({ fontSize: 14 });

const GreyLogout = styled(LogOut)({ color: "#8A8A8A", marginLeft: "auto" });

export const UserInfoLogout: FC<{ hideUserInfo?: boolean }> = ({
  hideUserInfo
}) => {
  const { logout } = useKindeAuth();
  const { userInfoData, employer } = useData();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {hideUserInfo ? (
        <Box
          display="flex"
          maxWidth="95px"
          ml="auto"
          my={3}
          style={{ cursor: "pointer" }}
          onClick={() => setIsModalOpen(true)}
        >
          <Typography>Log Out</Typography>
          <GreyLogout />
        </Box>
      ) : (
        <UserInfoContainer>
          <UserCircled />
          <UserInfoTextbox>
            <UserFullName>{`${userInfoData?.user?.firstName ?? ""} ${
              userInfoData?.user?.lastName ?? ""
            }`}</UserFullName>
            <EmployerName>{employer.data?.name}</EmployerName>
          </UserInfoTextbox>
          <GreyLogout
            style={{ cursor: "pointer" }}
            onClick={() => setIsModalOpen(true)}
          />
        </UserInfoContainer>
      )}

      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Log Out</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)} color="secondary">
            Cancel
          </Button>
          <LoadingButton onClick={logout} autoFocus variant="contained">
            Log Out
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
