import { useMemo } from "react";
import { LoadingSpinner } from "../../../../components/shared/LoadingSpinner";
import { useData } from "../../../../utils/useData";
import { Box, styled, Typography } from "@mui/material";
import { extraordinaryColors } from "../../../../utils/theme";
import { formatCurrency, formatPercentage } from "../../../../utils/formatters";
import { TierDisplay } from "../../../../components/shared/AccountSummary/TierDisplay";
import { SectorDisplay } from "../../../../components/shared/AccountSummary/SectorDisplay";
import { getOpenServiceSector } from "../../../../utils/serviceSectors/getOpenServiceSector";

const OverviewContainer = styled(Box)({ paddingTop: 28  })

const AggregationContainer = styled(Box)({
    borderRadius: 8,
    backgroundColor: extraordinaryColors.yellow.main,
    padding: 18,
    width: "100%"
})

const AggregationLabel = styled(Typography)({
    fontSize: 14,
    fontWeight: 500
})

const AggregationDisplay = styled(Typography)({
    fontSize: 28,
    fontWeight: 700
})

const PlanOverview = ({ planId } : { planId: string } ) => {

    const { plans, accounts, ledgers, serviceSectors } = useData();

    const plansLoading = plans.isIdle || plans.isFetching;
    const accountsLoading = accounts.isIdle || accounts.isFetching;
    const ledgersLoading = ledgers.isIdle || ledgers.isFetching;
    const sectorsLoading = serviceSectors.isIdle || serviceSectors.isLoading;


    const planOverviewAggregations = useMemo(() => accounts.data?.items.reduce((accumulator, account) => {
        const newAccumulation = { ...accumulator }
        if(account.planId === planId) {
            newAccumulation.totalUsers = accumulator.totalUsers + 1;
            newAccumulation.totalContributions = accumulator.totalContributions + account.totalContributionAmount.amount
        }
        return newAccumulation;
    }, { 
        totalUsers: 0, 
        totalContributions: 0,
    }), [])

    const txnAggregations = useMemo(() =>  ledgers.data?.items.reduce((accumulator, ledger) => { 
        const newAccumulation = { ...accumulator }
        ledger.charges.forEach((charge) => {
            if(charge.planId === planId) 
                newAccumulation.totalSpend = accumulator.totalSpend + charge.amount.amount
        })

        return newAccumulation;
    }, {
        totalSpend: 0
    }), [])

    const planSpendPercentage = useMemo(
        () => {
            const totalSpend = txnAggregations?.totalSpend ?? 0
            const totalContribution = planOverviewAggregations?.totalContributions ?? 0
            return totalSpend / (totalContribution / 100 )
        },
        [ planOverviewAggregations, txnAggregations ]
    )

    const currentPlan = plans.data?.items.find((plan) => plan.id === planId)

    if( plansLoading || accountsLoading || ledgersLoading || sectorsLoading ) return <LoadingSpinner />

    const openServiceSector = getOpenServiceSector(serviceSectors.data?.items ?? [])

    const isOpenAccount = currentPlan?.controls.serviceSectors.find(
        (ss) => ss.id === openServiceSector?.id
      )?.id

    if(!currentPlan) return <p>Error - Plan not found</p>

    return (
    <OverviewContainer>
        <Box display={"flex"} flexDirection={"row"} gap={3} justifyContent={"space-between"}>
            <AggregationContainer>
                <AggregationLabel>
                    Total Contributions
                </AggregationLabel>
                <AggregationDisplay>
                    {formatCurrency(planOverviewAggregations?.totalContributions ?? 0, 0)}
                </AggregationDisplay>
            </AggregationContainer>

            <AggregationContainer>
                <AggregationLabel>
                    Total Employees
                </AggregationLabel>
                <AggregationDisplay>
                    {planOverviewAggregations?.totalUsers ?? 0}
                </AggregationDisplay>
            </AggregationContainer>

            <AggregationContainer>
                <AggregationLabel>
                    Total Cost
                </AggregationLabel>
                <AggregationDisplay>
                    {formatCurrency(txnAggregations?.totalSpend ?? 0, 0)}
                </AggregationDisplay>
            </AggregationContainer>

            <AggregationContainer>
                <AggregationLabel>
                    Contribution Utilisation
                </AggregationLabel>
                <AggregationDisplay>
                    {formatPercentage(planSpendPercentage ?? 0)}
                </AggregationDisplay>
            </AggregationContainer>
        </Box>

        <Box display={"flex"} flexDirection={"row"} gap={4} paddingTop={2}>
            <Box width={"100%"}>
                <TierDisplay variant="full" tierInfo={currentPlan} />
            </Box>
            <Box width={"100%"}>
                <SectorDisplay 
                    variant="full"
                    serviceSectorIds={currentPlan.controls.serviceSectors?.map((sector) => sector.id) ?? []}
                    isOpenAccount={isOpenAccount}
                />
            </Box>
        </Box>



    </OverviewContainer>)
}

export { PlanOverview }