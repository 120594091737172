import { useContext, useState } from "react";
import { Employee } from "../../../models";
import {
    Box,
    Button,
    styled,
    Typography,
  } from "@mui/material";
import {
    CreditCard,
    Tag as TagIcon,
    Send,
    Sliders,
    Download,
    DollarSign,
  } from "react-feather";
import { useData } from "../../../utils/useData";
import { ApplyTags } from "../../employees/ApplyTags";
import { AssignPlans } from "../../employees/AssignPlans";
import { UnAssignPlans } from "../../employees/UnAssignPlans";
import { SendMessage } from "../../modals/SendMessage";
import { IssueCards } from "../../modals/IssueCards";
import { ReportEntity } from "../../../models/reporting/reportEntity";
import { convertEmployeesToCsv, downloadCsv } from "../../../utils/convertToCSV";
import { MatRippleColorContext } from "../../../context/MatRippleColorContext";
import { TopUpAccount } from "../../employees/TopUpAccount";

const ComponentViewDictionary: Record<string, React.FC<any>> = {
    'ApplyTags': ApplyTags,
    'AssignPlans': AssignPlans,
    'UnassignPlans': UnAssignPlans,
    'SendMessage': SendMessage,
    'IssueCards': IssueCards,
    'TopUpAccount': TopUpAccount
}

const DrawerContentContainer = styled(Box)({ width: 400, display: "flex", flexDirection: "column", padding: 12 })

const OptionsTitle = styled(Typography)({ fontWeight: 700, fontSize: 24, paddingBottom: 24 })

const OptionsButton = styled(Button)({ justifyContent: "start", fontSize: "1em", fontWeight: 500, borderBottom: "1px solid #eee", borderRadius: 0 })

const EmployeeActionDrawerContent = ({ selectedEmployees, handleResetTableSelection } : {selectedEmployees: Employee[], handleResetTableSelection: Function}) => {
    const { tagCategories, plans, accounts } = useData();    
    
    const [activeView, setActiveView] = useState('root')

    const { rippleColorSx } = useContext(MatRippleColorContext)

    const RenderTarget = ComponentViewDictionary[activeView];

    const handleDownloadCsv = (employees: Employee[]) => {
        const csv = convertEmployeesToCsv(
        employees,
        accounts.data?.items ?? [],
        tagCategories.data?.items,
        plans.data?.items
        );
        downloadCsv(csv, `${ReportEntity.EMPLOYEES}${new Date().toLocaleString()}`);
    };

    if(activeView !== 'root') return (
        <DrawerContentContainer sx={rippleColorSx}>
            <RenderTarget 
                open={true}
                handleComplete={() => handleResetTableSelection()}
                handleClose={() => setActiveView('root')}
                selectedEmployees={selectedEmployees}
            /> 
        </DrawerContentContainer>)

    return (
    <DrawerContentContainer sx={rippleColorSx}>
        <OptionsTitle>Actions</OptionsTitle>
        <OptionsButton
            variant="text"
            onClick={() => setActiveView('ApplyTags')}
            startIcon={<TagIcon size={16} style={{ marginRight: 12}}  />}
            size="small"
        >
            Apply Tags
        </OptionsButton>
        <OptionsButton
            variant="text"
            onClick={() => setActiveView('AssignPlans')}
            startIcon={<Sliders size={16} style={{ marginRight: 12}} />}
            size="small"
        >
            Assign Account
        </OptionsButton>
        <OptionsButton
            variant="text"
            onClick={() => setActiveView('UnassignPlans')}
            startIcon={<Sliders size={16} style={{ marginRight: 12}} />}
            size="small"
        >
            Un-Assign Account
        </OptionsButton>
        <OptionsButton 
            variant="text"
            onClick={() => setActiveView("TopUpAccount")}
            startIcon={<DollarSign size={16} style={{ marginRight: 12}} />}
            size="small"
            disabled={selectedEmployees.length !== 1}
        >
            Top up Account
        </OptionsButton>
        <OptionsButton
            variant="text"
            onClick={() => setActiveView('SendMessage')}
            startIcon={<Send size={16} style={{ marginRight: 12}} />}
            size="small"
        >
            Send message
        </OptionsButton>
        <OptionsButton
            variant="text"
            onClick={() => setActiveView('IssueCards')}
            startIcon={<CreditCard size={16} style={{ marginRight: 12}} />}
            size="small"
        >
            Issue cards
        </OptionsButton>
        <OptionsButton
            variant="text"
            onClick={() => handleDownloadCsv(selectedEmployees)}
            startIcon={<Download size={16} style={{ marginRight: 12}} />}
            size="small"
        >
            Export to CSV
        </OptionsButton>
    </DrawerContentContainer>
    );
}

export { EmployeeActionDrawerContent }