export type CardDesign = {
  id: string;
  employerId: string;
  type: CardDesignType;
  textStyle: CardDesignTextStyle;
  url: CardDesignUrl;
};

export enum CardDesignType {
  "GENERAL",
  "EMPLOYER_SPECIFIC"
}

export enum CardDesignTextStyle {
  WHITE = "WHITE",
  BLACK = "BLACK"
}

export type CardDesignUrl = {
  thumb: string;
  normal: string;
  large: string;
};
