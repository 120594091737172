import { Button } from "@mui/material";
import { EmployeeTable } from "../../components/table/Employee/EmployeeTable"
import { Plus, UploadCloud } from "react-feather";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageHeaderControlContext } from "../../context/PageHeaderControlContext";
import { useData } from "../../utils/useData";
import { SideDrawer } from "../../components/shared/SideDrawer";
import { AddUser } from "../../components/employees/AddUser";

const EmployeeTableTab = () => {
    const { employees, membership } = useData();

    const { setHeaderActions } = useContext(PageHeaderControlContext)
    const [isAddingUser, setIsAddingUser] = useState(false);
  
    const navigate = useNavigate()
    
  
    useEffect(() => {      
      if(employees.isLoading || !employees.data) {
        setHeaderActions([<></>])
        return
      }
  
      setHeaderActions([
      <>
        <Button
          variant="outlined"
          onClick={() => navigate(`/users/import`)}
          startIcon={<UploadCloud size={16} />}
          size="medium"
          disabled={
            membership.data?.features?.maxEmployeeCount
              ? employees.data.totalItems >=
                membership.data.features.maxEmployeeCount
              : false
          }
        >
          Import
        </Button>
        <Button
          variant="contained"
          onClick={() => setIsAddingUser(true)}
          startIcon={<Plus size={16} />}
          size="medium"
          disabled={
            membership.data?.features?.maxEmployeeCount
              ? employees.data.totalItems >=
                membership.data.features.maxEmployeeCount
              : false
          }
        >
          Add
        </Button>
      </>
    ])
    }, [])
    return(
        <>
            <SideDrawer open={isAddingUser} setIsOpen={setIsAddingUser} shouldConfirmClose shouldHideBackButton>
                <AddUser handleClose={() => setIsAddingUser(false)}/>
            </SideDrawer>
            <EmployeeTable />
        </>
    )
}

export { EmployeeTableTab }