import { Box, Grid, styled, Typography } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { CurrentFloatView } from "../../../components/billing/CurrentFloatView";
import { FloatPaymentBox } from "../../../components/billing/FloatPaymentBox";
import { LightTooltip } from "../../../components/shared/LightTooltip";
import { LoadingSpinner } from "../../../components/shared/LoadingSpinner";
import { Table } from "../../../components/shared/Table";
import { Invoice, InvoiceStatus, InvoiceType } from "../../../models";
import { formatCurrency, formatDate } from "../../../utils/formatters";
import { useData } from "../../../utils/useData";

const PaidChip = styled(Box)({
  borderRadius: 14,
  backgroundColor: "#D0EAD3",
  color: "#0E8F2B",
  fontSize: 10,
  fontWeight: 600,
  padding: 6,
  paddingLeft: 12,
  paddingRight: 12
})

const DueChip = styled(PaidChip)({
  backgroundColor: "#FAD4D3",
  color: "#BA352B"
})

export const FloatManagement = () => {
  const { float, employer, invoices } = useData();
  const [floatInvoices, setFloatInvoices] = useState<Invoice[]>([]);

  useEffect(() => {
    if (!invoices.isLoading && invoices.data) {
      const clonedInvoices = [...invoices.data.items];
      setFloatInvoices(
        clonedInvoices.filter(
          (invoice) =>
            invoice.type === InvoiceType.FLOAT &&
            (invoice.status === InvoiceStatus.PAID || invoice.status === InvoiceStatus.PAYMENT_PENDING)
        )
      );
    }
  }, [invoices.isLoading, invoices.data]);

  if (float.isLoading || float.isIdle || employer.isLoading) {
    return <LoadingSpinner />;
  }

  if (float.error || !float.data || employer.error || !employer.data) {
    return <p>Something went wrong, please try again later</p>;
  }

  const columns: ColumnDef<Invoice>[] = [
    {
      accessorFn: (row) => row.number,
      header: "Number",
      sortingFn: (rowA, rowB) => {
        return rowA.original.number < rowB.original.number ? -1 : 1;
      },
      size: 20
    },
    {
      accessorKey: "status",
      header: () => (
        <div style={{ textAlign: "center", width: "150px" }}>Status</div>
      ),
      cell: (cell) => {
        const invoiceStatus = cell.getValue() as InvoiceStatus;
        return (
          <Box
            display="flex"
            justifyContent="center"
            style={{ width: "150px" }}
          >
            <LightTooltip
              title={
                invoiceStatus === InvoiceStatus.PAID
                  ? "Paid"
                  : invoiceStatus === InvoiceStatus.PAYMENT_PENDING
                  ? "Payment Pending"
                  : "Ready To Pay"
              }
              placement="top"
            >
              {invoiceStatus === InvoiceStatus.PAID ? (
                <PaidChip>Paid</PaidChip>
              ) : invoiceStatus === InvoiceStatus.PAYMENT_PENDING ? (
                <PaidChip>Payment Pending</PaidChip>
              ) : (
                <DueChip>DUE</DueChip>
              )}
            </LightTooltip>
          </Box>
        );
      },
      enableSorting: false,
      id: "Status",
      size: 20
    },
    {
      accessorFn: (row) => formatDate(row.paidOn),
      header: "Date Paid",
      sortingFn: (rowA, rowB) => {
        return rowA.original.paidOn < rowB.original.paidOn ? -1 : 1;
      },
      size: 20
    },
    {
      accessorKey: "amount",
      header: () => <Box justifyContent={"end"} textAlign={"end"}>Amount</Box>,
      sortingFn: (rowA, rowB) => {
        return rowA.original.amount.amount < rowB.original.amount.amount ? -1 : 1;
      },
      cell: ({row}) => <Box justifyContent={"end"} textAlign={"end"}>
        {formatCurrency(row.original.amount.amount)}
        </Box>,
      size: 20,
      enableSorting: false
    },
  ];

  return (
    <Grid container spacing={5} flexDirection="column">
      <Grid item>
        <Box minWidth="fit-content">
          <CurrentFloatView float={float.data} />
        </Box>
      </Grid>
      <Grid item>
        <FloatPaymentBox />
      </Grid>
      <Grid item>
        <Box minWidth="fit-content">
          <Typography variant="h6" pb={2}>
            Invoices
          </Typography>
          <Table<Invoice>
            data={floatInvoices}
            totalItems={floatInvoices.length}
            columns={columns}
            displaySearch={false}
            displayFilters={false}
            rowSelection={false}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
