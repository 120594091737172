import { styled, Typography } from "@mui/material";

const SideDrawerTitle = styled(Typography)({
    fontWeight: 600,
    fontSize: 24,
    paddingTop: 14,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 0
})

export { SideDrawerTitle }