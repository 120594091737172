import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Grid,
  Typography
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Report } from "../../models/reporting/report";
import { ReportAxisValue } from "../../models/reporting/reportAxisValue";
import { ReportEntity } from "../../models/reporting/reportEntity";
import { ReportPeriod } from "../../models/reporting/reportPeriod";
import { ReportType } from "../../models/reporting/reportType";
import { ReportVisualScale } from "../../models/reporting/reportVisualScale";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";
import { ReportDetailsForm } from "./ReportDetailsForm";
import { SideDrawerTitle } from "../shared/SideDrawer";

type EditReportProps = {
  handleClose: () => void;
};

export const CreateReport = ({ handleClose }: EditReportProps) => {
  const { config } = useConfig();
  const { employerId, reports } = useData();
  const { getToken } = useKindeAuth();

  const [error, setError] = useState("");

  const mutationCreateReport = useMutation(
    async (formValues: Partial<Report>) => {
      const response = await fetch(
        `${config?.API_URL}/employers/${employerId}/reports`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(formValues)
        }
      );

      if (!response.ok)
        throw new Error("There was a problem creating this report");
    },
    {
      onSuccess: () => {
        toast.success("Report created successfully");
        reports.refetch();
        handleClose();
      },
      onError: (error: Error) => {
        console.error(error.message);
        setError(error.message);
      }
    }
  );

  const { resetForm, ...formik } = useFormik({
    initialValues: {
      title: "",
      type: ReportType.LINE_CHART,
      entity: ReportEntity.EMPLOYEES,
      visualScale: ReportVisualScale.LG,
      data: {
        xAxis: ReportAxisValue.TIME,
        yAxis: ReportAxisValue.COUNT,
        period: ReportPeriod.ALL_TIME,
        isAnonymised: true
      },
      filters: []
    },
    onSubmit: async (values) => {
      mutationCreateReport.mutate(values);
    },
    enableReinitialize: true
  });

  // Reset form when the modal is opened
  useEffect(() => {
    resetForm();
    setError("");
  }, [resetForm, setError]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box padding={2}>
          <Grid container spacing={2}>
            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
            <Box sx={{ width: "100%", px: 2, pt: 2 }}>
              <ReportDetailsForm form={formik} />
            </Box>
          </Grid>
        </Box>
        <Box padding={2}>
          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            color="primary"
            loading={mutationCreateReport.isLoading}
          >
            Save
          </LoadingButton>
        </Box>
      </form>
    </>
  );
};
