import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Box, Grid, styled, Typography } from "@mui/material";
import { useFormik } from "formik";
import { FC, useEffect } from "react";
import { useMutation } from "react-query";
import { Plan, PlanType, RenewalStrategy, RenewalType } from "../../../models";
import { useConfig } from "../../../utils/useConfig";
import { useData } from "../../../utils/useData";
import { CreatePlanInput } from "../../plans/CreatePlanInput";
import { AccountSummary } from "../../shared/AccountSummary";
import { HelpAndSupportFaq } from "../HelpAndSupportFaq";
import { Step0Name } from "./0_name";
import { Step1Type } from "./1_type";
import { Step2Tiers } from "./2_tiers";
import { Step3ServiceSectors } from "./3_serviceSectors";
import { Step4RenewalType } from "./4_renewalType";
import { Step5RenewalStrategy } from "./5_renewalStrategy";
import { Step6Review } from "./6_review";
import { extraordinaryColors } from "../../../utils/theme";

const AccountTitle = styled(Typography)({
    fontWeight: 700,
    fontSize: 18,
})

export const ContributionAccountSteps: FC<{
  step: number;
  setStep: (value: number) => void;
  existingPlan: Plan | null;
  refetchPlan?: () => void;
  setMasterStep?: (value: number) => void;
}> = ({ step, setStep, existingPlan, refetchPlan, setMasterStep }) => {
  const { config } = useConfig();
  const { getToken } = useKindeAuth();
  const { employer, serviceSectors } = useData();

  const initialValues: CreatePlanInput = {
    name: existingPlan?.name ?? "",
    tiers: existingPlan?.tiers ?? [],
    serviceSectorIds:
      existingPlan?.controls?.serviceSectors?.map((ss) => ss.id) ?? [],
    planType: existingPlan?.type ?? PlanType.ONGOING,
    renewalOptions: {
      strategy: existingPlan?.renewalOptions.strategy ?? RenewalStrategy.RESET,
      type:
        existingPlan?.renewalOptions.type ?? RenewalType.INDIVIDUAL_START_DATE,
      periodInMonths: existingPlan?.renewalOptions.periodInMonths ?? 1,
      startDate: existingPlan?.renewalOptions.startDate ?? undefined
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      if (existingPlan === null) {
        mutationCreatePlan.mutate(values);
      } else {
        mutationUpdatePlan.mutate(values);
      }
    },
    enableReinitialize: true
  });

  const mutationCreatePlan = useMutation(
    async (values: CreatePlanInput) => {
      if (!values.serviceSectorIds?.length) {
        throw new Error("Plan must have at least 1 category selected");
      }

      if (!values.tiers?.length) {
        throw new Error("Plan must have at least 1 tier");
      }

      const response = await fetch(
        `${config?.API_URL}/employers/${employer.data?.id}/plans`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            name: values.name,
            serviceSectorIds: values.serviceSectorIds,
            tiers: values.tiers.map((tier) => {
              return {
                name: tier.name,
                amount: tier.amount.amount
              };
            }),
            type: values.planType,
            renewalOptions: values.renewalOptions
          })
        }
      );

      if (!response.ok)
        throw new Error("There was a problem creating this plan");
    },
    {
      onSuccess: () => {
        if (refetchPlan) {
          refetchPlan();
        }
        if (setMasterStep) {
          setMasterStep(2);
        }
      },
      onError: (error: Error) => {
        console.error(error.message);
      }
    }
  );

  const mutationUpdatePlan = useMutation(
    async (values: CreatePlanInput) => {
      if (!values.serviceSectorIds?.length) {
        throw new Error("Plan must have at least 1 category selected");
      }

      if (!values.tiers?.length) {
        throw new Error("Plan must have at least 1 tier");
      }

      const response = await fetch(
        `${config?.API_URL}/employers/${employer.data?.id}/plans/${existingPlan?.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            name: values.name,
            serviceSectorIds: values.serviceSectorIds,
            tiers: values.tiers.map((tier) => {
              return {
                id: tier.id ?? null,
                name: tier.name,
                amount: tier.amount.amount
              };
            }),
            type: values.planType,
            renewalOptions: values.renewalOptions
          })
        }
      );

      if (!response.ok)
        throw new Error("There was a problem updating this plan");
    },
    {
      onSuccess: () => {
        if (refetchPlan) {
          refetchPlan();
        }
        if (setMasterStep) {
          setMasterStep(2);
        }
      },
      onError: (error: Error) => {
        console.error(error.message);
      }
    }
  );

  // Handle one-off VS ongoing
  useEffect(() => {
    if (formik.values.planType === PlanType.ONE_OFF) {
      formik.setFieldValue("renewalOptions.strategy", RenewalStrategy.EXPIRE);
    } else if (formik.values.planType === PlanType.ONGOING) {
      formik.setFieldValue("renewalOptions.strategy", RenewalStrategy.RESET);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.planType]);

  const resolveStep = () => {
    switch (step) {
      case 0: {
        return <Step0Name formik={formik} next={() => setStep(1)} />;
      }
      case 1: {
        return (
          <Step1Type
            formik={formik}
            next={() => setStep(2)}
            back={() => setStep(0)}
          />
        );
      }
      case 2: {
        return (
          <Step2Tiers
            formik={formik}
            currencyCode={employer.data?.country.currencyCode!}
            next={() => setStep(3)}
            back={() => setStep(1)}
          />
        );
      }
      case 3: {
        return (
          <Step3ServiceSectors
            formik={formik}
            serviceSectors={serviceSectors.data?.items}
            next={() => setStep(4)}
            back={() => setStep(2)}
          />
        );
      }
      case 4: {
        return (
          <Step4RenewalType
            formik={formik}
            next={() => {
              if (formik.values.planType === PlanType.ONE_OFF) {
                // If the plan is one-off, skip the renewal type (as it is forced to "EXPIRE")
                return setStep(6);
              } else {
                return setStep(5);
              }
            }}
            back={() => setStep(3)}
          />
        );
      }
      case 5: {
        return (
          <Step5RenewalStrategy
            formik={formik}
            next={() => setStep(6)}
            back={() => {
              if (formik.values.planType === PlanType.ONE_OFF) {
                // If the plan is one-off, skip the renewal type (as it is forced to "EXPIRE")
                return setStep(3);
              } else {
                return setStep(4);
              }
            }}
          />
        );
      }
      case 6: {
        return (
          <Step6Review
            formik={formik}
            serviceSectors={serviceSectors.data?.items}
            loading={
              mutationCreatePlan.isLoading || mutationUpdatePlan.isLoading
            }
            back={() => setStep(4)}
          />
        );
      }
    }
  };

  return (
    <Grid container item spacing={2}>
      <Grid item xs={11} lg={6}>
        <form onSubmit={formik.handleSubmit}>{resolveStep()}</form>
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          flexDirection: "column",
          gap: 3,
          marginLeft: "auto",
          marginTop: -14
        }}
        display={{ xs: "none", md: "none", lg: "flex" }}
      >
        {step !== 0 && (
          <>
            {step !== 6 && (
              <Box sx={{ bgcolor: extraordinaryColors.orange.main, borderRadius: "10px", padding: 2 }}>
                <AccountTitle>{formik.values.name}</AccountTitle>
                <AccountSummary
                  variant="preview"
                  planInfo={formik.values}
                  serviceSectors={serviceSectors.data?.items}
                  displayActivationDate={step > 3}
                  displayRenewalStrategy={step > 4}
                />
              </Box>

            )}
            <HelpAndSupportFaq />
          </>
        )}
      </Grid>
    </Grid>
  );
};
