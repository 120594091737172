import {
  Box,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import { useMemo, useState } from "react";
import { TransactionStatus, TransactionType } from "../../models";
import { formatCurrency } from "../../utils/formatters";
import { useData } from "../../utils/useData";
import EmployeeSpendChart from "../charts/EmployeeSpendChart";

const ToggleStyle = {
  py: 0.5,
  fontSize: 12,
  border: 0,
  "&.Mui-selected": {
    backgroundColor: "transparent",
    fontWeight: 700
  },
  "&.Mui-disabled": {
    border: "0px solid transparent"
  }
};

export const EmployeeSpend = () => {
  const [slot, setSlot] = useState("12m");

  const { transactions } = useData();

  const totalSpend: { [key: string]: number } = useMemo(() => {
    if (!transactions.isFetched) return { "1m": 0, "12m": 0 };
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();

    const twelveMonthsAgo = new Date();
    twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 12);

    let totalMonthEmployeeSpend = 0;
    let total12MonthsEmployeeSpend = 0;

    transactions.data?.items.forEach((t) => {
      const transactionDate = new Date(t.timestamp.split("T")[0]);

      const transactionYear = transactionDate.getFullYear();
      const transactionMonth = transactionDate.getMonth();

      const shouldCountTransaction =
        (t.status === TransactionStatus.APPROVED ||
          t.status === TransactionStatus.PENDING) &&
        t.type === TransactionType.PURCHASE;

      if (shouldCountTransaction) {
        if (transactionDate > twelveMonthsAgo) {
          total12MonthsEmployeeSpend += t.billingAmount?.amount ?? 0;
        }

        if (
          transactionYear === currentYear &&
          transactionMonth === currentMonth
        )
          totalMonthEmployeeSpend += t.billingAmount?.amount ?? 0;
      }
    });
    return { "1m": totalMonthEmployeeSpend, "12m": total12MonthsEmployeeSpend };
  }, [transactions.data, transactions.isFetched]);

  /* @ts-ignore-next-line */
  const handleChange = (event, newAlignment) => {
    if (newAlignment) setSlot(newAlignment);
  };

  return (
    // @ts-ignore-next-line
    <>
      <Grid item>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="subtitle2"
              sx={{ mt: 2, fontSize: 15, color: "#454545" }}
            >
              User Spend
            </Typography>
            <Typography sx={{ fontSize: 32, fontWeight: 600, lineHeight: 1.1 }}>
              {formatCurrency(totalSpend[slot] ?? 0)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent={{ xs: "center", sm: "flex-end" }}
              sx={{ mt: 3, mr: 2 }}
            >
              <ToggleButtonGroup
                exclusive
                onChange={handleChange}
                size="small"
                value={slot}
              >
                <ToggleButton
                  disabled={slot === "12m"}
                  value="12m"
                  className={"RangeToggle"}
                  sx={ToggleStyle}
                >
                  12M
                </ToggleButton>
                {
                  //TODO: Revisit 6 months rolling windows once reporting is defined
                  /* <ToggleButton
                  disabled={slot === "6m"}
                  value="6m"
                  className={"RangeToggle"}
                  sx={ToggleStyle}
                >
                  6M
                </ToggleButton> */
                }
                <ToggleButton
                  disabled={slot === "1m"}
                  value="1m"
                  className={"RangeToggle"}
                  sx={ToggleStyle}
                >
                  1M
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ pt: 1 }}>
        <EmployeeSpendChart slot={slot} />
      </Box>
    </>
  );
};
