import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from "@mui/material";
import { formatCurrency } from "../../utils/formatters";
import { useData } from "../../utils/useData";

type PlanSelectionProps = {
  selectedPlans: {
    planId: string;
    tierId: string;
  }[];
  setSelectedPlans: React.Dispatch<
    React.SetStateAction<
      {
        planId: string;
        tierId: string;
      }[]
    >
  >;
  DEFAULT_VALUE: string;
  disabledPlans?: string[];
};

export const PlanSelection = ({
  selectedPlans,
  setSelectedPlans,
  DEFAULT_VALUE,
  disabledPlans
}: PlanSelectionProps) => {
  const { plans } = useData();

  if (!plans.data?.items.length) return <div />;

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">Accounts</Typography>
      </Grid>
      {plans.data?.items.map((plan, index) => (
        <Grid item xs={12} key={index}>
          <InputLabel id={plan.id}>{plan.name}</InputLabel>
          <Select
            key={plan.id}
            labelId={plan.id}
            value={
              selectedPlans.find(
                (selectedPlan) => selectedPlan.planId === plan.id
              )?.tierId ?? DEFAULT_VALUE
            }
            onChange={(e: SelectChangeEvent) =>
              setSelectedPlans((selectedPlans) => [
                ...selectedPlans.filter(
                  (selectedPlan) => selectedPlan.planId !== plan.id
                ),
                {
                  planId: plan.id,
                  tierId: e.target.value
                }
              ])
            }
            fullWidth
            required
            size="small"
            disabled={disabledPlans?.includes(plan.id)}
          >
            {[
              ...plan.tiers.map((tier, index) => (
                <MenuItem value={tier.id} key={index}>
                  {tier.name} {formatCurrency(tier.amount.amount)}
                </MenuItem>
              )),
              <MenuItem value={DEFAULT_VALUE} defaultChecked key={9999}>
                Not selected ($0)
              </MenuItem>
            ]}
          </Select>
        </Grid>
      ))}
    </>
  );
};
