import { Box, Grid, Stack, styled, Typography } from "@mui/material";
import { formatCurrency, formatDate } from "../../utils/formatters";
import { LedgerReference } from "../../models";
import { Table as DataTable } from "../shared/Table";
import { ColumnDef } from "@tanstack/react-table";

const TotalDisplay = styled(Typography)({
  fontSize: 14,
  fontWeight: 600
})

const TableTitle = styled(Typography)({
  fontSize: 16,
  fontWeight: 600,
  marginBottom: 18
})

type InvoiceTransactionsProps = {
  transactions: LedgerReference[];
};

export const InvoiceTransactions = ({ transactions }: InvoiceTransactionsProps) => {
  const columns: ColumnDef<LedgerReference>[] = [
    {
      accessorKey: "employeeExternalId",
      header: "User Id",
      enableSorting: true,
      id: "employeeExternalId"
    },
    {
      accessorKey: "employeeName",
      header: "User",
      enableSorting: true,
      id: "employeeName"
    },
    {
      accessorKey: "sectorName",
      header: "Sector",
      enableSorting: true,
      id: "sectorName"
    },
    {
      accessorKey: "time",
      header: "Date",
      cell: ({ row }) => (
        <Box justifyContent="start">
          {formatDate(row.original.time)}
        </Box>
      ),
      enableSorting: true,
      id: "time"
    },
    {
      accessorKey: "amount",
      header: () => (
        <Box display={"flex"} justifyContent="flex-end" style={{ width: "100%", textAlign: "right" }}>
          Total
        </Box>
      ),
      cell: ({ row }) => (
        <Box display={"flex"} justifyContent="flex-end">
          {formatCurrency(row.original.amount, 2)}
        </Box>
      ),
      enableSorting: false
    }
  ];

  const total = transactions.reduce((acc, curr) => acc + curr.amount, 0);

  return (
    <Grid item>
      <Grid container justifyContent="space-between" alignItems="center">
        <TableTitle>
          User Transactions
        </TableTitle>
      </Grid>
      <Grid item>
        <Box minWidth="fit-content">
          <DataTable<LedgerReference>
            data={transactions}
            totalItems={transactions.length}
            columns={columns}
            displaySearch={false}
            displayFilters={false}
            rowSelection={false}
          />
        </Box>
      </Grid>
      <Stack 
        direction={"row"} 
        sx={{ borderTop: "2px solid #E4E7EC" }} 
        justifyContent={"space-between"}
        paddingY={2}
        paddingX={2}
        aria-label="platform fees display"
      >
        <TotalDisplay>
          Total
        </TotalDisplay>
        <TotalDisplay>
          {formatCurrency(total, 2)}
        </TotalDisplay>
      </Stack>
    </Grid>
  );
};
