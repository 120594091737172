import { TransactionTable } from "../../../../components/table/Transaction/TransactionTable"

const EmployeeTransactions = ({ employeeId } : { employeeId: string }) => {

    return (
        <>
            <TransactionTable employeeId={employeeId} enableExport/>
        </>
    )
}

export { EmployeeTransactions }