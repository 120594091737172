import { Stack, TextField } from "@mui/material";
import React, { useState } from "react";

type DateRangeFilterProps = {
  column: any;
};

export const DateRangeFilter: React.FC<DateRangeFilterProps> = ({ column }) => {
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const handleStartChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setStartDate(value);
    column.setFilterValue({ start: value, end: endDate });
  };

  const handleEndChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEndDate(value);
    column.setFilterValue({ start: startDate, end: value });
  };

  return (
    <Stack direction="row" spacing={2}>
      <TextField
        type="date"
        label="Start Date"
        value={startDate || ""}
        onChange={handleStartChange}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        type="date"
        label="End Date"
        value={endDate || ""}
        onChange={handleEndChange}
        InputLabelProps={{ shrink: true }}
      />
    </Stack>
  );
};
