import { Box } from "@mui/material";
import { createContext, useEffect, useState } from "react";
import { extraordinaryColors } from "../utils/theme";

interface IMatRippleColorContext {
    rippleColorSx: any,
    setRippleColor: (newString: string) => void
}

const MatRippleColorContext = createContext<IMatRippleColorContext>(
    { rippleColorSx: {}, setRippleColor: () => {} }
);

const MatRippleColorContextProvider = ({ children }: { children: React.ReactNode}) => {
    const [ rippleColor, setRippleColor ] = useState(extraordinaryColors.tomato.main)
    const [ rippleColorSx, setRippleColorSx ] = useState({})

    useEffect(() => {
        setRippleColorSx({
            '& .MuiButtonBase-root .MuiTouchRipple-root .MuiTouchRipple-ripple': { color: rippleColor },
            '& .MuiIconButton-root:hover': { backgroundColor: `${rippleColor}26` },
            '& .MuiCheckbox-root:hover': { backgroundColor: `${rippleColor}26` },
            '& .MuiButton-text:hover': { backgroundColor: `${rippleColor}26` },
            '& .MuiMenuItem-root .MuiTouchRipple-root .MuiTouchRipple-ripple': { color: rippleColor },
            '& .MuiButtonBase-root .MuiMenuItem-root .MuiTouchRipple-root .MuiTouchRipple-ripple': { color: rippleColor },
            '& .MuiDrawer-root .MuiTouchRipple-root .MuiTouchRipple-ripple': { color: rippleColor },
            '& .MuiRadio-root:hover': { backgroundColor: `${rippleColor}26` },
            '& .MuiMenuItem-root:hover': {backgroundColor: `${rippleColor}26`},
        })
    }, [rippleColor])

    return (
        <MatRippleColorContext.Provider value={{ rippleColorSx, setRippleColor }}>
            <Box sx={rippleColorSx}>
                {children}
            </Box>
        </MatRippleColorContext.Provider>
    )
}

export { MatRippleColorContext, MatRippleColorContextProvider }