import * as yup from "yup";
import { RenewalType } from "../../models";

const PlanSettingsSchema = yup.object().shape({
    name: yup.string().max(50).required(),
    renewalType: yup.string().oneOf(Object.values(RenewalType)).required(),
    startDate: yup
        .date()
        .nullable()
        .when("renewalType", ([renewalType], schema) => {
            if (renewalType === RenewalType.SET_DATE) {
                return schema.required("Start date is required");
            }
            return schema;
        })
    });

export { PlanSettingsSchema }