import { useState, useMemo } from "react";
import { CardStatus, Employee, EmployeeStatus } from "../../../models";
import { ColumnDef } from "@tanstack/react-table";
import {
    Box,
    CircularProgress,
    IconButton,
    styled,
    Typography,
    useTheme
  } from "@mui/material";
import {
    CreditCard,
    MoreHorizontal,
  } from "react-feather";
import { useData } from "../../../utils/useData";
import { HnChip } from "../../HnChip";
import { LightTooltip } from "../../shared/LightTooltip";
import { Table } from "../../shared/Table";
import { LoadingSpinner } from "../../shared/LoadingSpinner";
import { EditUser } from "../../employees/EditUser";
import { compareTags } from "../../../utils/compareTags";
import { useNavigate } from "react-router-dom";
import { EmployeeActionDrawerContent } from "./EmployeeActionDrawerContent";
import { formatCurrency, formatPercentage } from "../../../utils/formatters";
import { EmployeeStatusIndicator } from "../../shared/EmployeeStatusIndicator";
import './EmployeeTable.css'
import { extraordinaryColors } from "../../../utils/theme";


const NO_PLAN = "NO_PLAN";

const EmployeeStatusLabelMap = {
  [EmployeeStatus.Active]: { text: 'Active'},
  [EmployeeStatus.Created]: {  text: 'Created' },
  [EmployeeStatus.Inactive]: { text: 'Inactive' },
  [EmployeeStatus.Deleted]: { text: 'Removed' },
}

const SpendPercentDisplay = styled(Typography)({
  fontSize: 12,
  fontWeight: 500,
  marginRight: 6
})

const SpendTotalDisplay = styled(Typography)({
  fontSize: 12,
  fontWeight: 400
})

const EmployeeTable = ( { planId } : { planId?: string }) => {
    const { tagCategories, plans, accounts, employees } = useData();    
    const [editingEmployee, setEditingEmployee] = useState<Employee>();
    const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);

    const theme = useTheme();
    
    const navigate = useNavigate()


    function handleEditRow(employee: Employee) {
      navigate(`/users/${employee.id}/details`)
    }

    function handleStopEditRow() {
      setEditingEmployee(undefined);
    }

    const filterData = useMemo(() => {
      if(planId) { 
        return employees.data?.items.filter((employee) => 
          employee.accounts.some((account) => account.planId === planId))
      }
      return employees.data?.items
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employees.data?.items])

    // After table actions are completed (assigning tags, etc), we want to reset the table selection.
    // If we reset the row selection, it handles this visually but the table state still has the rows selected.
    // Easiest way to handle resetting the table entirely via updating the table key.
    const [tableResetKey, setTableResetKey] = useState(0);
    
    if (employees.isLoading || employees.isIdle) return <LoadingSpinner />;
    if (employees.error || !employees.data) return <p>Something went wrong, please try again later</p>;

    const columns: ColumnDef<Employee>[] = [
        {
          accessorFn: (row) => `${row.firstName} ${row.lastName}`,
          header: "Name",
          sortingFn: (rowA, rowB) => {
            return rowA.original.lastName < rowB.original.lastName
              ? -1
              : rowA.original.lastName === rowB.original.lastName
              ? 0
              : 1;
          }
        },
        {
          accessorFn: (row) => {
            row.tags = row.tags.sort((tag) => compareTags(tag, tagCategories));
            return row;
          },
          header: "User",
          cell: (cell) => {
            const employee = cell.getValue() as Employee;
            return (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                overflow="auto"
                whiteSpace="nowrap"
                minHeight={75}
                gap={1}
                width={160}
              >
                <Box>
                  <Typography>
                    {`${employee.firstName} ${employee.lastName}`}{" "}
                  </Typography>
                </Box>
    
                {employee.tags.length > 0 && (
                  <Box
                    display="flex"
                    maxWidth={160}
                    sx={{
                      overflowX: "auto",
                      "&::-webkit-scrollbar": {
                        display: "none" // for Chrome, Safari, and Opera
                      },
                      scrollbarWidth: "none", // for Firefox
                      msOverflowStyle: "none" // for IE 10+
                    }}
                    gap={1}
                  >
                    {employee.tags.map((tag, i) => {
                      return (
                        <HnChip key={i} variant="outlined" size="sm">
                          {tag.name}
                        </HnChip>
                      );
                    })}
                  </Box>
                )}
              </Box>
            );
          },
          enableSorting: false,
          enableGlobalFilter: false,
          meta: {
            filterLabel: "Tag",
            filterSelectOptions: tagCategories.data?.items
              .map((tagCategory) => {
                return tagCategory.tags.map((tag) => {
                  return {
                    option: tag.name,
                    value: tag.name,
                    category: tagCategory.name
                  };
                });
              })
              .flat()
          },
          filterFn: (row: any, columnId, filterValues: string) => {
            const tagNames = (row.getValue(columnId) as Employee).tags.map(
              (tag) => tag.name
            );
            return (
              tagNames.filter((tagName) => filterValues.includes(tagName)).length >
              0
            );
          },
        },
        {
          accessorFn: (row) => {
            const employeeAccounts =
              accounts.data?.items.filter((x) => x.employeeId === row.id) ?? [];
            if (!employeeAccounts?.length) return 0;
    
            const availableBalance = employeeAccounts.reduce(
              (accountSum, account) =>
                accountSum + (account.availableBalance?.amount ?? 0),
              0
            );
    
            const startingBalance = employeeAccounts.reduce(
              (balanceSum, account) =>
                balanceSum + (account?.startingBalance.amount ?? 0),
              0
            );
            
            const totalSpend = startingBalance - availableBalance
    
            const percentageSpent =
              (totalSpend / startingBalance) * 100;

            return {
              totalSpend,
              percentageSpent
            };
          },
          header: "Spend",
          cell: (cell: any) => {
            const size = 24,
              thickness = 4,
              value = cell.getValue(),
              secColor = theme.palette.grey[300];
    
            return (
              <Box 
                width={127}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-start"}
                textAlign={"left"}
                gap={0}
                padding={0}
                alignItems={"center"}
                >
                <CircularProgress
                  variant="determinate"
                  value={value.percentageSpent}
                  size={size}
                  thickness={thickness}
                  color="primary" // Set the color for the filled part
                  sx={{
                    borderRadius: "50%",
                    boxShadow: `inset 0 0 0 ${
                      (thickness / 44) * size
                    }px ${secColor}`,
                    marginRight: 1
                  }}
                />
                <SpendPercentDisplay>{formatPercentage(value.percentageSpent ?? 0)}</SpendPercentDisplay>
                <SpendTotalDisplay>{formatCurrency(value.totalSpend ?? 0)}</SpendTotalDisplay>
              </Box>
            );
          },
          enableColumnFilter: false
        },
        {
          accessorFn: (row) => {
            const employeeAccounts =
              accounts.data?.items.filter((x) => x.employeeId === row.id) ?? [];
            return employeeAccounts.map((a) => {
              const accountPlan = plans.data?.items.find((p) => p.id === a.planId);
              return accountPlan?.name;
            });
          },
          header: "Accounts",
          cell: (cell) => {
            const assignedPlanNames = cell.getValue() as string[];
            return (
              <Box display="flex" gap={1} width={245} overflow={"auto"}>
                {assignedPlanNames.map((assignedPlanName, i) => {
                  return (
                    <div style={{ marginTop: 24, marginBottom: 24}}>
                      <HnChip key={i} variant="outlined" size="sm">
                        {assignedPlanName}
                      </HnChip>
                    </div>
                  );
                })}
              </Box>
            );
          },
          enableSorting: false,
          enableGlobalFilter: false,
          meta: {
            filterLabel: "Account",
            filterSelectOptions: [
              ...(plans.data?.items?.map((plan) => {
                return {
                  option: plan.name,
                  value: plan.name,
                };
              }) || []),
              {
                option: "None",
                value: "NO_PLAN",
              },
            ]
          },
          filterFn: (row: any, columnId, filterValues: string) => {
            const planNames = row.getValue(columnId) as string[];
            if (filterValues.includes(NO_PLAN) && planNames.length === 0) {
              return true;
            }
            return (
              planNames.filter((planName) => filterValues.includes(planName))
                .length > 0
            );
          }
        },
        {
          accessorKey: "status",
          header: "Status",
          cell: (cell) => {
            const status = cell.getValue() as EmployeeStatus
            return (
              <HnChip variant="outlined" size="sm">
                <Box display={"flex"}>
                  <EmployeeStatusIndicator status={status} />
                  <Typography fontSize={12} fontWeight={600}>{EmployeeStatusLabelMap[status].text.toUpperCase()}</Typography>
                </Box>
              </HnChip>
            )
          },
          meta: {
            filterLabel: "Status",
            filterSelectOptions: Object.keys(EmployeeStatus)
              .filter(
                (statusKey) =>
                  EmployeeStatus[statusKey as keyof typeof EmployeeStatus] !==
                  EmployeeStatus.Deleted
              )
              .map((statusKey) => ({
                option: statusKey,
                value: EmployeeStatus[statusKey as keyof typeof EmployeeStatus]
              }))
          },
          filterFn: (row, columnId, filterValues: string[]) => {
            return filterValues.includes(row.getValue(columnId));
          },
          enableSorting: false,
          id: "Status"
        },
        {
            accessorFn: (row) =>
              row.cards.find((card) => card.status !== CardStatus.Removed)?.status ??
              row.cards[0]?.status ?? CardStatus.None,
            header: "Card",
            cell: (cell) => {
                switch (cell.getValue() as CardStatus) {
                    case CardStatus.Active:
                      return (
                        <Box>
                          <LightTooltip title={CardStatus.Active} placement="top">
                            <CreditCard color={extraordinaryColors.green.main} />
                          </LightTooltip>
                        </Box>
                      );
                    case CardStatus.Created:
                      return (
                        <Box>
                          <LightTooltip title={CardStatus.Created} placement="top">
                            <CreditCard color={theme.palette.grey[500]} />
                          </LightTooltip>
                        </Box>
                      );
                    case CardStatus.Issued:
                      return (
                        <Box>
                          <LightTooltip title={CardStatus.Issued} placement="top">
                            <CreditCard color={theme.palette.grey[500]} />
                          </LightTooltip>
                        </Box>
                      );
                    case CardStatus.Inactive:
                      return (
                        <Box>
                          <LightTooltip title={CardStatus.Inactive} placement="top">
                            <CreditCard color={theme.palette.grey[500]} />
                          </LightTooltip>
                        </Box>
                      );
                    case CardStatus.Removed:
                      return (
                        <Box>
                          <LightTooltip title={CardStatus.Removed} placement="top">
                            <CreditCard color={theme.palette.error.main} />
                          </LightTooltip>
                        </Box>
                      );
                    case CardStatus.None:
                    default:
                      return (
                        <Box>
                          <LightTooltip title={CardStatus.None} placement="top">
                            <Typography>{"-"}</Typography>
                          </LightTooltip>
                        </Box>
                    );
                }
            },
            enableSorting: false,
            enableGlobalFilter: false,
            enableColumnFilter: false,
            
            meta: {
              filterLabel: "Card",
              filterSelectOptions: Object.keys(CardStatus).map((statusKey) => ({
                option: statusKey,
                value: CardStatus[statusKey as keyof typeof CardStatus]
              })),
            },
            filterFn: (row, columnId, filterValues: string) => {
              return filterValues.includes(row.getValue(columnId));
            }
        },
        {
          id: "Edit",
          cell: ({ row }) => (
            <IconButton onClick={() => handleEditRow(row.original)}>
              <MoreHorizontal />
            </IconButton>
          ),
          enableSorting: false
        }
      ];

    const handleResetTableSelection = () => {
        setTableResetKey(tableResetKey + 1);
    };

    return (
        <>
          <Table<Employee>
            key={`MaterialTable-${tableResetKey}`}
            data={filterData ?? []}
            totalItems={filterData?.length ?? 0}
            columns={columns}
            defaultSorting={[{ id: "Name", desc: false }]}
            onSelectionChanges={setSelectedEmployees}
            itemActions={<EmployeeActionDrawerContent selectedEmployees={selectedEmployees} handleResetTableSelection={handleResetTableSelection} />}
          />

          {editingEmployee && (
            <EditUser
              open={true}
              handleClose={handleStopEditRow}
              employee={editingEmployee}
            />
          )}
      </>
  
    )
}

export { EmployeeTable }