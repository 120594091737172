import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { AccountCircleOutlined, ChevronRight } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { FC, ReactNode, useEffect, useState } from "react";
import ExtraordinaryBlackLogo from "../assets/logos/ExtraordinaryBlack.png";
import { Employee, Employer } from "../models";
import { extraordinaryColors } from "../utils/theme";

export const OnboardingLayout: FC<{
  step: number;
  subStep: number;
  totalSteps: number;
  employer: Employer | undefined;
  employee: Employee | undefined;
  children: ReactNode;
}> = ({ step, subStep, totalSteps, employer, employee, children }) => {
  const { logout } = useKindeAuth();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isOpenDeviceWarning, setIsOpenDeviceWarning] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isAccountMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    if (isSmallScreen) {
      setIsOpenDeviceWarning(true);
    }
  }, [isSmallScreen]);

  const handleCloseDeviceWarning = () => {
    setIsOpenDeviceWarning(false);
  };

  const handleCloseAccountMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      bgcolor={step === 4 ? extraordinaryColors.yellow.light : "transparent"}
      minHeight="110vh" // Handle gap around body
      mt={-3}
      pt={3}
    >
      <Dialog open={isOpenDeviceWarning} onClose={handleCloseDeviceWarning}>
        <DialogTitle>Onboarding</DialogTitle>
        <DialogContent>
          <Typography>
            We recommend completing your onboarding on a desktop/tablet device,
            for the best possible experience!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeviceWarning} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Grid
        container
        direction="row"
        my={3}
        px={{ xs: 5, sm: 10, lg: 18 }}
        className={isOpenDeviceWarning ? "blur-background" : ""}
      >
        <style>
          {`
            .blur-background {
              filter: blur(50px);
            }
          `}
        </style>

        {/* Navbar/Header */}
        <Box display="flex" width="100%" alignItems="center" gap={2}>
          <Box>
            <img src={ExtraordinaryBlackLogo} alt="Logo" height={28} />
          </Box>

          {/* Don't show on final review page */}
          {step !== 4 && (
            <>
              <Box
                display="flex"
                marginLeft="auto"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                sx={{
                  cursor: "pointer",
                  padding: 1,
                  paddingX: 2,
                  paddingRight: 6,
                  border: "1px solid black",
                  borderRadius: 2,
                  borderColor: "lightgrey"
                }}
              >
                <Box ml="auto" display="flex" alignItems="center" gap={1}>
                  <AccountCircleOutlined
                    color="secondary"
                    sx={{
                      width: 34,
                      height: 34,
                      stroke: "#ffffff",
                      strokeWidth: 1
                    }}
                  />
                  <Box>
                    <Typography fontSize={14} fontWeight={500} lineHeight={1}>
                      {employee?.firstName} {employee?.lastName}
                    </Typography>
                    <Typography fontSize={12} fontWeight={400}>
                      {employer?.name}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Menu
                sx={{ marginTop: 1 }}
                anchorEl={anchorEl}
                open={isAccountMenuOpen}
                onClose={handleCloseAccountMenu}
              >
                <MenuItem
                  sx={{ width: 130 }}
                  onClick={() => {
                    logout();
                    handleCloseAccountMenu();
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>

        <Grid container item mt={3} xs={12}>
          {/* Steps counter - Don't show on first or last page */}
          {step !== 0 && step !== 4 && (
            <Grid item my={5}>
              <Typography
                fontSize={14}
                color="secondary.main"
                fontWeight={600}
                display="flex"
                gap={0.5}
              >
                STEP{" "}
                <span style={{ color: theme.palette.secondary.dark }}>
                  {step}{" "}
                </span>
                OF{" "}
                <span style={{ color: theme.palette.secondary.dark }}>
                  {totalSteps}
                </span>
              </Typography>
              <Box display="flex" gap={2} my={2}>
                <Typography
                  color={step - 1 === 0 ? "primary" : "secondary"}
                  fontWeight={step - 1 === 0 ? 500 : 400}
                >
                  Contribution Account
                </Typography>
                <ChevronRight color="secondary" />
                <Typography
                  color={step - 1 === 1 ? "primary" : "secondary"}
                  fontWeight={step - 1 === 1 ? 500 : 400}
                >
                  People
                </Typography>
                <ChevronRight color="secondary" />
                <Typography
                  color={step - 1 === 2 ? "primary" : "secondary"}
                  fontWeight={step - 1 === 2 ? 500 : 400}
                >
                  Payment
                </Typography>
                <ChevronRight color="secondary" />
                <Typography
                  color={step - 1 === 3 ? "primary" : "secondary"}
                  fontWeight={step - 1 === 3 ? 500 : 400}
                >
                  Review & Complete
                </Typography>
              </Box>
            </Grid>
          )}

          <Grid container item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
